import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Typo from '../components/Text/Typo';
import { useTranslation } from 'react-i18next'
import bg2 from '../../src/textures/bg2.png';

const PREFIX = 'Footer';

const classes = {
    footer: `${PREFIX}-footer`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.footer}`]: {
        backgroundColor:theme.palette.primary.background,
        backgroundImage: `url(${bg2})`,
        backgroundRepeat: 'repeat',
        paddingTop:30,
        paddingBottom:30,
    }
}));

const Footer = () => {

    const { t, i18n } = useTranslation()

    return (
        <Root className={classes.footer}>
            <Grid container>
                <Grid item lg={1} md={1} sm={1} xs={1} />
                <Grid item lg={10} md={10} sm={10} xs={10}>
                    <Grid item lg={12} style={{paddingBottom:10}}>
                        <Typo variant="h6" type="title" fontWeight="bold">
                        {t('Disclaimer')}
                        </Typo>
                        <Typo variant="subtitle1" type="title">
                        {t('Whisper and its team members are not registered as financial advisors and hold no formal qualifications to give financial advice. Everything that is provided on this server, on the Whisper website or by Whisper and its team members is purely for educational purposes only. Whisper and its team members are not accountable or liable for any losses or damages. You are responsible for all the risks you take. Any content provided here should not be construed as financial advice.')}
                        </Typo>
                    </Grid>
                    <Grid item lg={12} style={{paddingBottom:10}}>
                        <Typo variant="subtitle1" type="title">
                        {t('@2022 Whisper. All Right Reserved.')}
                        </Typo>
                    </Grid>
                    <Grid item lg={12} style={{paddingBottom:10}}>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <Link to="/Login">
                                <Typo variant="subtitle1" type="title">
                                {t('Terms of Use')}
                                </Typo>
                            </Link>
                            <Link to="/Login" style={{padding:'1%'}}>
                                <Typo variant="subtitle1" type="title">
                                {t('Disclaimer')}
                                </Typo>
                            </Link>
                            <Link to="/Login" style={{padding:'1%'}}>
                                <Typo variant="subtitle1" type="title">
                                {t('Cookie Policy')}
                                </Typo>
                            </Link>
                            <Link to="/Login" style={{padding:'1%'}}>
                                <Typo variant="subtitle1" type="title">
                                {t('Privacy Policy')}
                                </Typo>
                            </Link>
                        </div>
                    </Grid>
                </Grid>
                <Grid item lg={1} md={1} sm={1} xs={1} />
            </Grid>
        </Root>
    );
}

export default Footer;