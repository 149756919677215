import { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import { setMemberships, setRoles, setTeams, setUser } from './redux/authSlice'

// services
import api from './services/api'

// hoc 
import Layout from './hoc/Layout'

// views
import Home from './views/Home'
import SignUp from './views/SignUp'
import Login from './views/Login'
import Main from './views/Main'
import Highlights from './views/Highlights'
import UpcomingEvents from './views/UpcomingEvents'
import Equity from './views/Equity'
import SalesManagement from './views/SalesManagement'
import UserManagement from './views/UserManagement'
import Favourites from './views/Favourites'
import ForgotPassword from './views/ForgotPassword'
import InvalidPath from './views/InvalidPath'
import ProfileSettings from './views/ProfileSettings'
import BillingHistory from './views/BillingHistory'
import Feedback from './views/Feedback'
import FAQ from './views/FAQ'
import License from './views/License'
import UserAgreement from './views/UserAgreement'
import About from './views/About'
import bg1 from '../src/textures/bg1.png'
import bg2 from '../src/textures/bg2.png'
import Typo from './components/Text/Typo'

const PREFIX = 'App'

const classes = {
  App: `${PREFIX}-App`
}

const AuthRoutes = () => {
  const user = useSelector(state => state.auth.user)
  const isAuthenticated = user?.$id.length > 0

  return (
    <Switch>
      <DefaultRoute path='/Login' title="Sign In" component={Login} />
      <DefaultRoute path='/SignUp' title="Sign Up" component={SignUp} />
      <DefaultRoute path='/ForgotPassword' title="Forgot Password" component={ForgotPassword} />
      <DefaultRoute path='/InvalidPath' title="Something Is Wrong" component={InvalidPath} showLayout={true} />

      <DefaultRoute path='/Main' title='Main' component={Main} showLayout={true} />

      <ProtectedRoute exact path='/' title="Home" component={Home} isAuth={isAuthenticated} />
      <ProtectedRoute path='/Highlights' title="Highlights" component={Highlights} isAuth={isAuthenticated} />
      <ProtectedRoute path='/Favourites' title="Favourites" component={Favourites} isAuth={isAuthenticated} />
      <ProtectedRoute path='/UpcomingEvents' title="Upcoming Events" component={UpcomingEvents} isAuth={isAuthenticated} />

      <ProtectedRoute path='/Equity' title="Equity" component={Equity} isAuth={isAuthenticated} />
      <ProtectedRoute path='/SalesManagement' title="Sales Management" component={SalesManagement} isAuth={isAuthenticated} />
      <ProtectedRoute path='/UserManagement' title="User Management" component={UserManagement} isAuth={isAuthenticated} />

      <ProtectedRoute path='/BillingHistory' title="Billing History" component={BillingHistory} isAuth={isAuthenticated} />
      <ProtectedRoute path='/Profile/Settings' title="Profile Settings" component={ProfileSettings} isAuth={isAuthenticated} />
      <ProtectedRoute path='/Feedback' title="Feedback" component={Feedback} isAuth={isAuthenticated} />
      <ProtectedRoute path='/FAQ' title="FAQ" component={FAQ} isAuth={isAuthenticated} />
      <ProtectedRoute path='/License' title="License" component={License} isAuth={isAuthenticated} />
      <ProtectedRoute path='/UserAgreement' title="User Agreement" component={UserAgreement} isAuth={isAuthenticated} />
      <ProtectedRoute path='/About' title="About" component={About} isAuth={isAuthenticated} />
      <DefaultRoute path='*' title="Something Is Wrong" component={InvalidPath} showLayout={true} />
    </Switch>
  )
}

const StyledAuthRoutes = styled(AuthRoutes)((
  {
    theme
  }
) => ({
  [`& .${classes.App}`]: {
    backgroundImage: `url(${theme.palette.secondary.background == '#000' ? bg2 : bg1})`,
    backgroundRepeat: 'repeat',
  }
}))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
        alert(err.toString())
      }
    }
  }
})

export default function App() {
  const { i18n } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  const Fallback = () => {
    return <Typo variant="body1" type="title">Ops something went wrong...</ Typo>
  }

  const handleOnErrorBoundary = (error, errorInfo) => {
    console.log('Error Logging: ', error, errorInfo)
  }

  useEffect(() => {
    // Perform change language if key exist in localStorage
    if (localStorage.getItem('language')) i18n.changeLanguage(localStorage.getItem('language'))

    async function checkSession() {
      try {
        console.log('checking existing auth session...')
        const rAccount = await api.getAccount()
        if (rAccount) {
          console.log('user session found...', rAccount)

          const { teams } = await api.getUserTeam()
          const { memberships } = await api.getUserMemberships(teams[0].$id)
          const roles = memberships[0].roles


          dispatch(setUser(rAccount))
          dispatch(setTeams(teams))
          dispatch(setMemberships(memberships))
          dispatch(setRoles(roles))

          history.push('/')
        }
      } catch (error) {
        // console.error(error)
      } finally {

      }
    }

    checkSession()
  }, [])

  return (
    <div className={classes.App}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={Fallback} onError={handleOnErrorBoundary}>
          <Routes />
        </ErrorBoundary>
      </QueryClientProvider>
    </div >
  )
}

const DefaultRoute = ({ component: Component, showLayout, ...otherProps }) => {

  const Page = (props) => {
    useEffect(() => {
      document.title = props.title || ""
    }, [props.title])
    return props.children
  }

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (showLayout) {
          return (
            <Page title={otherProps.title}>
              <Layout>
                <Component {...props} />
              </Layout>
            </Page>
          )
        } else {
          return (<Page title={otherProps.title}><Component {...props} /></Page>)
        }
      }}
    />
  )
}

const ProtectedRoute = ({ component: Component, isAuth, ...otherProps }) => {

  const Page = (props) => {
    useEffect(() => {
      document.title = props.title || ""
    }, [props.title])
    return props.children
  }

  return (
    <Route {...otherProps} render={(props) => {
      if (isAuth) {
        return (
          <Page title={otherProps.title}>
            <Layout>
              <Component {...props} />
            </Layout>
          </Page>
        )
      } else {
        return (<Page title={otherProps.title}><Redirect to={{ pathname: '/Main', state: { from: props.location } }} /></Page>)
      }
    }} />
  )
}

const PublicRoutes = () => {
  return (
    <Switch>
      <DefaultRoute exact path='/' title="Whisper" component={Main} showLayout={true} />
      <DefaultRoute path='/Login' title="Sign In" component={Login} />
      <DefaultRoute path='/SignUp' title="Sign Up" component={SignUp} />
      <DefaultRoute path='/ForgotPassword' title="Forgot Password" component={ForgotPassword} />
      <DefaultRoute path='*' title="Something Is Wrong" component={InvalidPath} showLayout={true} />
    </Switch>
  )
}

const Routes = () => {
  const user = useSelector(state => state.auth.user)

  if (!user) {
    console.log('pubRoutes')
    return <PublicRoutes />
  } else {
    console.log('StyledAuthRoutes')
    return <StyledAuthRoutes />
  }
}

// export default App