import { Button, Dialog, DialogActions, DialogContent, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiDialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types';
import Typo from '../Text/Typo';
import BasicButton from '../Button/BasicButton';

const PREFIX = 'StandardDialog';

const classes = {
    title: `${PREFIX}-title`,
    closeButton: `${PREFIX}-closeButton`
};

const StyledDialog = styled(Dialog)((
    {
        theme
    }
) => ({

    [`& .${classes.title}`]: {
        paddingLeft: 8,
    },

    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    }

}));

const DialogTitle = (props) => {

    const { children, onClose, ...other } = props
    return (
        <MuiDialogTitle {...other}>
            {onClose ? (
                <BasicButton
                    className={classes.closeButton}
                    type="iconbutton"
                    edge="start"
                    onClick={onClose}
                    icon={<CloseIcon />}
                />
            ) : null}
            <Typo variant="h6" type="title" fontWeight="bold">
                {children}
            </Typo>
        </MuiDialogTitle>
    )
}

const StandardDialog = (props) => {
    const { t } = useTranslation()
    const { open, setClose, onConfirm } = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <StyledDialog style={{ WebkitOverflowScrolling: 'touch' }} scroll={props.paper !== undefined ? 'paper' : 'body'} fullScreen={fullScreen} open={props.open !== undefined ? props.open : open} onClose={props.close !== undefined ? props.close : setClose}>
            {props.title !== undefined ?
                <DialogTitle onClose={props.iconClose !== undefined ? props.iconClose : setClose}>{t(props.title)}</DialogTitle>
                : null
            }
            {props.content !== undefined ?
                <DialogContent dividers onScroll={props.onscroll !== undefined ? props.onscroll : null}>
                    {props.content}
                </DialogContent>
                : null
            }
            {props.actions !== undefined ?
                props.actions : props.button === true ?
                    <DialogActions>
                        <Button onClick={props.close !== undefined ? props.close : setClose} variant="inline">{t('Cancel')}</Button>
                        <Button onClick={props.confirm !== undefined ? props.confirm : onConfirm} variant="contained">{t('Confirm')}</Button>
                    </DialogActions> : null
            }
        </StyledDialog>
    );
}

export default StandardDialog;