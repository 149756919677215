
import { useEffect, useContext } from "react";
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { ThemeContext } from '../../context/theme-context';

const TechnicalChart = (props) => {
    
    const themeContext = useContext(ThemeContext)
    const mode = themeContext.isDarkMode !== true ? 'light': 'dark'
    
    // Refer to https://tradingview-widgets.jorrinkievit.xyz/docs/components/AdvancedRealTimeChartWidget
    
    return (
        <AdvancedRealTimeChart 
            autosize="true" symbol={props.exchange + ':' + props.ticker}
            interval= "D" theme={mode}
            timezone= "exchange" style= "1"
            hide_top_toolbar= "true"
            hide_side_toolbar="true"
            hide_legend = "true"
            locale= "en"
            details="true"
            studies= {[
            // Beta Release - MA (200) only
                {
                    "id": "MAExp@tv-basicstudies",
                    "inputs": {
                        "length": 200,
                    }
                },
            // Starter Release - All beta + MA (100,50)
                {
                    "id": "MAExp@tv-basicstudies",
                    "inputs": {
                        "length": 100,
                    }
                
                },
                {
                    "id": "MAExp@tv-basicstudies",
                    "inputs": {
                        "length": 50,
                    }
                },
            ]}
            // save_image = "false"
            // disabled_features ={[
            //     "header_symbol_search",
            //     "symbol_search_hot_key",
            //     "header_chart_type",
            //     "header_settings", 
            //     "header_indicators", 
            //     "header_compare", 
            //     "header_undo_redo", 
            //     "header_screenshot",
            //     "border_around_the_chart",
            //     "left_toolbar",
            //     "context_menus"
            // ]}
        >
        </AdvancedRealTimeChart>);
}
export default TechnicalChart