import { createContext, useState, useEffect } from 'react';

import { lightTheme, darkTheme } from '../components/themes';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

export const ThemeContext = createContext();

const CustomThemeProvider = (props) => {
  const [theme, setTheme] = useState(lightTheme);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
    setTheme(theme === lightTheme ? darkTheme : lightTheme)

    if (theme === lightTheme) localStorage.setItem('darkMode', true)
    else localStorage.removeItem('darkMode')
  };

  useEffect(() => {
    if (localStorage.getItem('darkMode')) {
      setIsDarkMode(true)
      setTheme(darkTheme)
    }
  }, [])

  return (
    <ThemeContext.Provider value={{ theme: theme, isDarkMode: isDarkMode, toggle: toggleTheme }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {props.children}
        </ThemeProvider>
      </StyledEngineProvider>
    </ThemeContext.Provider>
  );
};

export default CustomThemeProvider;


