import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// JSON Sort https://r37r0m0d3l.github.io/json_sort/

// Translation
const resources = {
    en: {
        translation: {
            "Yearly": "Yearly",
            "12 Months": "12 Months",
            "6 Months": "6 Months",
            "7 Days Trend": "7 Days Trend",
            "9 Months": "9 Months",
            "About": "About",
            "Actual EPS": "Actual EPS",
            "Add": "Add",
            "Add Customer": "Add Customer",
            "Add entry plan": "Add entry plan",
            "Add Journal": "Add Journal",
            "Address 1": "Address 1",
            "Address 2": "Address 2",
            "Admin List": "Admin List",
            "Agent List": "Agent List",
            "Amount($SGD)": "Amount($SGD)",
            "Apr": "Apr",
            "Aug": "Aug",
            "Already have an account? Login Now": "Already have an account? Login Now",
            "Back": "Back",
            "Billing History": "Billing History",
            "Cancel": "Cancel",
            "Cancelled Job": "Cancelled Job",
            "Change": "Change",
            "Change Password": "Change Password",
            "Change(%)": "Change(%)",
            "Comment": "Comment",
            "Complete": "Complete",
            "Completed": "Completed",
            "Confirm": "Confirm",
            "Contact No": "Contact No",
            "Cookie Policy": "Cookie Policy",
            "Current Password": "Current Password",
            "Current Plan": "Current Plan",
            "Customer List": "Customer List",
            "Country": "Country",
            "Dark Mode": "Dark Mode",
            "Date": "Date",
            "Dec": "Dec",
            "Differences": "Differences",
            "Disclaimer": "Disclaimer",
            "Duration": "Duration",
            "Earnings": "Earnings",
            "Edit": "Edit",
            "Edit Personal Information": "Edit Personal Information",
            "Email": "Email",
            "Email Address": "Email Address",
            "English": "English",
            "Entry Amount": "Entry Amount",
            "Entry Date": "Entry Date",
            "Entry No of Share(s)": "Entry No of Share(s)",
            "Entry Price": "Entry Price",
            "Estimated EPS": "Estimated EPS",
            "Exchange": "Exchange",
            "Exit Amount": "Exit Amount",
            "Exit Date": "Exit Date",
            "Exit No of Share(s)": "Exit No of Share(s)",
            "Exit Price": "Exit Price",
            "FAQ (Payment Instruction)": "FAQ (Payment Instruction)",
            "Favourites": "Favourites",
            "Feb": "Feb",
            "Feedback": "Feedback",
            "First Name": "First Name",
            "Frequently Asked Questions": "Frequently Asked Questions",
            "Full Name": "Full Name",
            "General": "General",
            "Graph": "Graph",
            "Have feedback? We would love to hear it, but please dont share any sensitive information.": "Have feedback? We would love to hear it, but please dont share any sensitive information.",
            "Highlights": "Highlights",
            "Home": "Home",
            "Hot Sector": "Hot Sector",
            "Jan": "Jan",
            "Journal": "Journal",
            "July": "July",
            "June": "June",
            "Key Activation": "Key Activation",
            "Language": "Language",
            "Language Selection": "Language Selection",
            "Last Name": "Last Name",
            "Latest News": "Latest News",
            "License": "License",
            "Licenses": "Licenses",
            "Logout": "Logout",
            "Malaysia": "Malaysia",
            "Mar": "Mar",
            "May": "May",
            "Mobile Number": "Mobile Number",
            "Most Searched": "Most Searched",
            "Most Triggered": "Most Triggered",
            "My Customers": "My Customers",
            "Name": "Name",
            "New Password": "New Password",
            "News": "News",
            "Next": "Next",
            "No of Days": "No of Days",
            "No result found": "No result found",
            "Normal": "Normal",
            "Notifications": "Notifications",
            "Nov": "Nov",
            "Oct": "Oct",
            "Or": "Or",
            "Password": "Password",
            "Payment Summary": "Payment Summary",
            "Pending Job": "Pending Job",
            "Personal Information": "Personal Information",
            "Phone": "Phone",
            "Phone No.": "Phone No.",
            "Phone Number": "Phone Number",
            "Plan": "Plan",
            "Premium": "Premium",
            "Privacy Policy": "Privacy Policy",
            "Pro": "Pro",
            "Profile": "Profile",
            "Profile Setting": "Profile Setting",
            "Publish Date": "Publish Date",
            "Purchaser Name": "Purchaser Name",
            "Postal Code": "Postal Code",
            "Pricing": "Pricing",
            "Quantity": "Quantity",
            "Range": "Range",
            "Renewal": "Renewal",
            "Renewal Or Upgrade": "Renewal Or Upgrade",
            "Repeat Password": "Repeat Password",
            "Sales Management": "Sales Management",
            "Search": "Search",
            "Search Ticker": "Search Ticker",
            "Searching .....": "Searching .....",
            "Sector": "Sector",
            "Sep": "Sep",
            "Simplified Chinese": "简体中文",
            "Status": "Status",
            "Top Activities": "Top Activities",
            "Stocks": "Stocks",
            "Submit": "Submit",
            "Subscription": "Subscription",
            "Summary": "Summary",
            "State": "State",
            "Singapore": "Singapore",
            "Sign Up": "Sign Up",
            "Telegram Group": "Telegram Group",
            "Terms of Use": "Terms of Use",
            "Ticker": "Ticker",
            "Ticker Name": "Ticker Name",
            "Time": "Time",
            "Title": "Title",
            "Today Hit": "Today's Hit",
            "Yesterday Gainer": "Yesterday Gainer",
            "Top Loser": "Top Loser",
            "Most Gainer": "Most Gainer",
            "Trigger Date": "Trigger Date",
            "Testimonial": "Testimonial",
            "United State": "United State",
            "Upcoming Events": "Upcoming Events",
            "Upgrade": "Upgrade",
            "Upgrade OR Renew": "Upgrade OR Renew",
            "User Agreements": "User Agreements",
            "User Management": "User Management",
            "Wait List": "Wait List",
            "Your Information": "Your Information",
            "Subscribed Exchange" : "Subscribed Exchange",
            //Visitor
            "Removed the noise. Focus on what matters.":"Removed the noise. Focus on what matters.",
            "Take the complication out of trading with Whisper.":"Take the complication out of trading with Whisper.",
            "Powerful Simplicity":"Powerful Simplicity",
            "Intelligently adapts to any device. Tables are carefully engineered for best interactivity, both for powerful desktops with a mouse, and touch-optimized for tablets and phones.":"Intelligently adapts to any device. Tables are carefully engineered for best interactivity, both for powerful desktops with a mouse, and touch-optimized for tablets and phones.",
            "Simple on the surface, intelligent underneath.":"Simple on the surface, intelligent underneath.",
            "Our proprietary algorithm is connected to private data provider that provide the most accurate data.":"Our proprietary algorithm is connected to private data provider that provide the most accurate data.",
            "Access to Whisper, any time.":"Access to Whisper, any time.",
            "Whisper works on the biggest market such as Cryptocurrency Exchange.":"Whisper works on the biggest market such as Cryptocurrency Exchange.",
            "Understand hidden divergences":"Understand hidden divergences",
            "Additional features such as Whisper favourites help you remember your favourite ticker for future references":"Additional features such as Whisper favourites help you remember your favourite ticker for future references",
            "Never miss out on trending tickers again":"Never miss out on trending tickers again",
            "Get access to automated daily top searched within our website.":"Get access to automated daily top searched within our website.",
            "Whisper make simple":"Whisper make simple",
            "Join our free private discussion group by signing up!":"Join our free private discussion group by signing up!",
            "What our customer say":"What our customer say",
            "I have been investing for about 15 years, mainly long term, blue chip dividends, etc. But I wanted a little extra edge/confirmation with my day trades. This took away all the extra noise and helped me confirm my picks.":"I've been investing for about 15 years, mainly long term, blue chip dividends, etc. But I wanted a little extra edge/confirmation with my day trades. This took away all the extra noise and helped me confirm my picks.",
            "Whisper is amazing. I am very much an inexperienced trader (3months) and up to a week ago have been following signals from other traders. Whisper is not just update daily tickers but what it does do is give you an idea of how the market is trending.":"Whisper is amazing. I am very much an inexperienced trader (3months) and up to a week ago have been following signals from other traders. Whisper is not just update daily tickers but what it does do is give you an idea of how the market is trending.",
            "Whisper telegram community is great. It gives me the opportunity to meet and discuss my trading strategy with other expert.":"Whisper telegram community is great. It gives me the opportunity to meet and discuss my trading strategy with other expert.",
            "Anonymous":"Anonymous",
            "Start Your Free Trial Today":"Start Your Free Trial Today",
            "Be a part of a group of community who use whisper to view the market trend.":"Be a part of a group of community who use whisper to view the market trend.",
            "Whisper and its team members are not registered as financial advisors and hold no formal qualifications to give financial advice. Everything that is provided on this server, on the Whisper website or by Whisper and its team members is purely for educational purposes only. Whisper and its team members are not accountable or liable for any losses or damages. You are responsible for all the risks you take. Any content provided here should not be construed as financial advice.":"Whisper and its team members are not registered as financial advisors and hold no formal qualifications to give financial advice. Everything that is provided on this server, on the Whisper website or by Whisper and its team members is purely for educational purposes only. Whisper and its team members are not accountable or liable for any losses or damages. You are responsible for all the risks you take. Any content provided here should not be construed as financial advice.",
            "@2022 Whisper. All Right Reserved.":"@2022 Whisper. All Right Reserved.",
            //Pricing
            "Free 1 week trial":"Free 1 week trial",
            "Save 15% per month":"Save 15% per month",
            "Save 20% per month":"Save 20% per month",
            "Trial":"Trial",
            "Starter":"Starter",
            "Pro":"Pro",
            "Choose the right plan for you":"Choose the right plan for you",
            "Price Change":"Price Change",
            "Volume Change":"Volume Change",
            "Fundamental": "Fundamental",
        }
    },
    sch: {
        translation: {
            "Yearly": "1 年",
            "6 Months": "6 月",
            "3 Months": "3 月",
            "7 Days Trend": "7天趋势",
            "9 Months": "9 月",
            "About": "关于",
            "Actual EPS": "实际每股收益",
            "Add": "添加",
            "Add Customer": "添加客户",
            "Add entry plan": "添加入股计划",
            "Add Journal": "添加日志",
            "Address 1": "地址 1",
            "Address 2": "地址 2",
            "Admin List": "管理管名单",
            "Agent List": "代理商名单",
            "Amount($SGD)": "价钱($新币)",
            "Apr": "4月",
            "Aug": "8月",
            "Already have an account? Login Now": "已经有一个帐户？立即登录",
            "Back": "上一页",
            "Billing History": "账单记录",
            "Cancel": "取消",
            "Cancelled Job": "取消的工作",
            "Change": "跳动",
            "Change Password": "更换密码",
            "Change(%)": "跳动(%)",
            "Comment": "评论",
            "Complete": "完成",
            "Completed": "完成",
            "Confirm": "确认",
            "Contact No": "电话号码",
            "Cookie Policy": "Cookie政策",
            "Current Password": "当前密码",
            "Current Plan": "目前方案",
            "Customer List": "客户名单",
            "Country": "国家",
            "Dark Mode": "暗黑模式",
            "Date": "日期",
            "Dec": "12月",
            "Differences": "差异性",
            "Disclaimer": "免责声明",
            "Duration": "为期",
            "Earnings": "收益",
            "Edit": "编辑",
            "Edit Personal Information": "编辑个人资料",
            "Email": "电邮",
            "Email Address": "电邮地址",
            "English": "English",
            "Entry Amount": "入股数量",
            "Entry Date": "入股日期",
            "Entry No of Share(s)": "入股 股份数量",
            "Entry Price": "入股价格",
            "Estimated EPS": "预估每股收益",
            "Exchange": "外汇",
            "Exit Amount": "退股 股份数量",
            "Exit Date": "退股日期",
            "Exit No of Share(s)": "退股 股份数量",
            "Exit Price": "退股价格",
            "FAQ (Payment Instruction)": "常问问题 (付款说明)",
            "Favourites": "收藏夹",
            "Feb": "2月",
            "Feedback": "反馈",
            "First Name": "名",
            "Frequently Asked Questions": "常见问题解答",
            "Full Name": "全名",
            "General": "一般解答",
            "Graph": "图表",
            "Have feedback? We would love to hear it, but please dont share any sensitive information.": "有提议吗？ 我们乐于接受，请不要分享任何敏感信息。 ",
            "Highlights": "重点",
            "Home": "主页",
            "Hot Sector": "热门行业",
            "Jan": "1月",
            "Journal": "日志",
            "July": "7月",
            "June": "6月",
            "Key Activation": "钥匙激活",
            "Language": "语言",
            "Language Selection": "选择语言",
            "Last Name": "姓",
            "Latest News": "最新消息",
            "License": "执照",
            "Licenses": "执照",
            "Logout": "注销",
            "Malaysia": "马来西亚",
            "Mar": "3月",
            "May": "5月",
            "Mobile Number": "电话号码",
            "Most Searched": "搜索最多",
            "Most Triggered": "触发最多",
            "My Customers": "我的顾客",
            "Name": "名字",
            "New Password": "新密码",
            "News": "新闻",
            "Next": "下一页",
            "No of Days": "日数",
            "No result found": "找不到结果",
            "Normal": "正常",
            "Notifications": "通知",
            "Nov": "11月",
            "Oct": "10月",
            "Or": "或",
            "Password": "密码",
            "Payment Summary": "付款总结",
            "Pending Job": "待定的工作",
            "Personal Information": "个人资料",
            "Phone": "电话号码",
            "Phone No.": "电话号码",
            "Phone Number": "电话号码",
            "Plan": "方案",
            "Premium": "优质",
            "Privacy Policy": "隐私政策",
            "Pro": "专业",
            "Profile": "档案资料",
            "Profile Setting": "个人资料设置",
            "Publish Date": "发布日期",
            "Purchaser Name": "购买者姓名",
            "Postal Code": "邮政编码",
            "Pricing": "价格列表",
            "Quantity": "数量",
            "Range": "范围",
            "Renewal": "续订",
            "Renewal Or Upgrade": "续订或升级",
            "Repeat Password": "确认新密码",
            "Sales Management": "销售管理",
            "Search": "搜索",
            "Search Ticker": "搜索股票代码",
            "Searching .....": "搜寻中 .....",
            "Sector": "部门",
            "Sep": "9月",
            "Simplified Chinese": "简体中文",
            "Status": "状态",
            "Top Activities": "股票活动",
            "Stocks": "股票",
            "Submit": "提交",
            "Subscription": "订阅",
            "Summary": "总结",
            "State": "州属",
            "Singapore": "新加坡",
            "Sign Up": "注册",
            "Telegram Group": "Telegram 群",
            "Terms of Use": "使用条款",
            "Ticker": "股票代号",
            "Ticker Name": "股票代号",
            "Time": "时间",
            "Title": "标题",
            "Today Hit": "今日热门",
            "Yesterday Gainer": "昨天的赢家",
            "Top Loser": "失败者",
            "Most Gainer": "最大赢家",
            "Trigger Date": "触发日期",
            "Testimonial": "用户反馈",
            "United State": "美国",
            "Upcoming Events": "即将到来的活动",
            "Upgrade": "升级",
            "Upgrade OR Renew": "升级或续订",
            "User Agreements": "用户协议",
            "User Management": "用户管理",
            "Wait List": "等待名单",
            "Your Information": "您的资料",
            "Subscribed Exchange" : "订阅服务",
            //Visitor
            "Removed the noise. Focus on what matters.":"消除噪音,专注于重要的事情。",
            "Take the complication out of trading with Whisper.":"消除交易的复杂性。",
            "Powerful Simplicity":"强大的简单性",
            "Intelligently adapts to any device. Tables are carefully engineered for best interactivity, both for powerful desktops with a mouse, and touch-optimized for tablets and phones.":"智能适应任何设备。桌子经过精心设计，可实现最佳交互性，既适用于带有鼠标的功能强大的桌面，也适用于平板电脑和手机的触控优化。",
            "Simple on the surface, intelligent underneath.":"表面上简单，下层聪明",
            "Our proprietary algorithm is connected to private data provider that provide the most accurate data.":"我们的专有算法连接到提供最准确数据的私人数据提供商",
            "Access to Whisper, any time.":"随时访问 Whisper。",
            "Whisper works on the biggest market such as Cryptocurrency Exchange.":"Whisper 在最大的市场上工作，例如加密货币交易所。 ",
            "Understand hidden divergences":"了解隐藏的分歧",
            "Additional features such as Whisper favourites help you remember your favourite ticker for future references":"Whisper 收藏夹等附加功能可帮助您记住您最喜欢的股票代码以供将来参考",
            "Never miss out on trending tickers again":"再也不会错过热门股票",
            "Get access to automated daily top searched within our website.":"访问我们网站内的自动每日热门搜索。",
            "Whisper make simple":"Whiser让生活变得简单",
            "Join our free private discussion group by signing up!":"通过注册加入我们的免费私人讨论组！",
            "What our customer say":"我们的客户怎么说",
            "I have been investing for about 15 years, mainly long term, blue chip dividends, etc. But I wanted a little extra edge/confirmation with my day trades. This took away all the extra noise and helped me confirm my picks.":"我已经投资了大约 15 年，主要是长期、蓝筹股红利等。但我希望在我的日间交易中获得一些额外的优势/确认。这消除了所有额外的噪音并帮助我确认了我的选择。",
            "Whisper is amazing. I am very much an inexperienced trader (3months) and up to a week ago have been following signals from other traders. Whisper is not just update daily tickers but what it does do is give you an idea of how the market is trending.":"我是一个非常缺乏经验的交易者3 个月，直到一周前一直在关注其他交易者的信号。 Whisper 不仅更新每日代码，而且它的作用是让您了解市场趋势。",
            "Whisper telegram community is great. It gives me the opportunity to meet and discuss my trading strategy with other expert.":"Whiser 电报社区很棒。它让我有机会与其他专家会面并讨论我的交易策略。",
            "Anonymous":"无名",
            "Start Your Free Trial Today":"开始免费试用",
            "Be a part of a group of community who use whisper to view the market trend.":"成为一群使用耳语查看市场趋势的社区的一员。",
            "Whisper and its team members are not registered as financial advisors and hold no formal qualifications to give financial advice. Everything that is provided on this server, on the Whisper website or by Whisper and its team members is purely for educational purposes only. Whisper and its team members are not accountable or liable for any losses or damages. You are responsible for all the risks you take. Any content provided here should not be construed as financial advice.":"Whisper 及其团队成员未注册为财务顾问，也不具备提供财务建议的正式资格。此服务器、Whisper 网站或 Whisper 及其团队成员提供的所有内容仅用于教育目的。 Whisper 及其团队成员对任何损失或损害概不负责。您应对您承担的所有风险负责。此处提供的任何内容不应被视为财务建议。",
            "@2022 Whisper. All Right Reserved.":"@2022 Whisper. 保留所有权利。",
            //Pricing
            "Free 1 week trial":"1 周免费试用",
            "Save 15% per month":"每月节省 15%",
            "Save 20% per month":"每月节省 20%",
            "Trial":"考验",
            "Starter":"入门版",
            "Pro":"专业版",
            "Choose the right plan for you":"选择适合您的计划",
            "Price Change":"价格变化",
            "Volume Change":"成交量变化",
            "Fundamental":"基础知识"
        }
    }
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n;