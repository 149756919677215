import { useSelector, useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles';
import { setExchange } from '../../redux/uiSlice'

import Chip from '@mui/material/Chip';

const PREFIX = 'ExchangeToggle';

const classes = {
    root: `${PREFIX}-root`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    }
}));

export default function ExchangeToggle(props) {

    const { exchangeCountries } = useSelector(state => state.ui.home)
    const dispatch = useDispatch()

    const toggleExchange = (exchangeName) => {
        dispatch(setExchange(exchangeName))
        // let temp_exchangeCountriesObj = [...authCtx.auth.exchangeCountries]

        // reset all selected status
        // temp_exchangeCountriesObj
        //     .find((country) => country.selected)
        //     .exchanges.map((exchange) => {
        //         exchange.value = false;

        //         if (exchange.name === exchangeName)
        //             exchange.value = true;

        //         return exchange; 
        //     });

        // setAuthCtx(prevState => ({
        //     ...prevState,
        //     auth: {
        //         ...prevState.auth,
        //         exchangeCountries: temp_exchangeCountriesObj
        //     }
        // }))
    }

    return (
        <Root className={classes.root}>
            {exchangeCountries
                .filter(country => country.selected)
                .map(selectedCountry => selectedCountry.exchanges
                    .map(exchange =>
                        <Chip
                            key={exchange.name}
                            size="medium"
                            label={exchange.name}
                            clickable
                            color={exchange.value ? 'primary' : 'default'}
                            onClick={() => toggleExchange(exchange.name)}
                        />
                    ))
            }
        </Root>
    );
}