import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

// hoc
import ExchangeInfo from '../hoc/Layouts/ExchangeInfo';

// components
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import BasicTable from '../components/Table/BasicTable';
import { MultiTabs } from '../components/Tab/SimpleTabs';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import TableRow from '../components/Table/TableRow';
import LineGraph from '../components/Graph/LineGraph';
import Typo from '../components/Text/Typo';

const PREFIX = 'UpcomingEvents';

const classes = {
  hiddenspace: `${PREFIX}-hiddenspace`
};

const StyledExchangeInfo = styled(ExchangeInfo)((
  {
    theme
  }
) => ({
  [`& .${classes.hiddenspace}`]: {
    padding: '2%',
    [theme.breakpoints.down('xl')]: {
      padding: '10%',
    },
  }
}));

export default function UpcomingEvents(props) {

  const { t } = useTranslation()
  const history = useHistory();

  const profileRedirectHandler = (equity) => {
    const queryParams = '?' + encodeURIComponent('exchange') + '=' + encodeURIComponent(equity.exchange)
      + '&' + encodeURIComponent('ticker') + '=' + encodeURIComponent(equity.ticker);

    history.push({
      pathname: '/Equity',
      search: queryParams,
    });
  }

  // Dummy ticker data
  const data = [
    { day: 1, value: 200 },
    { day: 2, value: 500 },
    { day: 3, value: 212 },
    { day: 4, value: 900 },
    { day: 5, value: 300 },
    { day: 6, value: 543 },
    { day: 7, value: 1000 },
  ]

  const [stock, setStock] = useState({
    key: ['exchange', 'ticker'],
    header: [
      {
        headerName: 'Name',
        field: 'equity',
        render: (dataRow) =>
          <TableRow
            title={dataRow.ticker}
            subtitle={dataRow.name}
          />
      },
      {
        headerName: '7 Days Trend',
        field: '',
        render: () =>
          <LineGraph timeframe={data} width={'300'} height={'100'} />
      },
      {
        headerName: 'Estimated EPS',
        field: 'EEPS',
        numeric: true,
        width: 100,
      },
      {
        headerName: 'Actual EPS',
        field: 'AEPS',
        numeric: true,
        width: 100,
      },
      {
        headerName: 'Differences',
        field: 'differences',
        numeric: true,
        width: 20,
      },
      {
        headerName: 'Suprise',
        field: 'suprise',
        numeric: true,
        width: 20,
      },
    ],
    feeds: [
      {
        exchange: 'NASDAQ',
        ticker: 'MVIS',
        country: 'US',
        EEPS: 1.72,
        AEPS: 1.67,
        differences: -0.05,
        suprise: -2.9,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'CLNE',
        country: 'US',
        EEPS: -0.45,
        AEPS: -0.23,
        differences: 0.22,
        suprise: 48.22,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'VERU',
        country: 'US',
        EEPS: 0.35,
        AEPS: 0.41,
        differences: 0.06,
        suprise: 17.14,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'MARA',
        country: 'US',
        EEPS: 2.44,
        AEPS: 3.27,
        differences: 0.83,
        suprise: 34.02,
      },
    ],
  });

  const content = [
    {
      label: 'Earnings',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='small'
            searchable
            sortable
            paginable
            trxdate='21 Jan 2021 1300'
            pageSize={7}
            keys={stock.key}
            header={stock.header}
            feeds={stock.feeds}
            onDetail={profileRedirectHandler}
            maxMobileColumn={2}
          />
        </Grid>,
    }
  ];


  return (
    <StyledExchangeInfo>
      <Container disableGutters maxWidth="lg">
        <Typo variant="h6" type="title" fontWeight="bold">
          <Box fontWeight="fontWeightBold" m={0}>
            {t('Upcoming Events')}
          </Box>
        </Typo>
        <MultiTabs content={content} />
      </Container>
      <div className={classes.hiddenspace} />
    </StyledExchangeInfo>
  );
}
