import { useState } from 'react'
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

import { Container, TextField, Button } from '@mui/material'

import Rating from '../components/Rating'
import Typo from '../components/Text/Typo'
import { red } from '@mui/material/colors';

const PREFIX = 'Feedback';

const classes = {
    root: `${PREFIX}-root`,
    headingText: `${PREFIX}-headingText`,
    submitButton: `${PREFIX}-submitButton`,
    ratingContainer: `${PREFIX}-ratingContainer`,
    errorText: `${PREFIX}-errorText`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        paddingTop: '32px !important',
        paddingBottom: '80px !important'
    },

    [`& .${classes.headingText}`]: {
        marginTop: 0,
        marginBottom: '1px',
        fontWeight: 600,
        fontSize: '2rem'
    },

    [`& .${classes.submitButton}`]: {
        marginTop: theme.spacing(2)
    },

    [`& .${classes.ratingContainer}`]: {
        marginTop: '1rem',
    },

    [`& .${classes.errorText}`]: {
        color: red[500]
    }
}));

const Feedback = () => {

    const { t } = useTranslation()

    const [comment, setComment] = useState()

    const handleCommentOnChange = (ev) => { setComment(ev.target.value) }
    const handleOnSubmit = () => {
        // Validation
        if (rating === 0) setIsError(true)
        else setIsError(false)
    }

    const [rating, setRating] = useState(0)
    const hSetRating = (e) => { setRating(parseInt(e.target.value)) }

    const [isError, setIsError] = useState(false)

    return (
        <StyledContainer className={classes.root}>
            <Typo variant="h6" type="title" fontWeight="bold">
                {t('Feedback')}
            </Typo>
            <Typo variant="body1" type="title">
                {t('Have feedback? We would love to hear it, but please dont share any sensitive information.')}
            </Typo>
            <div style={{paddingTop:'3%'}}>
                <form >
                    <TextField
                        label={t("Comment")}
                        rows={5} multiline fullWidth
                        variant="outlined"
                        onChange={handleCommentOnChange}
                    />

                    <div className={classes.ratingContainer}>
                        <Rating rating={rating} setRating={hSetRating} />
                    </div>

                    {isError && <span className={classes.errorText}>Rating is required.</span>}

                    <Button
                        className={classes.submitButton}
                        variant="contained"
                        fullWidth
                        onClick={handleOnSubmit}
                    >
                        {t('Submit')}
                    </Button>
                </form>
            </div>
        </StyledContainer>
    );
}

export default Feedback