import { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import StandardDialog from '../components/Dialog/StandardDialog';
import FormGenerator from '../helper/form-generator';
import { ThemeContext } from '../context/theme-context';
import InboxIcon from '@mui/icons-material/MoveToInbox';

import {
    Container, Paper,
    Button, Radio, RadioGroup,
    Switch,
    Table, TableBody, TableCell, TableContainer, TableRow, Grid,
    FormControlLabel, FormControl, List, ListItem, ListItemButton, ListItemText, Divider, ListItemSecondaryAction, ListItemIcon, Collapse,
} from '@mui/material';
import Typo from '../components/Text/Typo';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const ProfileSettings = () => {

    const themeContext = useContext(ThemeContext)
    const { t, i18n } = useTranslation()
    const history = useHistory()

    const [profile, setProfile] = useState({
        fields: [
            {
                name: 'fistName',
                label: 'First Name',
                value: 'John',
                expandValue: []
            },
            {
                name: 'lastName',
                label: 'Last Name',
                value: 'Tan',
                expandValue: []
            },
            {
                name: 'phone',
                label: 'Phone Number',
                value: '+6018272612',
                expandValue: []
            },
            {
                name: 'email',
                label: 'Email',
                value: '1234@gmail.com',
                expandValue: []
            },
            {
                name: 'darkmode',
                label: 'Dark Mode',
                value: <Switch color="primary" checked={themeContext.isDarkMode} onChange={themeContext.toggle}/>,
                expandValue: []
            },
            {
                name: 'password',
                label: 'Password',
                value:  <Button variant="contained" color="primary" onClick={() => handleOpen_changePasswordDialog()}>{t('Change Password')}</Button>,
                expandValue: []
            },
            {
                name: 'language',
                label: 'Language',
                value: <Button onClick={() => handleOpen_languageDialog()} variant="contained" color="primary">
                            {selectedLanguage === 'en' ? 'English' : '简体中文'}
                        </Button>,
                expandValue: []
            },
            {
                name: 'exchange',
                label: 'Subscribed Exchange',
                value : '',
                expandValue: [
                    { name: 'Binance', periodStart: '1 Dec 2020', periodEnd: '31 Dec 2020' },
                    { name: 'BinancefDelivery', periodStart: '1 Dec 2020', periodEnd: '15 Dec 2020' },
                    { name: 'BinancefPerp', periodStart: '1 Jan 2021', periodEnd: '28 Feb 2021' },
                ]
            },
            {
                name: '',
                label: '',
                value: <Button onClick={() => handleOpen_editDialog()} variant="contained" color="primary">{t('Edit')}</Button>,
                expandValue: []
            }
           
        ]
    });

    //#region Password
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false)
    const handleOpen_changePasswordDialog = () => { setOpenChangePasswordDialog(true) }
    const handleClose_changePasswordDialog = () => { setOpenChangePasswordDialog(false) }
    const handleConfirm_changePasswordDialog = () => { setOpenChangePasswordDialog(false) }
    const [changePassword_formSchema, setChangePassword_formSchema] = useState({
        fields: [
            {
                name: 'currPassword',
                required: true,
                label: 'Current Password',
                type: 'password',
                fullWidth: true,
            },
            {
                name: 'newPassword',
                required: true,
                label: 'New Password',
                type: 'password',
                fullWidth: true,
            },
            {
                name: 'repeatPassword',
                required: true,
                label: 'Repeat Password',
                type: 'password',
                fullWidth: true,
            },
        ],
    })
    const handleChangePassword_onTextFieldChange = (event) => {
        const { name, value } = event.target;

        let newState = { ...changePassword_formSchema };

        newState.fields
            .find((field) => field.name === name)
            .value = value;

        setChangePassword_formSchema(newState);
    };
    //#endregion

    //#region Language
    const [selectedLanguage, setSelectedLanguage] = useState('en')
    const [openLanguageDialog, setOpenLanguageDialog] = useState(false)
    const handleOpen_languageDialog = () => { setOpenLanguageDialog(true) }
    const handleClose_languageDialog = () => { setOpenLanguageDialog(false) }
    const handleConfirm_languageDialog = () => {
        setOpenLanguageDialog(false)
        i18n.changeLanguage(selectedLanguage)
        localStorage.setItem('language', selectedLanguage)
    }
    const language_onChange = (event) => { setSelectedLanguage(event.target.value) }
    //#endregion

    //#region Edit Personal Info
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const handleOpen_editDialog = () => { setOpenEditDialog(true) }
    const handleClose_editDialog = () => { setOpenEditDialog(false) }
    const handleConfirm_editDialog = () => { setOpenEditDialog(false) }
    const [editPersonalInfo_formSchema, setEditPersonalInfo_formSchema] = useState({
        fields: [
            {
                name: 'First Name',
                required: true,
                label: 'First Name',
                fullWidth: true,
            },
            {
                name: 'Last Name',
                required: true,
                label: 'Last Name',
                fullWidth: true,
            },
            {
                name: 'Email',
                required: true,
                label: 'Email',
                fullWidth: true,
            },
            {
                name: 'Mobile Number',
                required: true,
                label: 'Mobile Number',
                fullWidth: true,
            },
        ],
    })
    const handlePersonalInfo_onTextFieldChange = (event) => {
        const { name, value } = event.target;

        let newState = { ...editPersonalInfo_formSchema };

        newState.fields
            .find((field) => field.name === name)
            .value = value;

        setEditPersonalInfo_formSchema(newState);
    };
    //#endregion

    useEffect(() => {
        if (localStorage.getItem('language'))
            setSelectedLanguage(localStorage.getItem('language'))
    }, [])

    return (
        <Container maxWidth="sm" sx={{paddingTop: '5%', paddingBottom: '5%'}}>
            <Typo variant="h5" type="title" fontWeight="bold">
                {t('Personal Information')}
            </Typo>

            <Table sx={{ width: '100%'}}>
                {
                    profile.fields.map((value) => {
                        return (
                            <>
                                <TableBody key={value.name} sx={{padding:'1.5%'}}>
                                    <TableRow>
                                        <TableCell>
                                            <Typo variant="body" type="title" fontWeight="bold">
                                                {t(value.label)}
                                            </Typo>
                                        </TableCell>
                                        <TableCell align='right'>
                                            {value.value}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                {
                                    value.name === "exchange" ?
                                        value.expandValue.map((item) => {
                                            return(
                                                <TableRow>
                                                    <TableCell>
                                                        <Typo variant="body" type="title" fontWeight="bold">
                                                            {item.name}
                                                        </Typo>
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        <Typo variant="body" type="title">
                                                            {item.periodStart} until {item.periodEnd}
                                                        </Typo>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    :  null
                                } 
                                {/* <Divider component="li" /> */}
                            </>
                        );
                    })
                }
            </Table>

            <StandardDialog
                paper
                button
                title={'Change Password'}
                open={openChangePasswordDialog}
                iconClose={handleClose_changePasswordDialog}
                close={handleConfirm_changePasswordDialog}
                confirm={handleConfirm_changePasswordDialog}
                content={

                    <form noValidate>
                        <Grid container spacing={2}>
                            <FormGenerator fields={changePassword_formSchema.fields} onChange={handleChangePassword_onTextFieldChange} />
                        </Grid>
                    </form>
                }
            />
            <StandardDialog
                paper
                button
                title={'Language Selection'}
                open={openLanguageDialog}
                iconClose={handleClose_languageDialog}
                close={handleClose_languageDialog}
                confirm={handleConfirm_languageDialog}
                content={
                    <Grid container spacing={2}>
                        <FormControl component="fieldset">
                            <RadioGroup value={selectedLanguage} onChange={language_onChange}>
                                <FormControlLabel value='en' control={<Radio color='default' />} label={t('English')} />
                                <FormControlLabel value='sch' control={<Radio color='default' />} label={t('Simplified Chinese')} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                }
            />
            <StandardDialog
                paper
                button
                title={'Edit Personal Information'}
                open={openEditDialog}
                iconClose={handleClose_editDialog}
                close={handleConfirm_editDialog}
                confirm={handleConfirm_editDialog}
                content={

                    <form noValidate>
                        <Grid container spacing={2}>
                            <FormGenerator fields={editPersonalInfo_formSchema.fields} onChange={handlePersonalInfo_onTextFieldChange} />
                        </Grid>
                    </form>
                }
            />
        </Container>
    );
}

export default ProfileSettings;