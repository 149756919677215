import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setGlobalLogin, setMemberships, setRoles, setTeams } from '../redux/authSlice'

// Material UI
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

// Mantine
import { useForm } from '@mantine/form'

// Helper
// import FormGenerator from '../helper/form-generator'

// Services
// import LoginServices from '../services/login-services'
import api from '../services/api'
import { setUser } from '../redux/authSlice'

// Lottie Animation
import Lottie from 'react-lottie'
import LoginAnim from '../components/LottieAnim/signin.json'
import Typo from '../components/Text/Typo'
import { red } from '@mui/material/colors'
const PREFIX = 'LogIn'

const classes = {
  root: `${PREFIX}-root`,
  desktop: `${PREFIX}-desktop`,
  image: `${PREFIX}-image`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
}

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
  },

  [`& .${classes.desktop}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },

  [`& .${classes.image}`]: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: red[500],
  },

  [`& .${classes.form}`]: {
    '& .MuiTextField-root': {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  }
}))

function Login() {
  const history = useHistory()
  const dispatch = useDispatch()

  // const [state, setState] = useState({
  //   fields: [
  //     {
  //       name: 'email',
  //       value: '',
  //       label: 'Email Address',
  //       required: true,
  //       fullWidth: true,
  //       autoFocus: true,
  //     },
  //     {
  //       name: 'password',
  //       value: '',
  //       label: 'Password',
  //       type: 'password',
  //       required: true,
  //       fullWidth: true,
  //     },
  //     {
  //       name: 'rememberMe',
  //       value: false,
  //       label: 'Remember me',
  //       fullWidth: true,
  //       type: 'check-box',
  //     },
  //   ],
  // })

  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    // validate: {
    //   email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    //   password: (value) => value.length < 8 ? 'At least 8 characters' : null,
    // }
  })

  const handleSignIn = async (values) => {
    const { email, password } = values
    // console.log('form values', values)

    setLoading(true)
    try {
      // await api.createSession(email, password)
      // const rAccount = await api.getAccount()

      // const { teams } = await api.getUserTeam()
      // console.log(teams)
      // const { memberships } = await api.getUserMemberships(teams[0].$id)
      // const roles = memberships[0].roles

      // dispatch(setUser(rAccount))
      // dispatch(setTeams(teams))
      // dispatch(setMemberships(memberships))
      // dispatch(setRoles(roles))

      dispatch(setUser({ $id: 'bypass' }))

      history.push('/')

    } catch (error) {
      console.error(error)
      setErrorMsg(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledGrid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />

      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}><LockOutlinedIcon /></Avatar>
          <Typo variant="h6" type="title" fontWeight="bold">Sign in</Typo>

          <form className={classes.form} onSubmit={form.onSubmit(handleSignIn)}>
            <Grid container spacing={2}>
              {/* <FormGenerator fields={state.fields} onChange={handleChange} /> */}

              <Grid item xs={12}>
                <TextField
                  required
                  autoFocus
                  fullWidth
                  variant="standard"
                  label="Email Address"
                  InputLabelProps={{ shrink: true }}
                  // {...form.getInputProps('email')}
                  error={form.errors.email}
                  helperText={form.errors.email}
                  value={form.values.email}
                  onChange={(e) => form.setFieldValue('email', e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  variant="standard"
                  label="Password"
                  InputLabelProps={{ shrink: true }}
                  error={form.errors.password}
                  helperText={form.errors.password}
                  value={form.values.password}
                  onChange={(e) => form.setFieldValue('password', e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel control={<Checkbox />} label="Remember Me" />
              </Grid>
            </Grid>

            <Button
              fullWidth
              type="submit"
              variant="contained"
              className={classes.submit}
              disabled={loading}
            >
              Sign In
            </Button>

            <Typography color="red" variant="body1" sx={{ marginBottom: 3 }}>{errorMsg}</Typography>

            <Grid container>
              <Grid item xs>
                <Link
                  variant='body2'
                  onClick={() => history.push('/ForgotPassword')}
                >
                  <Typo variant="subtitle2" type="title">
                    Forgot password?
                  </Typo>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  variant="body2"
                  onClick={() => history.push('/SignUp')}
                >
                  <Typo variant="subtitle2" type="title">
                    Don't have an account? Sign Up
                  </Typo>
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
            </Box>
          </form>
        </div>
      </Grid>
    </StyledGrid>
  )
}

export default Login