import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

import Container from '@mui/material/Container';

// components 
import Grid from '@mui/material/Grid';
import BasicTable from '../components/Table/BasicTable';
import FormGenerator from '../helper/form-generator';

// hoc
import { MultiTabs } from '../components/Tab/SimpleTabs';
import TableRow from '../components/Table/TableRow';
import LineGraph from '../components/Graph/LineGraph';
import Typo from '../components/Text/Typo';
import { HorizontalBarChart } from '../components/Graph/BarChart';
import { IconButton } from '@mui/material';
import { Download } from '@mui/icons-material';

const PREFIX = 'BillingHistory';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  hiddenspace: `${PREFIX}-hiddenspace`
};

const BillingHistory = () => {

    const { t } = useTranslation()

    const [billing, setBilling] = useState({
        key: ['date', 'receiptNumber'],
        header: [
            {
                headerName: '',
                field: '',
                render: (dataRow) =>
                  <IconButton sx={{color:"text.primary"}}>
                    <Download />
                  </IconButton>,
            },
            {
                headerName: 'Date',
                field: 'date',
            },
            {
                headerName: 'Receipt No.',
                field: 'receiptNumber',
            },
            {
                headerName: 'Amount',
                field: 'amount',
            },
        ],
        data: [
            { date: '2022-10-04', receiptNumber: '67642B75HF0170634', amount: '1000' },
            { date: '2020-11-05', receiptNumber: '163583B799358464W', amount: '7000' },
            { date: '2020-12-05', receiptNumber: '3VA98297BN400394R', amount: '7410' },
            { date: '2021-01-05', receiptNumber: '1G3583B79935B4677', amount: '15' },
            { date: '2021-02-05', receiptNumber: '56AS5D41F65SDF6S5', amount: '20' }
        ],
    });

    return (
        <>
            <Container disableGutters maxWidth="lg" sx={{paddingTop: '2%',paddingBottom: '2%'}}>  
                <Typo variant="h6" type="title" fontWeight="bold">
                    {t('Billing History')}
                </Typo>
                <Grid container item lg={12} xs={12} >
                    <BasicTable
                        placeholder='Search'
                        size='small'
                        dateSearchable
                        sortable
                        paginable
                        pageSize={10}
                        keys={billing.key}
                        header={billing.header}
                        feeds={billing.data}
                        maxMobileColumn={2}
                    />
                </Grid>
            </Container>
            <div className={classes.hiddenspace} />
        </>
    );
}

export default BillingHistory;