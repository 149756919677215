import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux'


import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// icons
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import HighlightIcon from '@mui/icons-material/Highlight';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoIcon from '@mui/icons-material/Info';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import Typo from '../Text/Typo';
import BasicButton from '../Button/BasicButton';
import { Divider } from '@mui/material';

const PREFIX = 'AppDrawer';

const classes = {
  paper: `${PREFIX}-paper`,
  marginauto: `${PREFIX}-marginauto`,
  icon: `${PREFIX}-icon`
};

const StyledDrawer = styled(Drawer)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    zIndex: 2,
    background: theme.palette.secondary.background,
    transition: 'all 0.8s',
    paddingTop: '70px',
    paddingRight: '5%',
    boxShadow: '0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)',
    position: 'absolute',
    display: 'block',
    top: 0,
    left: 0,
    backgroundSize: 'cover',
    backgroundPosition: '50%',
  },

  [`& .${classes.marginauto}`]: {
    width: 500,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.icon}`]: {
    right: '10%'
  }
}));

const AppDrawer = (props) => {

  const { t } = useTranslation()
  const history = useHistory()
  const user = useSelector(state => state.auth.user)

  const scrollToAbout = () => {
    setTimeout(() => {
      document.getElementById('aboutJump').scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }
  const scrollToTestimonial = () => {
    setTimeout(() => {
      document.getElementById('testimonialJump').scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }
  const scrollToPricing = () => {
    setTimeout(() => {
      document.getElementById('pricingJump').scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  return (
    <StyledDrawer classes={{ paper: classes.paper }} open={props.show} onClose={props.toggle(false)}>
      <div onClick={props.toggle(false)}>
        {
          user ?
            <List>
              {/* Menu for registered customer */}
              <ListItem button key='Home' onClick={() => history.push('/')}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Home')}
                  icon={<HomeIcon />}
                />
              </ListItem>
              {/* Hidden Away Until Further Enhancement */}
              {/* <ListItem button key='Upcoming Events' onClick={() => history.push('/UpcomingEvents')}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Upcoming Events')}
                  icon={<EventIcon />}
                />
              </ListItem> 
              <ListItem button key='Highlights' onClick={() => history.push('/Highlights')}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Highlights')}
                  icon={<HighlightIcon />}
                />
              </ListItem> */}
              {/* Hidden end */}
              <ListItem button key='Favourites' onClick={() => history.push('/Favourites')}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Favourites')}
                  icon={<FavoriteIcon />}
                />
              </ListItem>
              <ListItem button key='Telegram' onClick={() => history.push('/')}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Telegram Group')}
                  icon={<SupervisorAccountIcon />}
                />
              </ListItem>

              {/* Menu for sales and adminstrator */}
              <Divider />

              <ListItem button key='UserManagement' onClick={() => history.push('/UserManagement')}>
                <BasicButton
                  type="avatarbutton"
                  content={t('User Management')}
                  icon={<EmojiPeopleIcon />}
                />
              </ListItem>

              <ListItem button key='SalesManagement' onClick={() => history.push('/SalesManagement')}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Sales Management')}
                  icon={<SupervisedUserCircleIcon />}
                />
              </ListItem>

              {/* <ListItem button>
                <ListItemAvatar><Avatar> <SupervisorAccountIcon /> </Avatar></ListItemAvatar>
                <ListItemText primary={t('Telegram Group')} />
              </ListItem> */}



            </List>
            :
            <List>
              <ListItem button key='About' onClick={scrollToAbout}>
                <BasicButton
                  type="avatarbutton"
                  content={t('About')}
                  icon={<InfoIcon />}
                />
              </ListItem>
              <ListItem button key='Testimonials' onClick={scrollToTestimonial}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Testimonial')}
                  icon={<RateReviewIcon />}
                />
              </ListItem>
              <ListItem button key='Pricing' onClick={scrollToPricing}>
                <BasicButton
                  type="avatarbutton"
                  content={t('Pricing')}
                  icon={<LocalOfferIcon />}
                />
              </ListItem>
            </List>
        }
      </div>
    </StyledDrawer>
  );
}

export default AppDrawer;