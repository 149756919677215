// components
import Content from '../../hoc/Content';
import { styled } from '@mui/material/styles';
import Typo from '../Text/Typo';
import BasicButton from '../Button/BasicButton';

const TableRow = (props) => {


    return (
        <>
            {   
                props.title ? 
                    <Typo variant="subtitle1" type="title">
                        {props.title}
                    </Typo>
                : null
            }

            <div style={{ display: 'flex', alignItems: 'center',  flexWrap: 'wrap' }}>
                {
                    parseFloat(props.subtitle) ?
                        <BasicButton 
                            type="numericbutton"
                            edge="start"
                            buttonvariant = "contained"
                            variant="caption"
                            content={Number(props.subtitle)}
                        />
                    : props.subtitle !== null ?
                        <Typo variant="body2" type="title">
                            {props.subtitle}
                        </Typo>
                    : null
                }
                <div style={{paddingLeft:'5%'}}>
                    { 
                        parseFloat(props.subtitle2) ?
                            <BasicButton 
                                type="numericbutton"
                                edge="start"
                                variant="caption"
                                content={props.subtitle2}
                                percentage={true}
                            />
                        : props.subtitle2 !== null ?
                            <Typo variant="caption" type="title" fontWeight="bold">
                                {props.subtitle2}
                            </Typo>
                        : null
                    }
                </div>
            </div>
                
        </>
    );
}
export default TableRow;