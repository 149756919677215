import { useState } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'

import StarIcon from '@mui/icons-material/Star'

const PREFIX = 'Rating';

const classes = {
    root: `${PREFIX}-root`,
    hideRadioButton: `${PREFIX}-hideRadioButton`,
    iconCursor: `${PREFIX}-iconCursor`
};

const Root = styled('div')(() => ({
    [`&.${classes.root}`]: {
        display: 'flex'
    },

    [`& .${classes.hideRadioButton}`]: {
        display: 'none'
    },

    [`& .${classes.iconCursor}`]: {
        cursor: 'pointer'
    }
}));

const Rating = (props) => {

    const { rating, setRating } = props
    const [hover, setHover] = useState(null)

    return (
        <Root className={classes.root}>
            {
                [...Array(5)].map((ele, i) => {
                    const ratingValue = i + 1

                    return (
                        <label key={i}>
                            <input
                                type="radio"
                                className={classes.hideRadioButton}
                                value={ratingValue}
                                onClick={setRating}
                            />
                            <StarIcon
                                fontSize="large"
                                className={classes.iconCursor}
                                htmlColor={ratingValue <= (hover || rating) ? "#ffc107" : "#808080"}
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(null)}
                            />
                        </label>
                    )
                })
            }
        </Root>
    );
}
Rating.propTypes = {
    rating: PropTypes.number.isRequired,
    setRating: PropTypes.func.isRequired
}

export default Rating