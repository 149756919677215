import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

// components 
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import LockResetIcon from '@mui/icons-material/LockReset';
import { red } from '@mui/material/colors';

import FormGenerator from '../helper/form-generator';

import Typo from '../components/Text/Typo';

const PREFIX = 'ForgotPassword';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
  },

  [`& .${classes.image}`]: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: red[500],
  },

  [`& .${classes.form}`]: {
    '& .MuiTextField-root' : {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  }
}));

const ForgotPassword = () => {

    const history = useHistory();

    const [state, setState] = useState({
        fields: [
          {
            name: 'email',
            value: '',
            label: 'Email Address',
            required: true,
            fullWidth: true,
            autoFocus: true,
          },
        ]
    });

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
    
        let newState = {...state};
    
        newState.fields
          .find((field) => field.name === name)
          .value = type ? ( type === 'check-box' ? checked : value) : value;
    
        newState.valid = preliminaryValidation(state.fields);
    
        setState(newState);
    };
    
    const preliminaryValidation = (fields) => {
        return (
          fields
            .filter((field) => field.required)
            .filter((requiredField) => requiredField.value === '')
            .length === 0
        );
    };

    return (
      <StyledGrid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockResetIcon />
              </Avatar>
              <Typo variant="h6" type="title" fontWeight="bold">
                Reset Password
              </Typo>
              <form className={classes.form} noValidate>
                  <Grid container spacing={2}>
                      <FormGenerator fields={state.fields} onChange={handleChange}/>
                  </Grid>
                  <Button
                    type='submit'
                    fullWidth
                    disabled={!state.valid}
                    variant="contained"
                    className={classes.submit}
                  //   onClick={handleSignIn}
                  >
                    Reset Password
                  </Button>
                  <Grid container>
                    <Grid item>
                      <Link 
                        variant="body2" 
                        onClick={() => history.push('/Login')}
                      >
                        Back To Login
                      </Link>
                    </Grid>
                  </Grid>
                  <Box mt={5} />
              </form>
          </div>
          
          </Grid>
      </StyledGrid>
    );
}

export default ForgotPassword;