import { useHistory } from 'react-router-dom';

// components
import PopperMenu from './Menu/PopperMenu';

// icons
import AccountCircle from '@mui/icons-material/AccountCircle';


export default function Profile(props)  {
    return (
        <AccountCircle sx={{color:"text.primary"}}/>
    );
};

export function ProfileMenu(props) {
    const history = useHistory();

    const goProfile = () => {
        history.push('/user/profile');
        props.onClose();
    };

    const logout = () => {
        history.push('/login');       
    };

    const menuItems = [
        {
            name: 'Profile',
            onClick: goProfile,
        },
        {
            name: 'Logout',
            onClick: logout,
        },
    ];

    return (
        <PopperMenu 
            anchorEl={props.anchorEl}
            menuItems={menuItems}
            open={props.open}
            onClose={props.onClose}
        />
    );
}
