import { useState, useContext } from 'react'
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { deleteUser } from '../../redux/authSlice'

// import PropTypes from 'prop-types'
import { produce } from 'immer'

// services
import { getTickerBySymbol } from '../../services/tickerSearch-services'
import api from '../../services/api'

import {
  AppBar, Toolbar,
  List, ListItem, ListItemText, ListItemAvatar,
  IconButton, InputBase, Avatar, Badge,
  Divider,
  Grid,
  Stack,
  Box,
  Paper,
  Menu,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded';
import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import TranslateIcon from '@mui/icons-material/Translate'

import Content from '../../hoc/Content'
import StandardDialog from '../../components/Dialog/StandardDialog'
import Profile from '../Profile';
import Notification from '../Notification';
import PopperMenu from '../PopperMenu';
import Typo from '../Text/Typo';
import BasicButton from '../Button/BasicButton';
import SearchBar from '../SearchBar/SearchBar';

const PREFIX = 'PrimaryAppBar';

const classes = {
  title: `${PREFIX}-title`,
  grow: `${PREFIX}-grow`,
  menuButton: `${PREFIX}-menuButton`,
  searchIcon: `${PREFIX}-searchIcon`,
  notificationList_iconWidth: `${PREFIX}-notificationList_iconWidth`,
  primaryAppBar: `${PREFIX}-primaryAppBar`,
  listItem: `${PREFIX}-listItem`,
  symbol: `${PREFIX}-symbol`,
  name: `${PREFIX}-name`
};

const StyledList = styled(List)((
  {
    theme
  }
) => ({
  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.notificationList_iconWidth}`]: {
    minWidth: '2rem'
  },
  [`& .${classes.listItem}`]: {
    paddingLeft: '6px'
  },

  [`& .${classes.symbol}`]: {
    maxWidth: '50px',
    width: '50px',
    fontWeight: 800,
    paddingRight: '4.6rem'
  },

  [`& .${classes.name}`]: {
    wordWrap: 'break-word',
    textAlign: 'left',
    paddingRight: '0.5rem'
  }
}));



const PrimaryAppBar = (props) => {

  const history = useHistory();
  const user = useSelector(state => state.auth.user)
  const dispatch = useDispatch()

  const [appBarToggle, setAppBarToggle] = useState(true)
  const [state, setState] = useState({
    searchBarActive: false,
    searchString: '',
    menu: {
      profile: null,
      notification: null,
      searchResult: null,
      language: null
    },
  })
  const [searchResultData, setSearchResultData] = useState(null)
  const [isSearching, setIsSearching] = useState(false)

  const handleMenuOpen = (event) => {
    const updatedMenuState = produce({ ...state }, draft => {
      draft.menu[event.currentTarget.name] = event.currentTarget
    })
    setState(updatedMenuState)

  }

  const handleMenuClose = () => {
    const resetMenuState = produce({ ...state }, draft => {
      draft.menu.profile = null
      draft.menu.notification = null
      draft.menu.searchResult = null
      draft.menu.language = null
    })

    setState(resetMenuState)
  }

  // *******************
  // Search Bar
  // *******************
  const onClear = () => setState({ ...state, searchString: '' });

  const Searchbar_onChange = async (event) => {
    setIsSearching(true)
    setState({
      ...state, searchString: event.target.value
    })

    // tickerSearch-services
    let searchInput = event.target.value
    const { data, error } = await getTickerBySymbol(searchInput)
    if (!error && data) {
      // remove unwanted property
      const formatted = data.map(d => { delete d.currency; delete d.stockExchange; return d })
      setSearchResultData(formatted)
    }

    setIsSearching(false)
  }

  // Notification
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [opendialogProfile, setOpenProfile] = useState(false);
  const [opendialogNotification, setOpenNotification] = useState(false);

  const hOpenSearchDialog = () => setOpenSearchDialog(true)
  const hCloseSearchDialog = () => setOpenSearchDialog(false)
  const hProfileOpen = () => { setOpenProfile(true); };
  const hProfileClose = () => { setOpenProfile(false); };
  const hNotificationOpen = () => { setOpenNotification(true); };
  const hNotificationClose = () => { setOpenNotification(false); };

  const Navigation = {
    profileSetting: () => { hProfileClose(); history.push('/Profile/Settings') },
    billingHistory: () => { hProfileClose(); history.push('/BillingHistory') },
    feedback: () => { hProfileClose(); history.push('/Feedback') },
    faq: () => { hProfileClose(); history.push('/FAQ') },
    license: () => { hProfileClose(); history.push('/License') },
    userAgreement: () => { hProfileClose(); history.push('/UserAgreement') },
    about: () => { hProfileClose(); history.push('/About') },
    logout: async () => {
      hProfileClose()
      await api.deleteSession()
      dispatch(deleteUser())
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed' style={{ zIndex: 3 }} >
        {appBarToggle ?
          <>
            <Toolbar>
              <Drawer prop={props} />
              <SiteName prop={props} />
              <Box sx={{ flexGrow: 1 }} />
              {
                user
                  ?
                  <User_Appbar_Right prop={props} onSearchClick={hOpenSearchDialog} onNotificationClick={hNotificationOpen} onProfileClick={hProfileOpen} />
                  :
                  <Visitor_AppBar_Right prop={props} onSearchClick={hOpenSearchDialog} languageMenuOnClick={handleMenuOpen} />

              }

            </Toolbar>
          </>
          :
          null
        }
      </AppBar>

      <Search_Dialog
        open={openSearchDialog}
        onClose={hCloseSearchDialog}
        onClear={onClear}
        onChange={Searchbar_onChange}
        value={state.searchString}
        results={searchResultData}
        isSearching={isSearching}
      />
      <Notification_Dialog open={opendialogNotification} onClose={hNotificationClose} prop={props} />
      <Profile_Dialog open={opendialogProfile} onClose={hProfileClose} navigation={Navigation} />
    </Box>
  );
}

// -------------- Global -----------------
const SiteName = (props) => {
  const history = useHistory()
  const { prop: __prop } = props
  return (
    <BasicButton
      sx={{ paddingLeft: '1%' }}
      type="avatarbutton"
      onClick={() => { __prop.onHome(); history.push('/'); }}
      src="../img/icon.png"
      variant="body1"
      content="Whisper"
    />
  )
}

// -------------- Shared between Visitor And Logined User ----------------
const Drawer = (props) => {

  const { prop: __prop } = props
  const user = useSelector(state => state.auth.user)

  return (
    <>
      {
        user
          ? (
            <BasicButton
              type="iconbutton"
              edge="start"
              className={classes.menuButton}
              onClick={__prop.openDrawer(true)}
              icon={<MenuIcon />}
            />
          )
          :
          <BasicButton
            type="iconbutton"
            edge="start"
            className={classes.menuButton}
            onClick={__prop.openDrawer(true)}
            icon={<MenuIcon />}
          />
      }
    </>
  )
}

// Search Dialog
const Search_Dialog = (props) => {

  const { t } = useTranslation()
  const history = useHistory()
  const { open, onClose, onClear, onChange, isSearching, value, results: _results } = props

  /*
    results [{
      symbol | string
      name | string
      exchangeShortName | string
    }]
  */
  const searchResultRedirect = (result) => {
    const queryParams =
      '?' + encodeURIComponent('exchange') +
      '=' + encodeURIComponent(result.exchangeShortName) +
      '&' + encodeURIComponent('ticker') +
      '=' + encodeURIComponent(result.symbol);

    history.push({
      pathname: '/Equity',
      search: queryParams
    })
  }

  const NoResultFound = () => {
    return (
      <ListItem className={classes.defaultCursor}>
        <ListItemText primary={t('No result found')} className={classes.name} />
      </ListItem>
    )
  }

  const Searching = () => {
    return (
      <ListItem className={classes.defaultCursor}>
        <ListItemText primary={t('Searching .....')} className={classes.name} />
      </ListItem>
    )
  }

  const RenderResultsList = (props) => {
    const { results } = props

    if (isSearching) { return (<Searching />) }

    if (results && results.length === 0) { return (<NoResultFound />) }

    return (
      results.map(result =>
        <ListItem onClick={() => { searchResultRedirect(result); onClose(); onClear() }} className={classes.listItem}>
          <ListItemText primary={result.symbol} disableTypography className={classes.symbol} />
          <ListItemText primary={result.name} className={classes.name} />
        </ListItem>
      )
    )
  }
  return (
    <StandardDialog open={open}
      content={
        <>
          <AppBar style={{ position: 'relative' }}>
            <Toolbar>
              <SearchBar
                placeholder="Search"
                change={onChange}
                clear={onClear}
                value={value}
                onBack={onClose}
                fullWidth
              />
              {/* <IconButton edge="start" onClick={onClose} size="large">
                <ArrowBackIcon />
              </IconButton>
              <InputBase
                placeholder="Search"
                autoFocus
                fullWidth
                onChange={onChange}
                value={value}
              />
              <IconButton edge="end" onClick={onClear} size="large">
                <CloseIcon />
              </IconButton> */}
            </Toolbar>
          </AppBar>
          <List dense>
            {_results && <RenderResultsList results={_results} />}
          </List>
        </>
      }
    />
  );
}

// -------------- Visitor -----------------
// Desktop - before login
const Visitor_AppBar_Right = (props) => {

  const history = useHistory();

  const goLogin = () => history.push('/Login');

  //#region Language
  const { t, i18n } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const [openLanguageDialog, setOpenLanguageDialog] = useState(false)
  const handleOpen_languageDialog = () => { setOpenLanguageDialog(true) }
  const handleClose_languageDialog = () => { setOpenLanguageDialog(false) }
  const handleConfirm_languageDialog = () => {
    setOpenLanguageDialog(false)
    i18n.changeLanguage(selectedLanguage)
    localStorage.setItem('language', selectedLanguage)
  }
  const language_onChange = (event) => { setSelectedLanguage(event.target.value) }
  //#endregion

  return (
    <Box>
      <BasicButton
        type="avatarbutton"
        variant="body1"
        name="search"
        content={t('Search')}
        onClick={props.onSearchClick}
        icon={<SearchIcon sx={{ color: "text.primary" }} />}
      />
      <BasicButton
        type="avatarbutton"
        variant="body1"
        name="language"
        content={t('Language')}
        icon={<TranslateIcon />}
        onClick={handleOpen_languageDialog}
        hiddentext
      />
      <StandardDialog
        paper
        button
        title={'Language Selection'}
        open={openLanguageDialog}
        iconClose={handleClose_languageDialog}
        close={handleClose_languageDialog}
        confirm={handleConfirm_languageDialog}
        content={
          <Grid container spacing={2}>
            <FormControl component="fieldset">
              <RadioGroup value={selectedLanguage} onChange={language_onChange}>
                <FormControlLabel value='en' control={<Radio color='default' />} label={t('English')} />
                <FormControlLabel value='sch' control={<Radio color='default' />} label={t('Simplified Chinese')} />
              </RadioGroup>
            </FormControl>
          </Grid>
        }
      />
      <BasicButton
        type="avatarbutton"
        variant="body1"
        content={t('Login')}
        icon={<PersonIcon />}
        onClick={goLogin}
        hiddentext
      />
    </Box>
  )
}

// -------------- LoggedIn -----------------
// Search Button toggle AppBar
// -----------------------------------------
// AppBar R - Notification
const User_Appbar_Right = (props) => {

  const { prop: __prop, onSearchClick, onNotificationClick, onProfileClick } = props
  return (
    <Box>
      <BasicButton
        type="iconbutton"
        onClick={onSearchClick}
        icon={<SearchIcon sx={{ color: "text.primary" }} />}
      />
      <BasicButton
        type="iconbutton"
        onClick={onNotificationClick}
        icon={<Notification
          count={__prop.notifications.filter((notification) =>
            !notification.read).length}
        />}
      />
      <BasicButton
        type="iconbutton"
        edge="end"
        onClick={onProfileClick}
        icon={<Profile />}
      />
    </Box>
  )
}

// Profile Dialog
const Profile_Dialog = (props) => {

  const { t } = useTranslation()
  const { open, onClose, navigation } = props

  return (
    <StandardDialog
      paper
      title={t('Profile Setting')}
      content={
        <List>
          <ListItem button onClick={navigation.profileSetting}>
            <ListItemAvatar><Avatar sx={{ color: "text.primary", backgroundColor: "transparent" }}>  <PersonIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem button onClick={navigation.license}>
            <ListItemAvatar><Avatar sx={{ color: "text.primary", backgroundColor: "transparent" }}>  <CopyrightRoundedIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('License')} />
          </ListItem>
          <ListItem button onClick={navigation.logout}>
            <ListItemAvatar><Avatar sx={{ color: "text.primary", backgroundColor: "transparent" }}>  <ExitToAppIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('Logout')} />
          </ListItem>
          {/* Hidden Away Until Further Enhancement */}
          {/* <ListItem button onClick={navigation.billingHistory}>
            <ListItemAvatar><Avatar sx={{color:"text.primary", backgroundColor:"transparent"}}>  <EventNoteRoundedIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('Billing History')} />
          </ListItem>
          <ListItem button onClick={navigation.feedback}>
            <ListItemAvatar><Avatar sx={{color:"text.primary", backgroundColor:"transparent"}}>  <AnnouncementRoundedIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('Feedback')} />
          </ListItem>
          <ListItem button onClick={navigation.faq}>
            <ListItemAvatar><Avatar sx={{color:"text.primary", backgroundColor:"transparent"}}>  <HelpOutlineRoundedIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('FAQ (Payment Instruction)')} />
          </ListItem>
          <ListItem button onClick={navigation.userAgreement}>
            <ListItemAvatar><Avatar sx={{color:"text.primary", backgroundColor:"transparent"}}>  <PlaylistAddCheckRoundedIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('User Agreements')} />
          </ListItem>
          <ListItem button onClick={navigation.about}>
            <ListItemAvatar><Avatar sx={{color:"text.primary", backgroundColor:"transparent"}}>  <InfoRoundedIcon /> </Avatar></ListItemAvatar>
            <ListItemText primary={t('About')} />
          </ListItem>
           */}
          {/* Hidden end */}
        </List>
      }
      open={open}
      iconClose={onClose}
    />
  )
}

// Notification Dialog
const Notification_Dialog = (props) => {

  const { t } = useTranslation()
  const { open, onClose, prop: __porp } = props
  // __prop as passed over props OR parent props

  return (
    <StandardDialog
      paper
      title={t('Notifications')}
      padding='0px'
      content={
        <List>
          {__porp.notifications.map((notification, index) =>
            <ListItem
              key={index}
              alignItems='flex-start'
            >
              <ListItemAvatar>
                <Badge
                  color="info"
                  overlap="circular"
                  badgeContent=" "
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  variant="dot"
                  invisible={!notification.dot}
                  style={{ zIndex: 0 }}
                >
                  <Avatar alt={notification.provider} src={notification.imgurl} />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={notification.title}
                secondary={
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <Typo variant="body2" type="title" fontSize='12px'>
                      {notification.provider + " - "}
                    </Typo>
                    <Typo variant="body2" type="title" fontSize='12px'>
                      {notification.content}
                    </Typo>
                  </div>
                }
              />

            </ListItem>
          )}
        </List>
      }
      open={open}
      iconClose={onClose}
    />
  );
}

export default PrimaryAppBar;
