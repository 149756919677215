import { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'

// MUI
import {
  Avatar, Button, Link, Paper, Grid, DialogActions, CardMedia, Stepper, Step, StepLabel,
  TextField,
} from '@mui/material'
import { red } from '@mui/material/colors'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'

// components
import StandardDialog from '../components/Dialog/StandardDialog'
import TableRow from '../components/Table/TableRow'
import PeopleAnim from '../components/LottieAnim/people.json'
import Typo from '../components/Text/Typo'
import PrivacyStatementHtm from '../documents/PrivacyStatement.htm'
import useWindowDimensions from '../../src/services/useWindowDimensions'

// services
import api from '../services/api'

// hooks
import { useForm } from '@mantine/form'

// helper
import FormGenerator from '../helper/form-generator'

const PREFIX = 'SignUp'

const classes = {
  root: `${PREFIX}-root`,
  desktop: `${PREFIX}-desktop`,
  image: `${PREFIX}-image`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  groupField: `${PREFIX}-groupField`,
  buttons: `${PREFIX}-buttons`,
  instructions: `${PREFIX}-instructions`,
  LottieImg: `${PREFIX}-LottieImg`
}

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    height: '400px',
  },

  [`& .${classes.desktop}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },

  [`& .${classes.image}`]: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh'
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(3, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: red[500],
  },

  [`& .${classes.form}`]: {
    '& .MuiTextField-root': {
      width: '100%',
      marginTop: theme.spacing(1),
    },
  },

  [`& .${classes.submit}`]: {
    display: 'flex',
    padding: '24px',
  },

  [`& .${classes.groupField}`]: {
    width: 200,
  },

  [`& .${classes.buttons}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.LottieImg}`]: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  }
}))

export default function SignUp(props) {
  const { t } = useTranslation()
  const history = useHistory()

  const { height, width } = useWindowDimensions()
  var newheight = (width < 599) ? height - 200 : 500

  const form = useForm({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      mobileNumber: '',
      password: '',
      repeatPassword: '',
      refId: ''
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => value.length < 8 ? 'At least 8 characters' : null,
      repeatPassword: (value, formValues) => value !== formValues.password ? `Repeat password doesn't match` : null,
    }
  })

  const [loading, setLoading] = useState(false)
  const steps = ['Your Infomation', 'Summary']
  const [activeStep, setActiveStep] = useState(0)

  const handleSubmit = (values) => {
    // console.log(values)
    setOpenedAgreement(true)
  }

  const [openedAgreement, setOpenedAgreement] = useState(true)
  const [isAgreed, setIsAgreed] = useState(false)
  const agreeCheckboxField = {
    fields: [
      {
        label: 'Click here to indicate that you have read and agree to the terms of use of Whisper',
        type: 'check-box',
      }
    ]
  }

  const closeAgreement = () => {
    setIsAgreed(false)
    setOpenedAgreement(false)
  }
  const handleAgreed_register = async () => {
    const formValues = form.values

    setLoading(true)
    try {
      const r = await api.executeRegisterUser(formValues)
      const response = JSON.parse(r.response)
      // console.log(r)

      if (response.success === true) {
        closeAgreement()
        setActiveStep(1)
      } else {
        alert(response.error)
      }
    } catch (error) {
      console.error(error)
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StyledGrid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}><PersonAddAltIcon /></Avatar>
          <Typo variant="h6" type="title" fontWeight="bold">{t('Sign Up')}</Typo>
        </div>

        <Stepper activeStep={activeStep} alternativeLabel>
          {
            steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))
          }
        </Stepper>

        {
          activeStep === 0 &&
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
              <StyledGrid container spacing={2} >
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    autoFocus
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label="First Name"
                    // error
                    // helperText
                    value={form.values.firstname}
                    onChange={(e) => { form.setFieldValue('firstname', e.target.value) }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label="Last Name"
                    // error
                    // helperText
                    value={form.values.lastname}
                    onChange={(e) => { form.setFieldValue('lastname', e.target.value) }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label="Email Address"
                    error={form.errors.email}
                    helperText={form.errors.email}
                    value={form.values.email}
                    onChange={(e) => { form.setFieldValue('email', e.target.value) }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label="Phone Number"
                    // error
                    // helperText
                    value={form.values.mobileNumber}
                    onChange={(e) => { form.setFieldValue('mobileNumber', e.target.value) }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    type="password"
                    InputLabelProps={{ shrink: true }}
                    label="Password"
                    error={form.errors.password}
                    helperText={form.errors.password}
                    value={form.values.password}
                    onChange={(e) => { form.setFieldValue('password', e.target.value) }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    type="password"
                    InputLabelProps={{ shrink: true }}
                    label="Repeat password"
                    error={form.errors.repeatPassword}
                    helperText={form.errors.repeatPassword}
                    value={form.values.repeatPassword}
                    onChange={(e) => { form.setFieldValue('repeatPassword', e.target.value) }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    label="Referral ID"
                    // error
                    // helperText
                    value={form.values.refId}
                    onChange={(e) => { form.setFieldValue('refId', e.target.value) }}
                  />
                </Grid>

                <Grid container direction="row" justifyContent="flex-end" sx={{ pt: 3 }}>
                  <Grid item xs={6}>
                    <Button fullWidth type="submit" variant="contained" >Next</Button>
                  </Grid>
                </Grid>

                <Grid item xs={12} style={{ paddingTop: '5%' }}>
                  <Link variant="body2" sx={{ cursor: 'pointer' }} onClick={() => history.push('/Login')}>
                    <Typo variant="subtitle2" type="title">
                      {t('Already have an account? Login Now')}
                    </Typo>
                  </Link>
                </Grid>

              </StyledGrid>
            </form>
          </div>
        }

        <StandardDialog
          paper
          title={'Agreement T&C'}
          content={
            <>
              <CardMedia
                component="iframe"
                height={newheight}
                src={PrivacyStatementHtm}
              />
              <FormGenerator fields={agreeCheckboxField.fields} onChange={setIsAgreed} />
            </>}
          open={openedAgreement}
          close={closeAgreement}
          actions={
            <DialogActions>
              <Button variant="contained" onClick={closeAgreement} disabled={loading}>Disagree</Button>
              {!isAgreed && <Button disabled={!isAgreed} variant="contained">Agree</Button>}
              {isAgreed && <Button onClick={handleAgreed_register} color="primary" variant="contained" disabled={loading}>Agree</Button>}
            </DialogActions>
          }
        />

        {
          activeStep === 1 &&
          <Grid container sx={{ pt: 3, px: 3 }} justifyContent="center">
            <Grid container style={{ justifyContent: 'center' }}>
              <Grid item xs={12} center>
                <Typo variant="h5" type="title">
                  Welcome to the family, {form.values.firstname} {form.values.lastname}
                </Typo>
              </Grid>
              <Grid item xs={12}>
                <Lottie className={classes.LottieImg}
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: PeopleAnim,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typo variant="subtitle1" type="title">
                  When you join Whisper Private Telegram Group, you are joining a community of amazingly awesome individuals from all over the world. Being a part of this community on Whisper Private Telegram Group means you are welcomed into its circle of trust and respect which are the foundational principles upon which this community exists.

                  If you come across any content that is inappropriate, please report it to be reviewed by our Whisper Private Telegram Group team. Our team will determine whether there has been a violation of our Community Guidelines and take appropriate decisive action.
                </Typo>
              </Grid>
              <Grid item xs={6}>
                <Button variant="outlined" color="primary" onClick={() => { }} alignItems='left' fullWidth>
                  Telegram
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={() => history.push('/Login')} fullWidth>
                  Complete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }

      </Grid>
    </StyledGrid>
  )
}