import { ThemeContext } from '../../context/theme-context';
import React, { useContext } from 'react';
import { ResponsiveContainer, AreaChart, Area, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from "recharts";

const LineGraph = (props) => {

    const themeContext = useContext(ThemeContext)
    const text = themeContext.theme.palette.text.primary
    const background = themeContext.theme.palette.background.default

    const CustomizedTick2 = ({x, y, payload}) => {
        return (
            <text style={{ fontSize: "12px", float: "right", textAlign: "right"}} fill={text} x={x - 24} y={y - 6} textAnchor="top" dominantBaseline="hanging">
                {payload.value}
            </text>
        )
    }
    
    const renderTooltip = (props) => {
        if (props && props.payload[0]) {
            return (
            <div style={{padding: "5px", background: background, color: text, fontSize: "12px"}}>
                <div>Price: {props.payload[0].payload.value}</div>
            </div>
            )
        }
    }
    
    const args = {
        chartData: props.timeframe,
        gradientColor: text,
        areaStrokeColor: "cyan",
        customizedTick: CustomizedTick2,
        renderTooltip: renderTooltip,
        uniqueId: 2,
    }

    return (
        <ResponsiveContainer width={props.width?parseInt(props.width):"100%"} height={props.height?parseInt(props.height):300}>
        <AreaChart data={args.chartData}
            margin={{ top: 20, right: 10, left: -30, bottom: 0 }}>
            <defs>
            <linearGradient id={"colorUv" + args.uniqueId} x1="0" y1="0" x2="0" y2="1">
                <stop offset="100%" stopColor={args.gradientColor} />
            </linearGradient>
            </defs>
            <XAxis dataKey="day" stroke={text}/>
            <YAxis 
                width={80}
                tick={args.customizedTick}
                interval={0}
                domain={[1, 15]}
                tickFormatter={args.tickFormatter}
                stroke={text}
            />
            <Tooltip content={args.renderTooltip} />
            <Area dot={{ fill: args.gradientColor, fillOpacity: 1}} type="monotone" dataKey="value" stroke={args.gradientColor} fillOpacity={0.1} fill={"url(#colorUv" + args.uniqueId + ")"} />
        </AreaChart>
        </ResponsiveContainer>
    )
}

export default LineGraph;
