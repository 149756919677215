import { red, green, grey } from '@mui/material/colors';

const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export default function numericColorFormatter(props) {
    
    const color= parseFloat(props) && parseFloat(props) < 0 ? red[500] : parseFloat(props) && parseFloat(props) > 0 ? green[500]  : grey[500] 

    return `${color}`;
}

export function numericColorBackgroundFormatter(props) {
    
    const color= parseFloat(props) && parseFloat(props) < 0 ? red[50] : parseFloat(props) && parseFloat(props) > 0 ? green[50]  : grey[50] 

    return `${color}`;
}

export function numericPlusFormatter(props) {
    
    const isPositiveNumeric = isNumeric(props) && props > 0 ? '+' : '-' 

    return `${isPositiveNumeric}`;
}