import { useQuery } from '@tanstack/react-query'

import api from '../services/api'

export function useAdmin_listCustomer(offset = 0) {
    const query = useQuery(['admin-customers', offset], async () => {
        const { response } = await api.executeAdminListCustomers(offset)
        const customers = JSON.parse(response)

        return customers
    })

    return query
}

export function useUserCustomers(userId) {
    const query = useQuery(['user-customer', userId], async () => {
        // customer's refId = userId
        const { documents: customers } = await api.listUserCustomers(userId)

        return customers
    }, {
        enabled: !!userId,
        refetchOnWindowFocus: false,
        onError: error => alert(error.message)
    })

    return query
}
