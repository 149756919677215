import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: null,
    teams: null,
    memberships: null,
    roles: null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, { payload }) => { state.user = payload },
        deleteUser: (state) => { state.user = null },
        setTeams: (state, { payload }) => { state.teams = payload },
        setMemberships: (state, { payload }) => { state.memberships = payload },
        setRoles: (state, { payload }) => { state.roles = payload },
    },
})

export const { setUser, deleteUser, setTeams, setMemberships, setRoles } = authSlice.actions

export default authSlice.reducer

