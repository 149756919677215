import { useEffect, useRef, useContext } from "react";
import { useSelector } from 'react-redux'
import { ThemeContext } from '../../context/theme-context';

const ExchangeBar = () => {
  const _ref = useRef()
  const { exchangeCountries } = useSelector(state => state.ui.home)
  
  const themeContext = useContext(ThemeContext)
  const mode = themeContext.isDarkMode !== true ? 'light': 'dark'
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js'
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: exchangeCountries.filter((country) => country.selected).exchanges,
      colorTheme: mode,
      isTransparent: true,
      showSymbolLogo: true,
      displayMode: "regular",
      locale: "en"
    });
    _ref.current.appendChild(script);
  }, [])

  return (
    <div className="tradingview-widget-container" ref={_ref} style={{ paddingTop: '5px' }}>
      <div className="tradingview-widget-container__widget"></div>
    </div>
  );
}

export default ExchangeBar