import { forwardRef } from 'react'
import { styled } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux'

// components
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

// icons
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import HighlightIcon from '@mui/icons-material/Highlight';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Slide from '@mui/material/Slide';

const PREFIX = 'BasicBottomNavigation';

const classes = {
  bottomNavigation: `${PREFIX}-bottomNavigation`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.bottomNavigation}`]: {
    position: 'fixed',
    width: '100%',
    marginTop: 'auto',
    bottom: 0,
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  }
}));


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BasicBottomNavigation = (props) => {

  const { isAuthenticated } = useSelector(state => state.user)

  return (
    (<Root>
      {
        isAuthenticated === true
          ? (<div className={classes.bottomNavigation}>
            <BottomNavigation
              value={props.value}
              onChange={(event, newValue) => props.onChange(newValue)}
              showLabels={props.showLabels}
            >
              <BottomNavigationAction
                label="Home"
                icon={<HomeIcon />}
                onClick={() => props.history.push('/')}
              />
              <BottomNavigationAction
                label="Events"
                icon={<EventIcon />}
                onClick={() => props.history.push('/UpcomingEvents')}
              />
              <BottomNavigationAction
                label="Highlights"
                icon={<HighlightIcon />}
                onClick={() => props.history.push('/Highlights')}
              />
              <BottomNavigationAction
                label="Favourites"
                icon={<FavoriteIcon />}
                onClick={() => props.history.push('/Favourites')}
              />
            </BottomNavigation>
          </div>) : null
      }
    </Root>)
  );
}

export default withRouter(BasicBottomNavigation);