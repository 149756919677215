import { useState } from 'react'
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { Container, Button } from '@mui/material'
import StandardDialog from '../components/Dialog/StandardDialog'
import Typo from '../components/Text/Typo'

const PREFIX = 'License';

const classes = {
    root: `${PREFIX}-root`,
    headingText: `${PREFIX}-headingText`,
    button: `${PREFIX}-button`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        paddingTop: '32px !important',
        paddingBottom: '80px !important'
    },

    [`& .${classes.headingText}`]: {
        marginTop: 0,
        marginBottom: '1px',
        fontWeight: 600,
        fontSize: '2rem'
    },

    [`& .${classes.button}`]: {
        marginTop: theme.spacing(2),
        justifyContent: 'flex-start',
        fontSize: '1rem',
        fontWeight: 600
    }
}));

const License = () => {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <StyledContainer className={classes.root}>
            <Typo variant="h5" type="title" fontWeight="bold">{t('Licenses')}</Typo>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                target="_blank" href="https://material-ui.com/store/terms/"
            >
                Material UI
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                target="_blank" href="https://www.binance.com/en/binance-api"
            >
                Binance API
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                target="_blank" href="https://lottiefiles.com/page/terms-and-conditions"
            >
                Lottie Files
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                target="_blank" href="https://www.tradingview.com/policies/"
            >
                TradingView
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                target="_blank" href="https://www.cryptocompare.com/terms-conditions/"
            >
                Crypto Compare
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                onClick={handleClickOpen}
            >
                Flaticon
            </Button>
            <StandardDialog
                paper
                title={'Freepikcompany Credits'}
                button={true}
                open={open}
                close={handleClose}
                confirm={handleClose}
                content={
                    <>
                        <Typo variant="body2" type="title">
                            On display<br />
                            <a href="https://www.flaticon.com/free-icons/whale" title="whale icons">Whale icons created by photo3idea_studio - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bitcoin" title="bitcoin icons">Bitcoin icons created by itim2101 - Flaticon</a><br />

                            Additional<br />
                            <a href="https://www.flaticon.com/free-icons/abkhazia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/afghanistan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/aland-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/albania" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/algeria" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/american-samoa" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/andorra" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/angola" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/anguilla" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/antigua-and-barbuda" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/argentina" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/armenia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/aruba" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/australia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/austria" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/azerbaijan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/azores-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bahamas" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bahrain" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/balearic-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bangladesh" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/barbados" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/basque-country" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/belarus" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/belgium" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/belize" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/benin" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bermuda" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bhutan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bhutan-1" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bolivia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bonaire" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bosnia-and-herzegovina" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/botswana" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/brazil" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/british-columbia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/british-indian-ocean-territory" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/british-virgin-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/brunei" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/bulgaria" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/burkina-faso" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/burundi" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cambodia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cameroon" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/canada" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/canary-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cape-verde" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cayman-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/central-african-republic" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ceuta" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/chad" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/chile" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/china" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/christmas-island" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cocos-island" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/colombia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/comoros" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cook-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/corsica" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/costa-rica" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/croatia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cryptocurrency" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cuba" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/curacao" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/cyprus" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/czech-republic" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/democratic-republic-of-congo" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/denmark" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/djibouti" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/dominica" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/dominican-republic" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/east-timor" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ecuador" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/egypt" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/england" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/equatorial-guinea" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/eritrea" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/estonia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ethiopia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/european-union" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/falkland-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/faroe-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/fiji" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/finland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/france" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/french-polynesia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/gabon" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/galapagos-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/gambia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/georgia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/germany" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ghana" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/gibraltar" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/greece" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/greenland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/grenada" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/guam" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/guatemala" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/guernsey" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/guinea" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/guinea-bissau" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/guyana" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/haiti" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/hawaii" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/honduras" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/hong-kong" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/hungary" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/iceland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/india" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/indonesia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/iran" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/iraq" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ireland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/isle-of-man" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/israel" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/italy" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ivory-coast" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/jamaica" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/japan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/jersey" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/jordan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/kazakhstan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/kenya" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/kiribati" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/kosovo" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/kuwait" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/kyrgyzstan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/laos" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/latvia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/lebanon" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/lesotho" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/liberia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/libya" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/liechtenstein" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/lithuania" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/luxembourg" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/macao" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/madagascar" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/madeira" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/malawi" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/malaysia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/maldives" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/mali" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/malta" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/marshall-island" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/martinique" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/mauritania" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/mauritius" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/melilla" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/mexico" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/micronesia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/moldova" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/monaco" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/mongolia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/montenegro" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/montserrat" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/morocco" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/mozambique" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/myanmar" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/namibia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/nato" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/nauru" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/nepal" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/netherlands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/new-zealand" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/nicaragua" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/niger" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/nigeria" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/niue" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/norfolk-island" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/northen-cyprus" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/northern-marianas-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/north-korea" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/norway" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/oman" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/orkney-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ossetia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/pakistan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/palau" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/palestine" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/panama" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/papua-new-guinea" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/paraguay" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/peru" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/philippines" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/pitcairn-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/portugal" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/puerto-rico" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/qatar" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/rapa-nui" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/republic-of-macedonia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/republic-of-poland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/republic-of-the-congo" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/romania" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/russia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/rwanda" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/saba-island" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/saint-kitts-and-nevis" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/salvador" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/samoa" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/san-marino" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sao-tome-and-principe" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sardinia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/saudi-arabia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/scotland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/senegal" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/serbia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/seychelles" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sierra-leone" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/singapore" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sint-eustatius" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sint-maarten" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/slovakia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/slovenia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/solomon-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/somalia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/somaliland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/south-africa" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/south-korea" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/south-sudan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/spain" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sri-lanka" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/st-barts" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/st-lucia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/st-vincent-and-the-grenadines" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sudan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/suriname" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/swaziland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/sweden" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/switzerland" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/syria" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/taiwan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/tajikistan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/tanzania" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/thailand" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/tibet" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/togo" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/tokelau" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/tonga" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/transnistria" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/trinidad-and-tobago" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/tunisia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/turkey" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/turkmenistan" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/turks-and-caicos" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/tuvalu" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/uganda" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/ukraine" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/united-arab-emirates" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/united-kingdom" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/united-nations" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/united-states-of-america" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/uruguay" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/uzbekistn" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/vanuatu" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/vatican-city" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/venezuela" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/vietnam" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/virgin-islands" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/wales" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/western-sahara" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/yemen" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/zambia" title=" icons"> icons created by Freepik - Flaticon</a><br />
                            <a href="https://www.flaticon.com/free-icons/zimbabwe" title=" icons"> icons created by Freepik - Flaticon</a><br />
                        </Typo>
                    </>
                }
            />
        </StyledContainer>
    );
}

export default License;