import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles';

import { Container } from '@mui/material'
import Typo from '../components/Text/Typo'

const PREFIX = 'About';

const classes = {
    root: `${PREFIX}-root`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        paddingTop: '32px !important',
        paddingBottom: '80px !important'
    }
}));

const About = () => {

    const { t } = useTranslation()

    const about = [...new Array(25)].map(() => `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi urna sem, finibus eget maximus id, varius quis sapien. Sed vel massa urna. Maecenas leo nunc, tristique nec ex viverra, maximus imperdiet risus. In diam ligula, cursus ac interdum vel, efficitur ac massa. Vestibulum rutrum vehicula turpis hendrerit sodales. Praesent ut nisl vel neque lobortis tincidunt. Vivamus vestibulum arcu at enim interdum vestibulum. `).join('\n')

    return (
        <StyledContainer className={classes.root}>
            <Typo variant="h6" type="title" fontWeight="bold">
                {t('About')}
            </Typo>
            <Typo variant="body1" type="title">
                {about}
            </Typo>

        </StyledContainer>
    );
}

export default About;