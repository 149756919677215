import {
    Grid,
    Card, CardContent, CardActions,
    Divider,
    Avatar
} from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached';

import FormGenerator from '../../helper/form-generator'
import Typo from '../Text/Typo';
import LineGraph from '../Graph/LineGraph';
import BasicButton from '../Button/BasicButton';
import Favourite from '../../icon/favourite';


export default function BasicCard(props) {

    return (
        <Grid container style={{ display: 'flex' }} alignItems="stretch">
            {props.content.map(content => {
                return (
                    <Grid key={content.title} item lg={content.lg !== null ? content.lg : 4} md={content.md !== null ? content.md : 4} sm={content.sm !== null ? content.sm : 6} xs={content.xs !== null ? content.xs : 12} style={{ padding: content.padding !== null ? content.padding : '0.5%' }}>
                        <Card variant='outlined' sx={{borderColor:'text.primary'}}>
                            <CardContent>
                                {content.ticker !== null ?
                                    <Typo type="title" fontWeight="600">{content.ticker}</Typo> : null
                                }
                                {content.type && <BC_Header content={content} />}
                                {content.activities && <Activities_Header content={content} />}
                                {content.price !== undefined || content.changePercentage !== undefined ?
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                    }}>
                                        <BasicButton 
                                            type="numericbutton"
                                            edge="start"
                                            buttonvariant = "contained"
                                            variant="caption"
                                            content={Number(content.price)}
                                        />
                                        <div style={{paddingLeft:'5%'}}>
                                            <BasicButton 
                                                type="numericbutton"
                                                edge="start"
                                                variant="caption"
                                                content={content.changePercentage}
                                                percentage={true}
                                            />
                                        </div>
                                    </div>
                                    : null
                                }
                                {
                                    content.timeframe ?
                                    <LineGraph timeframe={content.timeframe} width={'300'} height={'180'} />
                                    : null
                                }
                                {content.type && <BC_Content content={content} />}
                                {/* {content.items && (content.items)} */}
                            </CardContent>
                            {content.trxdate !== undefined ?
                                <>
                                    <Divider sx={{borderColor:'text.primary'}}/>
                                    <CardActions>
                                        <Typo variant="h6" type="title" fontSize='12px'>
                                            <CachedIcon fontSize="small" style={{ position: 'relative', top: '4px', fontSize: '16px' }} /> Last updated on {content.trxdate} HRS
                                        </Typo>
                                    </CardActions>
                                </> : null
                            }
                            {content.type && <BC_Footer content={content} onChangePlanDuration={props.onChangePlanDuration} />}
                        </Card>
                    </Grid>
                );
            }
            )}
        </Grid>
    );
}

const BC_Header = ({ content }) => {
    const { type, title, name } = content

    if (type === 'signUpPlan') {
        return (
            <>
                {title && <Typo variant="h6" type="title">{title}</Typo>}

                {name &&
                    <Typo variant="subtitle1" type="title">
                        {name}
                    </Typo>
                }
            </>
        )
    }
}

const Activities_Header = ({ content }) => {
    const { title, name } = content

        return (
            <>
                {title && <Typo variant="h6" type="title">{title}</Typo>}

                {name &&
                    <Typo variant="subtitle1" type="title">
                        {name}
                    </Typo>
                }
            </>
        )
}

const BC_Content = ({ content }) => {
    const { type, items } = content

    if (type === 'signUpPlan') {
        return (
            <>
                {items.map(item =>
                    <Typo variant="h6" type="title" fontSize='12px'>{item}</Typo>
                )}
            </>
        )
    }
}

const BC_Footer = ({ content, onChangePlanDuration }) => {
    const { type, actions } = content

    if (type === 'signUpPlan') {
        return (
            <>
                <Divider />
                <CardActions>
                    {/* <FormGenerator fields={actions.fields} onChange={onChangePlanDuration} /> */}
                    {/* <Avatar src={actions.fields[0].src} /> */}
                    {actions.fields.map((item) =>
                        <BasicButton 
                            type="avatarbutton"
                            edge="start"
                            src={item.src}
                        />
                    )}
                </CardActions>
            </>
        )
    }
}