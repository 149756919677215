import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import { FacebookIcon, WhatsappIcon, TwitterIcon, EmailIcon } from 'react-share';

import { Tooltip, Button, Dialog, DialogContent } from '@mui/material'
import { Divider, OutlinedInput, IconButton } from '@mui/material'
import MuiDialogTitle from '@mui/material/DialogTitle'

// Icon
import CloseIcon from '@mui/icons-material/Close'
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';

import StandardDialog from '../components/Dialog/StandardDialog';
import Typo from './Text/Typo';

const PREFIX = 'Share';

const classes = {
    dialogTitleRoot: `${PREFIX}-dialogTitleRoot`,
    closeButton: `${PREFIX}-closeButton`,
    copyButton: `${PREFIX}-copyButton`,
    customTooltip: `${PREFIX}-customTooltip`,
    customArrow: `${PREFIX}-customArrow`,
    divider: `${PREFIX}-divider`,
    socialContainer: `${PREFIX}-socialContainer`,
    socialButton: `${PREFIX}-socialButton`,
    hoverTooltip: `${PREFIX}-hoverTooltip`,
    downloadButtonContainer: `${PREFIX}-downloadButtonContainer`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.dialogTitleRoot}`]: {
        margin: 0,
        padding: theme.spacing(2)
    },

    [`& .${classes.closeButton}`]: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },

    [`& .${classes.copyButton}`]: {
        height: '54px',
        fontWeight: 'bold',
        borderColor: '#4491d0',
        borderRadius: '15px',
        marginLeft: '0.5rem',
        color: '#4491d0',
    },

    [`& .${classes.customTooltip}`]: {
        fontSize: '1rem',
        backgroundColor: '#4491d0',
    },

    [`& .${classes.customArrow}`]: {
        color: '#4491d0'
    },

    [`& .${classes.divider}`]: {
        margin: theme.spacing(2)
    },

    [`& .${classes.socialContainer}`]: {
        display: 'flex',
        justifyContent: 'center'
    },

    [`& .${classes.socialButton}`]: {
        cursor: 'pointer',
        marginRight: '1rem'
    },

    [`& .${classes.hoverTooltip}`]: {
        fontSize: '1rem',
        backgroundColor: '#4491d0'
    },

    [`& .${classes.downloadButtonContainer}`]: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0),
    }
}));

const DialogTitle = (props) => {

    const { children, onClose, ...other } = props
    return (
        <MuiDialogTitle className={classes.dialogTitleRoot} {...other}>
            <Typo variant="h6" type="title">
                {children}
            </Typo>
            {onClose ? (
                <IconButton className={classes.closeButton} onClick={onClose} size="large">
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
}

// Web Share
// Problem: Parameter 'href' should represent a valid URL (Facebook & Linkin)
// Ref: https://github.com/nygardk/react-share/issues/233
// Cause: only occurs in dev locahost environment
// Solve: works fine in any deveploment except localhost (untested)
const Share = (props) => {

    const { open, onClose, title, url, imageUrl } = props
    const [tipOpen, setTipOpen] = useState(false)

    const hCopyButton_onClick = () => {
        navigator.clipboard.writeText(url)

        setTipOpen(true)

        setTimeout(() => {
            setTipOpen(false)
        }, 3000)
    }

    const hDownloadButton_onClick = () => {
        window.open(imageUrl, '_blank')
    }

    return (
        <StandardDialog
            title={'Share'}
            button={false}
            open={open}
            close={onClose}
            iconClose={onClose}
            content={
                <Root>
                    <div className={classes.socialContainer}>
                        <Tooltip
                            classes={{ tooltip: classes.customTooltip, arrow: classes.customArrow }}
                            open={tipOpen}
                            title={<span >Copied!</span>}
                            placement="top"
                            arrow
                            onClose={() => setTipOpen(false)}
                        >
                            <Tooltip
                                // Hover
                                classes={{ tooltip: classes.hoverTooltip }}
                                disableFocusListener
                                disableTouchListener
                                title={url}
                            >
                                <OutlinedInput value={url} readOnly />
                            </Tooltip>
                        </Tooltip>

                        <Button
                            className={classes.copyButton}
                            variant="outlined"
                            onClick={hCopyButton_onClick}
                        >
                            Copy
                    </Button>
                    </div>
                    {imageUrl &&
                        <div className={classes.downloadButtonContainer}>
                            <Button
                                color="textSecondary"
                                startIcon={<GetAppRoundedIcon />}
                                onClick={hDownloadButton_onClick}
                            >
                                Save Image
                        </Button>
                        </div>
                    }
                    <Divider className={classes.divider} />
                    <div className={classes.socialContainer}>
                        <EmailShareButton
                            url={url}
                            subject={'Check this out!'}
                            body={url}
                            className={classes.socialButton}
                        >
                            <EmailIcon size={64} round />
                        </EmailShareButton>

                        <FacebookShareButton
                            url={url}
                            quote='Check this out!'
                            className={classes.socialButton}
                        >
                            <FacebookIcon size={64} round />
                        </FacebookShareButton>

                        <TwitterShareButton
                            url={url}
                            title="Check this out!"
                            className={classes.socialButton}
                        >
                            <TwitterIcon size={64} round />
                        </TwitterShareButton>

                        <WhatsappShareButton
                            url={url}
                            title="Check this out!"
                            separator="::"
                            className={classes.socialButton}
                        >
                            <WhatsappIcon size={64} round />
                        </WhatsappShareButton>
                    </div>
                    <Typo variant="body2" type="title" fontSize='12px'>
                        Remark: Please disable your adblock to prevent issue.
                    </Typo>
                </Root>
            }
        />
    );
}
Share.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
}

// Phone Share
// Prob: Huawei native browser doesn't work (using webView)
// Ref: compatibility
// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
export const PhoneShare = (title, text, url) => {
    if (navigator.share) {
        navigator.share({
            title: title || '',
            text: text || '',
            url: url || ''
        })
            .then(() => { })
            .catch((error) => {
                console.log('PhoneShare catch: ', error)
            })
    }
}

export function isPhone() {
    if (navigator.share) { return true }
    return false
}

export function getCurrentURL() {
    return window.location.href
}

export default Share