import { createSlice, current } from '@reduxjs/toolkit'
import produce from 'immer'
import { isValidEmail } from '../helper/validator'

const initialState = {
    form: {
        fields: [
            { name: 'firstName', value: null },
            { name: 'lastName', value: null },
            { name: 'email', value: null },
            { name: 'password', value: null },
            { name: 'phone', value: null },
            { name: 'country', value: null },
            { name: 'country_state', value: null },
            { name: 'postalcode', value: null },
            { name: 'address1', value: null },
            { name: 'address2', value: null },
        ],
        isValid: false,
        currency: null,
        taxRate: null,
    },
    plan: {
        minExchangeLimit: null,
        selectedPlanIndex: 0,
        selectedPlanName: null,
        selectedPlanDuration: null,
        selectedPlanPrice: null,
        planCurrency: null,
        isValid: false,
        api: {
            availablePlan: []
        },
        plansSchema: {
            key: ['exchange', 'ticker'],
            feeds: [
                {
                    type: 'signUpPlan',
                    title: 'Premium-placeholder',
                    name: '',
                    items: ['loading...'],
                    actions:
                    {
                        fields: [
                            {
                                label: '',
                                type: '',
                                color: '',
                                name: ''
                            }
                        ],
                    },
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 12,
                },
                {
                    type: 'signUpPlan',
                    title: 'Pro-placeholder',
                    name: '',
                    items: ['loading...'],
                    actions:
                    {
                        fields: [
                            {
                                label: '',
                                type: '',
                                color: '',
                                name: ''
                            }
                        ],
                    },
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 12
                },
                {
                    type: 'signUpPlan',
                    title: 'Trial-placeholder',
                    name: '',
                    items: ['loading...'],
                    actions:
                    {
                        fields: [
                            {
                                label: '',
                                type: '',
                                color: '',
                                name: ''
                            }
                        ],
                    },
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 12
                },
            ]
        },
    },
    exchange: {
        isValid: false,
        selectedExchanges: [],
        api: { // FIXME: dummy atm
            availableExchange: [
                {
                    "ExchangeCode": "DJI",
                    "ExchangeName": "Dow Jones Industrial Average",
                    "Country": "US",
                    "Price": "100"
                },
                {
                    "ExchangeCode": "NASDAQ",
                    "ExchangeName": "Nasdaq Composite",
                    "Country": "US",
                    "Price": "200"
                },
                {
                    "ExchangeCode": "NYSE",
                    "ExchangeName": "New York Stock Exchange",
                    "Country": "US",
                    "Price": "300"
                },
                {
                    "ExchangeCode": "SP500",
                    "ExchangeName": "S&P 500",
                    "Country": "US",
                    "Price": "400"
                },
                {
                    "ExchangeCode": "SGX",
                    "ExchangeName": "Singapore Exchange Limited",
                    "Country": "US",
                    "Price": "500"
                }
            ]
        }
    },
    postBody: {
        FirstName: '',
        LastName: '',
        EmailAddress: '',
        Password: '',
        Address1: '',
        Address2: '',
        PostalCode: '',
        State: '',
        CountryCode: '',
        ContactNumber: '',
        Plan: '',
        Duration: '',
        ApplyDate: '',
        ApplicationExchanges: [
            // {
            //     ExchangeCode: ''
            // }
        ],
        AddonExchanges: [
            // {
            //     ExchangeCode: ''
            // }
        ]
    },
}

function toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            var norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        setFormValue: (state, { payload }) => {
            // console.log(payload, 'setFormValue payload')
            // payload: {name, value}

            state.form.fields.map(field => {
                if (field.name === payload.name) field.value = payload.value
                return field
            })
        },
        setCurrencyTaxRate: (state, { payload }) => {
            // payload: {Currency, TaxRate}
            state.form.currency = payload.currency
            state.form.taxRate = payload.taxRate
        },
        doFormValidation: (state) => {
            const fields = state.form.fields
            for (let i = 0; i < fields.length; i++) {
                // check - all fields !null
                if (!fields[i].value) {
                    state.form.isValid = false
                    break
                }
                // check - email format
                if (fields[i].name === 'email') {
                    if (!isValidEmail(fields[i].value)) {
                        state.form.isValid = false
                        break
                    }
                }
                // check - postalCode numeric only
                if (fields[i].name === 'postalcode') {
                    if (!Number.isInteger(parseInt(fields[i].value))) {
                        state.form.isValid = false
                        break
                    }

                    const countryValue = state.form.fields.find(field => field.name === 'country').value
                    if (countryValue && countryValue === 'SG') {
                        if (fields[i].value.length !== 6) {
                            state.form.isValid = false
                            break
                        }
                    }
                }
                state.form.isValid = true
            }
        },

        setPlanIndex: (state, { payload }) => {
            // payload: 0
            state.plan.selectedPlanIndex = payload
        },
        setPlanAPI_AvailablePlan: (state, { payload }) => {
            // payload is api data
            state.plan.api.availablePlan = payload
        },
        setMinExchangeLimit: (state, { payload }) => {
            // payload: 3
            state.plan.minExchangeLimit = payload
        },
        setPlansSchema: (state, { payload }) => {
            // payload: {index: {index}, plan: {ApiData}}

            const { plansSchema } = state.plan
            plansSchema.feeds[payload.index].title = payload.plan.PlanName
            plansSchema.feeds[payload.index].items = payload.plan.SubscriptionFeatures
            const actionsSchemaFields = (PlanName) => payload.plan.SubscriptionPlanPrices.map(plan => {
                return {
                    label: `${plan.Duration} Months`,
                    type: 'chip',
                    color: 'default',
                    name: `${PlanName}-${plan.Duration}`,
                }
            })
            plansSchema.feeds[payload.index].actions.fields = actionsSchemaFields(payload.plan.PlanName)
        },
        setSelectedPlanDuration: (state, { payload }) => {
            // payload: '{plan duration chip name}'

            // reset all selected exchanges
            state.exchange.isValid = false
            state.exchange.selectedExchanges = []

            // reset all chips color to 'default'
            state.plan.plansSchema.feeds.map(feed => {
                feed.actions.fields.map(field => field.color = 'default')
            })

            // set selected chip color to 'primary'
            state.plan.plansSchema.feeds.map(feed => {
                feed.actions.fields.map(field => {
                    if (field.name === payload) field.color = 'primary'
                })
            })

            // set selected plan - name & duration
            const planName = payload.split('-')[0]
            const planDuration = payload.split('-')[1]
            state.plan.selectedPlanName = planName
            state.plan.selectedPlanDuration = planDuration

            // set selected chip price & currency
            const selectedPlanIndex = state.plan.selectedPlanIndex
            state.plan.api.availablePlan[selectedPlanIndex].SubscriptionPlanPrices
                .map(spp => {
                    if (spp.Duration.toString() === planDuration) {
                        state.plan.selectedPlanPrice = spp.Price
                        state.plan.planCurrency = spp.CountryCode
                    }
                })
        },
        resetPlansDuration: (state) => {
            // reset all plan duration chip color to default
            state.plan.plansSchema.feeds.map(feed => {
                feed.actions.fields.map(field => field.color = 'default')
            })
            state.plan.selectedPlanDuration = null
            state.plan.selectedPlanPrice = null
        },
        doPlanValidation: (state) => {
            const { selectedPlanDuration } = state.plan

            if (selectedPlanDuration) {
                state.plan.isValid = true
            } else {
                state.plan.isValid = false
            }
        },

        setSelectedExchanges: (state, { payload }) => {
            // payload: 'NYSE'
            const { selectedExchanges } = state.exchange
            const index = selectedExchanges.indexOf(payload)

            if (index < 0) {
                selectedExchanges.push(payload)
            } else {
                selectedExchanges.splice(index, 1)
            }
        },
        resetSelectedExchanges: (state) => {
            state.exchange.isValid = false
            state.exchange.selectedExchanges = []
        },
        doExchangeValidation: (state, { payload }) => {
            // payload: 3
            const { selectedExchanges } = state.exchange
            if (selectedExchanges.length < payload) {
                state.exchange.isValid = false
            } else {
                state.exchange.isValid = true
            }
        },

        // Post Body
        setPostBody: (state) => {
            const defaultPostBody = {
                FirstName: '',
                LastName: '',
                EmailAddress: '',
                Password: '',
                Address1: '',
                Address2: '',
                PostalCode: '',
                State: '',
                CountryCode: '',
                ContactNumber: '',
                Plan: '',
                Duration: '',
                ApplyDate: '',
                ApplicationExchanges: [
                    // {
                    //     ExchangeCode: ''
                    // }
                ],
                AddonExchanges: [
                    // {
                    //     ExchangeCode: ''
                    // }
                ]
            }
            state.postBody = defaultPostBody

            const pBody = state.postBody

            state.form.fields.map(field => {
                switch (field.name) {
                    case 'firstName':
                        return pBody.FirstName = field.value
                    case 'lastName':
                        return pBody.LastName = field.value
                    case 'email':
                        return pBody.EmailAddress = field.value
                    case 'password':
                        return pBody.Password = field.value
                    case 'phone':
                        return pBody.ContactNumber = field.value
                    case 'country':
                        return pBody.CountryCode = field.value
                    case 'country_state':
                        return pBody.State = field.value
                    case 'postalcode':
                        return pBody.PostalCode = field.value
                    case 'address1':
                        return pBody.Address1 = field.value
                    case 'address2':
                        return pBody.Address2 = field.value
                    default:
                        return
                }
            })

            pBody.Plan = state.plan.selectedPlanName
            pBody.Duration = state.plan.selectedPlanDuration
            pBody.ApplyDate = toIsoString(new Date())

            state.exchange.selectedExchanges.map((exchange, index) => {
                if (index < state.plan.minExchangeLimit) {
                    pBody.ApplicationExchanges.push({
                        ExchangeCode: exchange
                    })
                } else {
                    pBody.AddonExchanges.push({
                        ExchangeCode: exchange
                    })
                }
            })
        }
    },
})

export const {
    // Form
    setFormValue,
    setCurrencyTaxRate,
    doFormValidation,
    // Plan
    setPlanIndex,
    setPlanAPI_AvailablePlan,
    setMinExchangeLimit,
    setPlansSchema,
    setSelectedPlanDuration,
    resetPlansDuration,
    doPlanValidation,
    // Exchange
    setSelectedExchanges,
    resetSelectedExchanges,
    doExchangeValidation,
    // Post Body
    setPostBody,
} = signupSlice.actions

export const selectIsFormValid = state => state.signup.form.isValid

export const selectPlanName = state => state.signup.plan.selectedPlanName
export const selectMinExchangeLimit = state => state.signup.plan.minExchangeLimit
export const selectSelectedPlanPrice = state => state.signup.plan.selectedPlanPrice
export const selectPlanTaxRate = state => state.signup.form.taxRate
export const selectIsPlanValid = state => state.signup.plan.isValid
export const selectPlansSchema = state => state.signup.plan.plansSchema
export const selectPlanAPI_availablePlan = state => state.signup.plan.api.availablePlan

export const selectSelectedExchanges = state => state.signup.exchange.selectedExchanges
export const selectExchangeAPI_AvailableExchange = state => state.signup.exchange.api.availableExchange
export const selectIsExchangeValid = state => state.signup.exchange.isValid

export const selectSignUpPostBody = state => state.signup.postBody


export default signupSlice.reducer