import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

// components
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import CachedIcon from '@mui/icons-material/Cached';
import Content from '../../hoc/Content';
import SearchBar from '../SearchBar/SearchBar';
import StandardDialog from '../../components/Dialog/StandardDialog';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
// formatter
import DateFormatter from '../../formatter/date-formatter';
import DialogActions from '@mui/material/DialogActions'
// icon
import FilterListIcon from '@mui/icons-material/FilterList';
import AppsIcon from '@mui/icons-material/Apps';
import CloseIcon from '@mui/icons-material/Close';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import BasicCard from '../Card/BasicCard';
import Typo from '../Text/Typo';
import BasicButton from '../Button/BasicButton';
import useWindowDimensions from '../../services/useWindowDimensions';
import { Grid, Stack } from '@mui/material';
import FormGenerator from '../../helper/form-generator';

export default function BasicTable(props) {

    const { t } = useTranslation()

    // states
    const [feeds, setFeeds] = useState([...props.feeds]);
    const [paginatedFeeds, setPaginatedFeeds] = useState(feeds);
    // const [footer,setFooter] = useState([...props.footer]);
    const [filter, setFilter] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState(
        props.sortable
            ? props.defaultOrderBy
            : null
    );
    const [page, setPage] = useState(0);

    // edit mode toggle
    const [onEdit, setOnEdit] = useState(false);

    // filter
    // filter toggle
    const [showFieldFilter, setShowFieldFilter] = useState(false);
    const [showColumn, setColumn] = useState(false);

    const [fieldFilter, setFieldFilter] =
        useState(
            props.header
                .map(headerField => {
                    return {
                        name: headerField.field,
                        value: '',
                    }
                })
        );

    const [curAppliedFilter, setCurAppliedFilter] = useState(0);

    // handle current applied filter change
    const handleCurAppliedFilterChanged = event => {
        const { value } = event.target;

        setCurAppliedFilter(value);

        setFieldFilter([...props.filterOptions.filterLists[value].value]);
    }

    // add modal
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);

    // -- modal --------------------
    const addModal = props.editOptions ? { ...props.editOptions.add.modal } : null;
    const editModal = props.editOptions ? { ...props.editOptions.edit.modal } : null;

    // -- sorting -----------------------------------
    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy])
            return -1;

        if (b[orderBy] > a[orderBy])
            return 1;

        return 0;
    }

    const getComparator = (order, orderBy) =>
        order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy)


    // handle sort field & sort order control
    const handleRequestSort = (orderField) => {
        const isAsc = orderBy === orderField && order === 'asc';

        setOrder(
            isAsc
                ? 'desc'
                : 'asc'
        );

        setOrderBy(orderField);
    }

    // sort data
    const stableSort = (array, comparator) => {
        const stabilizedTarget = array.map((el, index) => [el, index]);

        stabilizedTarget.sort((a, b) => {
            const ordering = comparator(a[0], b[0]);

            if (ordering !== 0) return ordering;

            return a[1] - b[1];
        });

        return stabilizedTarget.map((el) => el[0]);
    }

    // -- filter ---------------------------

    // compare 2 values, return true if compared value is partially matched with base value
    const compare = (baseValue, comparedValue) => {
        // convert value to lower case string
        baseValue = baseValue.toString().toLowerCase();
        comparedValue = comparedValue.toString().toLowerCase();

        return baseValue.indexOf(comparedValue) !== -1;
    }

    // match by any field(s) of data row
    // function under searchable option
    const match = dataRow => {
        for (const dataField in dataRow) {
            if (compare(dataRow[dataField], filter))
                return true;
        }

        return false;
    }

    // match by field
    // function under filterable option
    const matchField = dataRow => {
        const activeFieldFilters = fieldFilter.filter(field => field.value !== '');

        // skip applying filter if none is provided
        if (activeFieldFilters.length === 0)
            return true;

        // assume record is match by default
        let match = true;

        activeFieldFilters
            .map(filterField => {
                // handle field that bind to multiple feeds field(s)
                const name = filterField.name;
                const fields = typeof (name) === 'string' ? name.split(',') : name

                // find match record for each field(s)
                let hasMatched;

                for (let index = 0; index < fields.length; index++) {
                    const field = fields[index];

                    // loop thru data row field(s) and compare matched field 
                    for (const dataField in dataRow) {
                        if (dataField === field) {
                            hasMatched = false;

                            if (compare(dataRow[dataField], filterField.value)) {
                                hasMatched = true;
                                break;
                            }
                        }
                    }

                    // single display field with multiple data row fields is consider bundle
                    // if any one of the fields has matched result, return true
                    if (hasMatched)
                        break;
                };

                match = match && (hasMatched ?? true);
            });

        return match;
    }

    const handleFieldFilter = event => {
        const { name, value } = event.target;

        let updatedFieldFilter = [...fieldFilter];

        updatedFieldFilter
            .find(fieldFilter => fieldFilter.name == name)
            .value = value;

        setFieldFilter(updatedFieldFilter);
    }

    // clear field filter
    const clearFieldFilter = targetField => {
        let updatedFieldFilter = [...fieldFilter];

        updatedFieldFilter
            .find(fieldFilter => fieldFilter.name.toString() === targetField)
            .value = '';

        setFieldFilter(updatedFieldFilter);
    }

    // clear all field filter(s)
    const clearAllFieldFilter = () => {
        let updatedFieldFilter = [...fieldFilter];

        updatedFieldFilter.map(fieldFilter => fieldFilter.value = '');

        setFieldFilter(updatedFieldFilter);

        // reset filter
        setCurAppliedFilter(0);
    }

    // apply filter
    useEffect(() => {
        let processedFeeds = [...props.feeds];

        if (props.searchable) {
            processedFeeds = filter
                ? processedFeeds.filter(dataRow => match(dataRow))
                : processedFeeds

            setFeeds(processedFeeds);
        }

        if (props.dateSearchable) {
            processedFeeds = filter
                ? processedFeeds.filter(dataRow => match(dataRow))
                : processedFeeds

            setFeeds(processedFeeds);
        }

        if (props.filterable) {
            processedFeeds = processedFeeds.filter(dataRow => matchField(dataRow))

            setFeeds(processedFeeds);
        }

        if (props.sortable) {
            processedFeeds = stableSort(processedFeeds, getComparator(order, orderBy));

            setFeeds(processedFeeds);
        }

        if (props.paginable) {
            processedFeeds = processedFeeds.slice(
                page * props.pageSize,
                page * props.pageSize + props.pageSize
            )

            setPaginatedFeeds(processedFeeds);
        }

    }, [filter, fieldFilter, order, orderBy, page]);

    // Search by date from and date till
    const [state, setState] = useState({
        fields: [
            {
                name: 'date',
                value: '',
                label: 'Date',
                type: 'date',
                fullWidth: true,
                sizeOptions: {
                    xs: 12
                },
            },
        ],
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        let newState = { ...state };

        newState.fields
            .find((field) => field.name === name)
            .value = value;

        setFilter(value);
        setState(newState);
    };

    return (
        <Content>
            {(props.editable || props.filterable) &&
                <TableContainer component={Paper}>
                    <Toolbar>
                        {/* add edit option */}
                        {props.editable && props.editOptions.add &&
                            <>
                                <BasicButton
                                    type="iconbutton"
                                    edge="start"
                                    onClick={() => setAddModalOpen(true)}
                                    icon={<AddCircleIcon sx={{ color: "text.primary" }} />}
                                />
                                <StandardDialog
                                    paper
                                    open={addModalOpen}
                                    iconClose={() => setAddModalOpen(false)}
                                    close={() => setAddModalOpen(false)}
                                    confirm={() => setAddModalOpen(false)}
                                    title={addModal.title}
                                    content={addModal.content}
                                    actions={addModal.actions !== undefined ?
                                        <DialogActions>
                                            {addModal.actions}
                                        </DialogActions> :
                                        undefined}
                                    button={true}
                                />
                            </>
                        }
                        {props.editable && props.editOptions.edit &&
                            <>
                                <BasicButton
                                    type="iconbutton"
                                    onClick={() => setOnEdit(!onEdit)}
                                    icon={
                                        onEdit ?
                                            <CancelIcon sx={{ color: "text.primary" }} />
                                            : <BorderColorRoundedIcon sx={{ color: "text.primary" }} />
                                    }
                                />
                                <StandardDialog
                                    paper
                                    open={editModalOpen}
                                    iconClose={() => setEditModalOpen(false)}
                                    close={() => setEditModalOpen(false)}
                                    confirm={() => setAddModalOpen(false)}
                                    title={editModal.title}
                                    content={editModal.content}
                                    actions={editModal.actions !== undefined ?
                                        <DialogActions>
                                            {editModal.actions}
                                        </DialogActions>
                                        : undefined}
                                    button={true}
                                />
                            </>
                        }
                        {props.filterable &&
                            <>
                                {/* {props.filterable && props.filterOptions && 
                                    <Select
                                        native
                                        value={curAppliedFilter}
                                        onChange={handleCurAppliedFilterChanged}
                                    > 
                                        {props.filterOptions.filterLists.map((filterItem, index) => <option key={filterItem.name} value={index}>{filterItem.name}</option>)}
                                    </Select>
                                } */}
                                <Box sx={{ flexGrow: 1 }} />
                                <BasicButton
                                    type="iconbutton"
                                    edge='end'
                                    onClick={() => setShowFieldFilter(!showFieldFilter)}
                                    icon={<FilterListIcon sx={{ color: "text.primary" }} />}
                                />
                                {props.editable &&
                                    fieldFilter.filter(field => field.value !== '').length > 0 &&
                                    <>
                                        {/* <Chip 
                                            label='Save' 
                                            onClick={() => props.filterOptions.filterLists.push({ name: 'test', value: fieldFilter.map(field => field) })}
                                        />  */}
                                        <Chip label='Clear Filter' onClick={clearAllFieldFilter} sx={{ color: "text.primary" }} />
                                    </>
                                }
                            </>
                        }
                    </Toolbar>
                </TableContainer>
            }

            <TableContainer component={Paper}>
                {props.searchable || props.dateSearchable || props.switchColumn ?
                    <Toolbar>
                        <>
                            {
                                props.searchable &&
                                <SearchBar
                                    placeholder={props.placeholder
                                        ? t(props.placeholder)
                                        : null
                                    }
                                    change={event => setFilter(event.target.value)}
                                    clear={() => setFilter('')}
                                    value={filter}
                                />
                            }
                            {
                                props.dateSearchable &&
                                <Stack direction="row" spacing={2} sx={{ paddingTop: '2%', paddingBottom: '2%' }}>
                                    <FormGenerator fields={state.fields} onChange={handleChange} />
                                    {console.log(state.fields)}
                                </Stack>
                            }


                            <Box sx={{ flexGrow: 1 }} />

                            {props.switchColumn &&
                                <BasicButton
                                    type="iconbutton"
                                    edge='end'
                                    onClick={() => setColumn(!showColumn)}
                                    icon={showColumn ? <AppsIcon sx={{ color: "text.primary" }} /> : <ViewCompactIcon sx={{ color: "text.primary" }} />}
                                />
                            }
                        </>
                    </Toolbar> : null
                }

            </TableContainer>
            <TableContainer component={Paper}>
                {!showColumn ?
                    <Table sx={{ minWidth: 700 }} size={props.size}>
                        <TableHead>
                            <TableRow>
                                {props.editable && onEdit && <TableCell />}
                                {props.header
                                    .map((headerField, index) =>
                                        // TableHead WithMobileColumn
                                        <WithMobileColumn key={index} index={index} value={props.maxMobileColumn}>
                                            <TableCell
                                                align={headerField.numeric
                                                    ? 'right'
                                                    : 'left'
                                                }
                                            >
                                                {props.sortable
                                                    ? <TableSortLabel
                                                        active={orderBy === headerField.field}
                                                        direction={
                                                            orderBy === headerField.field
                                                                ? order
                                                                : 'asc'
                                                        }
                                                        onClick={() => handleRequestSort(headerField.field)}
                                                    >
                                                        <Typo variant="body1" fontSize="14px" type="title" fontWeight="bold">
                                                            {t(headerField.headerName)}
                                                        </Typo>
                                                    </TableSortLabel>
                                                    :
                                                    <Typo variant="body1" fontSize="14px" type="title" fontWeight="bold">
                                                        {t(headerField.headerName)}
                                                    </Typo>
                                                }
                                            </TableCell>
                                        </WithMobileColumn>
                                    )
                                }
                            </TableRow>
                            {showFieldFilter &&
                                <TableRow>
                                    {props.editable && onEdit && <TableCell />}
                                    {props.header
                                        .map((headerField, index) => {
                                            // TableHead filter WithMobileColumn
                                            if (index > props.maxMobileColumn) {
                                                return (
                                                    <WithMobileColumn key={index} index={index} value={props.maxMobileColumn}>
                                                        <TableCell key={index}>
                                                            <TextField
                                                                name={headerField.field.toString()}
                                                                value={fieldFilter
                                                                    .find(filter => filter.name.toString() === headerField.field.toString())
                                                                    .value}
                                                                required={headerField.required}
                                                                onChange={handleFieldFilter}
                                                                InputProps={{
                                                                    endAdornment: fieldFilter
                                                                        .find(filter => filter.name.toString() === headerField.field.toString())
                                                                        .value !== '' &&
                                                                        <CloseIcon onClick={() => clearFieldFilter(headerField.field.toString())} />
                                                                }}
                                                            />
                                                        </TableCell>
                                                    </WithMobileColumn>
                                                );
                                            } else {
                                                return (
                                                    <TableCell key={index}>
                                                        <TextField
                                                            name={headerField.field.toString()}
                                                            value={fieldFilter
                                                                .find(filter => filter.name.toString() === headerField.field.toString())
                                                                .value}
                                                            required={headerField.required}
                                                            onChange={handleFieldFilter}
                                                            InputProps={{
                                                                endAdornment: fieldFilter
                                                                    .find(filter => filter.name.toString() === headerField.field.toString())
                                                                    .value !== '' &&
                                                                    <CloseIcon onClick={() => clearFieldFilter(headerField.field.toString())} />
                                                            }}
                                                        />
                                                    </TableCell>
                                                )
                                            }
                                        }
                                        )}
                                </TableRow>
                            }
                        </TableHead>
                        <TableBody>
                            {paginatedFeeds.map(dataRow => {
                                let keys = {}
                                props.keys.map(keyField => keys[keyField] = dataRow[keyField]);

                                let keyValueString = '';

                                for (const keyFields in keys) {
                                    keyValueString += dataRow[keyFields];
                                }

                                return (
                                    <TableRow
                                        hover={props.hover}
                                        key={keyValueString}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {props.editable && onEdit &&
                                            <TableCell padding="none" style={{ paddingLeft: 16, paddingRight: 16 }}>
                                                <IconButton size="large">
                                                    <IndeterminateCheckBoxIcon />
                                                </IconButton>
                                            </TableCell>
                                        }
                                        {props.header.map((headerField, index) => {
                                            // custom defined cell
                                            // ** onclick event will override default table row user defined onclick event on edit mode
                                            // ** cross-check if custom defined cell is bind with onclick, 
                                            // ** if none, bind default user defined table row onclick event
                                            // ** if there's no table row onclick event, do nothing 
                                            if (headerField.render)
                                                return (
                                                    <WithMobileColumn key={index} index={index} value={props.maxMobileColumn}>
                                                        <TableCell
                                                            component="th" scope="row"
                                                            onClick={() => onEdit ? setEditModalOpen(true)
                                                                : headerField.onClick
                                                                    ? headerField.onClick(dataRow)
                                                                    : props.onDetail && props.onDetail(dataRow)}
                                                        >
                                                            <Typo variant="body2" fontSize="12px" type="title" fontWeight="bold">
                                                                {headerField.render(dataRow)}
                                                            </Typo>
                                                        </TableCell>
                                                    </WithMobileColumn>
                                                )
                                            else
                                                // TableBody WithMobileColumn
                                                return (
                                                    <WithMobileColumn key={index} index={index} value={props.maxMobileColumn}>
                                                        <TableCell
                                                            component="th" scope="row"
                                                            align={headerField.numeric
                                                                ? 'right'
                                                                : 'left'
                                                            }
                                                            onClick={() => onEdit
                                                                ? setEditModalOpen(true)
                                                                : props.onDetail && props.onDetail(dataRow)}
                                                        >
                                                            <Typo variant="body2" fontSize="12px" type="title">
                                                                {headerField.date
                                                                    ? <DateFormatter date={dataRow[headerField.field]} />
                                                                    : dataRow[headerField.field]
                                                                }
                                                            </Typo>
                                                        </TableCell>
                                                    </WithMobileColumn>
                                                )
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter>
                            {props.footer && props.footer.map(dataRow => {
                                return (
                                    <TableRow>
                                        <TableCell align={'left'}>
                                            <Typo variant="body2" fontSize="12px" type="title" fontWeight="bold">
                                                {dataRow.desc}
                                            </Typo>
                                        </TableCell>
                                        <TableCell align={'right'}>
                                            <Typo variant="body2" fontSize="12px" type="title">
                                                {dataRow.amount}
                                            </Typo>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableFooter>
                    </Table> :
                    <BasicCard content={paginatedFeeds} />
                }

            </TableContainer>
            {props.paginable &&
                <TableContainer component={Paper}>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={feeds.length}
                        rowsPerPage={props.pageSize}
                        page={page}
                        onPageChange={(event, newPage) => setPage(newPage)}
                    />
                </TableContainer>
            }

            {props.trxdate !== undefined ?
                <Typo variant="h6" type="title" fontSize='12px'>
                    <CachedIcon fontSize="small" style={{ position: 'relative', top: '4px', fontSize: '16px' }} /> Last updated on {props.trxdate} HRS
                </Typo>
                : null
            }

        </Content>
    );
}

const WithMobileColumn = (props) => {

    const { height, width } = useWindowDimensions();

    const { children, index, value } = props

    if (index > value) {
        return width < 516 ? null : children
    } else {
        return (
            children
        )
    }
}