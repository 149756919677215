import { useState } from 'react'
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    FormControlLabel,
    FormHelperText,
    Checkbox,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemAvatar,
    Switch,
    Chip,
    MenuItem,
    OutlinedInput,
    ListItemText,
    Box,
    InputAdornment
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Content from '../../src/hoc/Content';
import BasicCard from '../components/Card/BasicCard';
import Typo from '../components/Text/Typo';

const PREFIX = 'form-generator';

const classes = {
    root: `${PREFIX}-root`,
    countryAvatar: `${PREFIX}-countryAvatar`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },

    [`& .${classes.countryAvatar}`]: {
        float: 'left',
        marginRight: '15px',
        display: 'block',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        margin: '4px',
    }
}));

export default function FormGenerator(props) {

    const { t } = useTranslation()
    const [state, setState] = useState({
        checked: true,
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return props.fields.map((field) => {
        switch (field.type) {
            case 'select':
                return (
                    <StyledGrid item xs={12} {...field.sizeOptions}>
                        <FormControl fullWidth
                            required={field.required}
                            variant="standard"
                            error={field.error}
                        >
                            <InputLabel>
                                {t(field.label)}
                            </InputLabel>
                            <Select
                                labelId={field.label}
                                id={field.name}
                                name={field.name}
                                value={field.value}
                                onChange={props.onChange}
                                label={field.label}
                            >
                                {field.options.map((option) => <MenuItem value={option.value}>{t(option.displayValue)}</MenuItem>)}
                            </Select>
                            {field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
                        </FormControl>
                    </StyledGrid>
                );
            case 'select-multi':
                return (
                    <StyledGrid item xs={12} {...field.sizeOptions}>
                        <FormControl fullWidth
                            variant="standard"
                            required={field.required}
                            error={field.error}
                        >
                            <InputLabel>
                                {t(field.label)}
                            </InputLabel>
                            <Select
                                labelId={field.label}
                                id={field.name}
                                multiple
                                name={field.name}
                                value={field.value}
                                onChange={props.onMultiChange}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                      ))}
                                    </Box>
                                )}
                            >
                                {field.options.map((option,index) => 
                                    <MenuItem 
                                        key={option.value}
                                        value={option.value}
                                    >
                                        <ListItemText primary={t(option.displayValue)} />
                                    </MenuItem>
                                )}
                            </Select>
                            {field.helperText && <FormHelperText>{field.helperText}</FormHelperText>}
                        </FormControl>
                    </StyledGrid>
                );
            case 'chip':
                return (
                    <div className={classes.root}>
                        <Chip
                            label={t(field.label)}
                            clickable
                            color={field.color}
                            name={field.name}
                            // onDelete={props.onClick}
                            // deleteIcon={<DoneIcon />}
                            onClick={props.onChange}
                        />
                    </div>
                );
            case 'check-box':
                return (
                    <Grid item xs={12} {...field.sizeOptions}>
                        <FormControlLabel
                            name={field.name}
                            control={<Checkbox />}
                            label={field.label}
                            onChange={props.onChange}
                        />
                    </Grid>
                );

            case 'accordion':
                return (
                    <Grid item xs={12} {...field.sizeOptions}>
                        <Accordion defaultExpanded style={{ width: '100%' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typo variant="subtitle1" type="title">
                                    {field.name}
                                </Typo>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {field.accordionOptions.map((accordionoption) =>
                                        <ListItem>
                                            <ListItemAvatar>•</ListItemAvatar><Typo variant="body2" type="title"> {accordionoption.displayValue}</Typo>
                                        </ListItem>
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                );

            case 'summary-list':
                return (
                    <Grid container>
                        {field.summaryOptions.map((summaryOption) =>
                            <Content>
                                <Grid item xs={6} style={{ textAlign: 'left' }} {...field.sizeOptions}>
                                    <Typo variant="body2" type="title">
                                        {summaryOption.displayPrimaryValue}
                                    </Typo>
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right' }} {...field.sizeOptions}>
                                    <Typo variant="body2" type="title">
                                        {summaryOption.displaySecondaryValue}
                                    </Typo>
                                </Grid>
                            </Content>
                        )}
                    </Grid>
                );
            case 'iosSwitch':
                return (
                    null
                );
            default:
                return (
                    <Grid item xs={12} {...field.sizeOptions} >
                        <TextField
                            disabled={field.disabled}
                            variant="standard"
                            required={field.required}
                            id={field.name}
                            label={t(field.label)}
                            name={field.name}
                            type={field.type}
                            placeholder={field.placeholder}
                            fullWidth={field.fullWidth}
                            autoComplete={field.name}
                            onChange={props.onChange}
                            autoFocus={field.autoFocus}
                            InputProps={{
                                startAdornment: 
                                    field.input ? 
                                        <InputAdornment position="start">
                                            {field.input}
                                        </InputAdornment> 
                                    : null,
                                endAdornment: 
                                    field.inputEnd ? 
                                        <InputAdornment position="end">
                                            {field.inputEnd}
                                        </InputAdornment> 
                                    : null,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={field.error}
                            helperText={field.helperText}
                            value={field.value}
                        />
                    </Grid>
                );

        }
    });
}
