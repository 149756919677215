import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    home: {
        exchangeCountries: [
            {
                name: 'Crypto',
                value: 'Crypto',
                selected: true,
                exchanges: [
                    {
                        name: 'Binance',
                        value: true,
                        proName: "INDEX:Binance",
                        title: "Binance",
                    },
                    {
                        name: 'Kraken',
                        value: false,
                        proName: "INDEX:Kraken",
                        title: "Kraken",
                    },
                    {
                        name: 'Gemini',
                        value: false,
                        proName: "INDEX:Gemini",
                        title: "Gemini",
                    },
                    {
                        name: 'Crypto.com',
                        value: false,
                        proName: "INDEX:Crypto.com",
                        title: "Crypto.com",
                    }
                ],
            },
            {
                name: 'Malaysia',
                value: 'MY',
                selected: false,
                exchanges: [
                    {
                        name: 'MYX',
                        value: true,
                        proName: "INDEX:KLSE",
                        title: "MYX",
                    }
                ],
            },
            {
                name: 'United State',
                value: 'US',
                selected: false,
                exchanges: [
                    {
                        name: 'Nasdaq',
                        value: true,
                        proName: "FOREXCOM:NSXUSD",
                        title: "Nasdaq 100",
                    },
                    {
                        name: 'NYSE',
                        value: false,
                    },
                ],
            },
        ],
    },
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setCountry: (state, { payload }) => {
            // console.log(payload, 'setCountry payload')
            // payload = value
            // reset selected countries
            state.home.exchangeCountries.map(country => country.selected = false)

            // set selected country
            state.home.exchangeCountries.find(country => country.value === payload).selected = true
        },
        setExchange: (state, { payload }) => {
            // console.log(payload, 'setExchange payload')
            // reset selected 
            state.home.exchangeCountries
                .find(country => country.selected)
                .exchanges.map(exchange => {
                    exchange.value = false

                    if (exchange.name === payload)
                        exchange.value = true

                    return exchange
                })
        },

    },
})

export const {
    setCountry,
    setExchange,
} = uiSlice.actions

export default uiSlice.reducer

