import { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom';

// hoc
import ExchangeInfo from '../hoc/Layouts/ExchangeInfo';

// components
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import BasicTable from '../components/Table/BasicTable';
import { MultiTabs } from '../components/Tab/SimpleTabs';
import TableRow from '../components/Table/TableRow';
import LineGraph from '../components/Graph/LineGraph';
import Typo from '../components/Text/Typo';

const PREFIX = 'Highlights';

const classes = {
  hiddenspace: `${PREFIX}-hiddenspace`
};

const StyledExchangeInfo = styled(ExchangeInfo)((
  {
    theme
  }
) => ({
  [`& .${classes.hiddenspace}`]: {
    padding: '2%',
    [theme.breakpoints.down('xl')]: {
      padding: '10%',
    },
  }
}));

export default function Highlights(props) {

  const { t } = useTranslation()
  const history = useHistory();

  const profileRedirectHandler = (equity) => {
    const queryParams = '?' + encodeURIComponent('exchange') + '=' + encodeURIComponent(equity.exchange)
      + '&' + encodeURIComponent('ticker') + '=' + encodeURIComponent(equity.ticker);

    history.push({
      pathname: '/Equity',
      search: queryParams,
    });
  }

  // Dummy ticker data
  const data = [
    { day: 1, value: 200 },
    { day: 2, value: 500 },
    { day: 3, value: 212 },
    { day: 4, value: 900 },
    { day: 5, value: 300 },
    { day: 6, value: 543 },
    { day: 7, value: 1000 },
  ]

  const [stock, setStock] = useState({
    key: ['exchange', 'ticker'],
    header: [
      {
        headerName: 'Name',
        field: 'equity',
        render: (dataRow) =>
          <TableRow
            title={dataRow.ticker}
            subtitle={dataRow.name}
          />
      },
      {
        headerName: '7 Days Trend',
        field: '',
        render: () =>
          <LineGraph timeframe={data} width={'300'} height={'100'} />
      },
      {
        headerName: 'Price Change',
        field: 'change',
        align: 'right',
        key: false,
        render: dataRow =>
          <div style={{
            paddingLeft: '16px',
            paddingRight: '16px'
          }}>
           <TableRow
              title={dataRow.price}
              subtitle={Number(dataRow.change)}
              subtitle2={Number(dataRow.changePercentage)}
            />
          </div>
      },
      {
        headerName: 'No of Days',
        field: 'noOfDays',
        numeric: true,
        width: 110,
      },
      {
        headerName: 'Trigger Date',
        field: 'initTriggerDate',
        date: true,
      },
    ],
    feeds: [
      {
        exchange: 'NASDAQ',
        ticker: 'MVIS',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 5,
        noOfDays: 7,
        initTriggerDate: new Date(2020, 12, 25),
        favourite: true,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'CLNE',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 1,
        noOfDays: 29,
        initTriggerDate: new Date(2020, 11, 15),
        favourite: false,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'CBAT',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 2,
        noOfDays: 2,
        initTriggerDate: new Date(2020, 12, 22),
        favourite: true,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'WWR',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 1.1,
        noOfDays: 3,
        initTriggerDate: new Date(2020, 11, 15),
        favourite: false,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'WKHS',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 0.1,
        noOfDays: 40,
        initTriggerDate: new Date(2020, 9, 25),
        favourite: true,
      },
      {
        exchange: 'MYX',
        ticker: 'TOPGLOV',
        country: 'MY',
        price: 45.23,
        change: 1.6,
        changePercentage: 0.01,
        noOfDays: 29,
        initTriggerDate: new Date(2020, 11, 15),
        favourite: false,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'RIOT',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 0.001,
        noOfDays: 7,
        initTriggerDate: new Date(2020, 6, 25),
        favourite: true,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'BLNK',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 0.001,
        noOfDays: 29,
        initTriggerDate: new Date(2020, 11, 15),
        favourite: false,
      },
      {
        exchange: 'MYX',
        ticker: 'SUPERMX',
        country: 'MY',
        price: 45.23,
        change: 1.6,
        changePercentage: 0.001,
        noOfDays: 5,
        initTriggerDate: new Date(2020, 12, 11),
        favourite: false,
      },
      {
        exchange: 'MYX',
        ticker: 'ESCAREM',
        country: 'MY',
        price: 45.23,
        change: 1.6,
        changePercentage: 0.02,
        noOfDays: 26,
        initTriggerDate: new Date(2020, 11, 25),
        favourite: true,
      },
    ],
  });

  const [sector, setSector] = useState({
    key: ['exchange', 'ticker'],
    header: [
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Change(%)',
        field: 'percentage',
        numeric: true,
      },
    ],
    feeds: [
      {
        name: 'Aerospace & Defense',
        percentage: 141.85,
        favourite: true,
      },
      {
        name: 'Airlines',
        percentage: 116.63,
        favourite: false,
      },
      {
        name: 'Auto Components',
        percentage: 22.85,
        favourite: true,
      },
      {
        name: 'Automobiles',
        percentage: 10.63,
        favourite: false,
      },
      {
        name: 'Banking',
        percentage: 33.85,
        favourite: true,
      },
      {
        name: 'Basic Materials',
        percentage: 6.63,
        favourite: false,
      },
      {
        name: 'Beverages',
        percentage: 22.85,
        favourite: true,
      },
      {
        name: 'Biotechnology',
        percentage: 50.63,
        favourite: false,
      },
      {
        name: 'Building',
        percentage: 6.57,
        favourite: false,
      },
      {
        name: 'Chemicals',
        percentage: 0.6,
        favourite: true,
      },
    ],
  });

  const content = [
    {
      label: 'Ticker',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='small'
            searchable
            sortable
            paginable
            trxdate='21 Jan 2021 1300'
            pageSize={10}
            keys={stock.key}
            header={stock.header}
            feeds={stock.feeds}
            onDetail={profileRedirectHandler}
            maxMobileColumn={1}
          />
        </Grid>,
    },
    {
      label: 'Sector',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='small'
            searchable
            sortable
            paginable
            trxdate='21 Jan 2021 1100'
            pageSize={10}
            keys={sector.key}
            header={sector.header}
            feeds={sector.feeds}
            onDetail={profileRedirectHandler}

          />
        </Grid>,
    }
  ];



  return (
    <StyledExchangeInfo>
      <Container disableGutters maxWidth="lg">
        <Typo variant="h6" type="title" fontWeight="bold">
          {t('Highlights')}
        </Typo>
        <MultiTabs content={content} />
      </Container>
      <div className={classes.hiddenspace} />
    </StyledExchangeInfo>
  );
}