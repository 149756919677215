import {Button, IconButton, Avatar, ListItemAvatar, Box, ListItemText } from '@mui/material'
import Typo from '../Text/Typo';
import numericColorFormatter, { numericPlusFormatter, numericColorBackgroundFormatter } from '../../formatter/numeric-formatter';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export default function BasicButton(props) {
    return props.type == "button" ?
        <Button className={props.className} name={props.name} onClick={props.onClick} >
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                {props.src ? 
                    <Avatar src={props.src} style={{ right:'10%'}}/> 
                : null}
                {props.icon? props.icon : null}
                <Box sx={{
                        display: { xs: props.hiddentext ? 'none' : 'block', md: 'block' },
                }}>
                    <Typo variant={props.variant} type="title" fontWeight='bold'>
                        {props.content}
                    </Typo>
                </Box>
            </div>
        </Button>
    : props.type == "avatarbutton" ?
        <Button className={props.className} name={props.name} onClick={props.onClick}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                {props.src ? 
                    <Avatar src={props.src} style={{ right:'10%'}}/> 
                : null}
                {props.icon? 
                    <ListItemAvatar >
                        <Avatar sx={{color:"text.primary", backgroundColor:"transparent"}}> 
                            {props.icon }
                        </Avatar>
                    </ListItemAvatar>
                : null}
                <Box sx={{
                        display: { xs: props.hiddentext ? 'none' : 'block', md: 'block' },
                }}>                    
                    <Typo variant={props.variant} type="title" fontWeight='bold'>
                        
                        {props.content && props.itemtext
                            ? <ListItemText primary={props.content} />
                            : props.content
                        }
                    </Typo>
                </Box>
            </div>
        </Button>
    : props.type == "iconbutton" ?
        <IconButton
            className={props.className}
            onClick={props.onClick}
            edge={props.edge}
            size="large"
        >
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
                {props.src ? 
                    <Avatar sx={{right:'10%', color:"text.primary", backgroundColor:"transparent"}} src={props.src}/> 
                : null}
                {props.icon? props.icon : null}
                <Box sx={{
                        display: { xs: props.hiddentext ? 'none' : 'block', md: 'block' },
                }}>                    
                    <Typo variant={props.variant} type="title" fontWeight='bold'>
                        {props.content}
                    </Typo>
                </Box>
            </div>
        </IconButton>
    : props.type == "numericbutton" ?
    <Button 
        className={props.className} onClick={props.onClick} edge={props.edge} 
        style={{backgroundColor:numericColorBackgroundFormatter(props.content), padding:0}} 
        variant={props.buttonvariant} size="small" disabled
        disableElevation
    >
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
            {
                numericPlusFormatter(props.content) !== "-" ? 
                <ArrowUpwardIcon style={{color:numericColorFormatter(props.content)}} fontSize="small"/>
                : 
                <ArrowDownwardIcon style={{color:numericColorFormatter(props.content)}} fontSize="small"/>
            }
            <Typo variant={props.variant} type="numeric">
                {props.content} {props.percentage ? '%': null}
            </Typo>
        </div>
    </Button>
    : null;
}