import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { setCountry } from '../../redux/uiSlice'

import {
    FormControl, NativeSelect, Container,
    Avatar, ListItem, List, Box, 
} from '@mui/material'

import Content from '../Content';
import ExchangeToggle from '../../components/ExchangeToggle/ExchangeToggle';
import FormGenerator from '../../helper/form-generator';
import ExchangeBar from '../../components/TradingView/ExchangeBar';

import USIcon from '../../img/country/united-states-of-america.png';
import CryptoIcon from '../../img/country/cryptocurrency.png';
import MalaysiaIcon from '../../img/country/malaysia.png';

const PREFIX = 'ExchangeInfo';

const classes = {
    formControl: `${PREFIX}-formControl`,
    countryDropDown: `${PREFIX}-countryDropDown`,
    countryDropDownItem: `${PREFIX}-countryDropDownItem`,
    countryAvatar: `${PREFIX}-countryAvatar`
};

const StyledContent = styled(Content)((
    {
        theme
    }
) => ({
    [`& .${classes.formControl}`]: {
        paddingBottom: '1%',
        padding: '1%',
    },

    [`& .${classes.countryDropDown}`]: {
        margin: '0px',
        fontWeight: '700',
        fontSize: '1.5rem',
        width: 'auto',
    },

    [`& .${classes.countryDropDownItem}`]: {
        position: 'absolute',
        fontSize: '1.2rem',
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        paddingTop: '5px',
        paddingBottom: '5px',
    },

    [`& .${classes.countryAvatar}`]: {
        float: 'left',
        marginRight: '15px',
        display: 'block',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        margin: '4px',
    }
}));



export default function ExchangeInfo(props) {

    const { t } = useTranslation()
    const { exchangeCountries } = useSelector(state => state.ui.home)
    const dispatch = useDispatch()

    const [index, setIndex] = useState({
        fields: [
            {
                type: 'index_carousel',
                item: [
                    {
                        itemOptions: [
                            {
                                icon: USIcon,
                                index: 'NASDAQ',
                                price: 12291,
                                change: 121,
                                changePercentage: '3%',
                                redirect: ''
                            },
                            {
                                icon: USIcon,
                                index: 'DJI',
                                price: 1921,
                                change: -21,
                                changePercentage: '-1.2%',
                                redirect: ''
                            },
                        ],
                    },
                    {
                        itemOptions: [
                            {
                                icon: MalaysiaIcon,
                                index: 'DJI',
                                price: 1921,
                                change: -21,
                                changePercentage: '-1%',
                                redirect: ''
                            },
                            {
                                icon: MalaysiaIcon,
                                index: 'DJI',
                                price: 1921,
                                change: -21,
                                changePercentage: '-19%',
                                redirect: ''
                            },
                        ],
                    },
                ],
                padding: '1%',
                autoPlay: true,
                animation: "false",
                indicators: false,
                timeout: 500,
                navButtonsAlwaysInvisible: true,
            },
        ],
    });

    const toggleCountry = (event) => {
        const { value } = event.target;

        dispatch(setCountry(value))        

        // let temp_exchangeCountriesObj = [...authCtx.auth.exchangeCountries]

        // reset all selected status
        // temp_exchangeCountriesObj.map((country) => {
        //     country.selected = false
        //     return country
        // })

        // update selected status for target country
        // temp_exchangeCountriesObj.find(country => country.value === value).selected = true

        // setAuthCtx(prevState => ({
        //     ...prevState,
        //     auth: {
        //         ...prevState.auth,
        //         exchangeCountries: temp_exchangeCountriesObj
        //     }
        // }))
    }

    const [exchangesBarItems] = useState([
        {
            exchangeCode: 'DJI',
            exchangePrice: 4323.2,
            exchangeChanges: 1.5,
            changesPercentage: 0.03,
            country: 'US'
        },
        {
            exchangeCode: 'NASDAQ',
            exchangePrice: 14547.8,
            exchangeChanges: -3.7,
            changesPercentage: -0.02,
            country: 'US'
        },
        {
            exchangeCode: 'NYSE',
            exchangePrice: 1.18420,
            exchangeChanges: -0.00054,
            changesPercentage: -0.05,
            country: 'US'
        },
        {
            exchangeCode: 'SP500',
            exchangePrice: 33737.35,
            exchangeChanges: 175.24,
            changesPercentage: 0.64,
            country: 'US'
        },
    ])

    return (
        <StyledContent>
            {/* <FormGenerator fields={index.fields} /> */}
            <ExchangeBar />
            {/* Exchange Country And Button */}
            <Container disableGutters maxWidth="lg">
                <FormControl className={classes.formControl}>
                    <List>
                        <ListItem key='country' disableGutters>
                            <Avatar className={classes.countryAvatar} src={CryptoIcon} />
                            <Box sx={{paddingLeft:'5%'}}></Box>
                            <NativeSelect
                                value={exchangeCountries.find(country => country.selected).value}
                                onChange={toggleCountry}
                                className={classes.countryDropDown}
                            >
                                {exchangeCountries.map((country) =>
                                    <option
                                        className={classes.countryDropDownItem}
                                        key={country.value}
                                        value={country.value}
                                    >
                                        {t(country.name)}
                                    </option>
                                )}
                            </NativeSelect>
                        </ListItem>
                        <ListItem key='exchange' disableGutters>
                            <ExchangeToggle />
                        </ListItem>
                    </List>
                </FormControl>
            </Container>
            {props.children}
        </StyledContent>
    );
}