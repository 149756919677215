// components
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Height } from '@mui/icons-material';

const popperMenu = (props) => 
    <Popper 
      open={props.open} 
      anchorEl={props.anchorEl} 
      role={undefined} 
      transition 
      disablePortal 
      style={{ zIndex:'2'}} 
      {...props}  
    >
    {({ TransitionProps, placement }) => (
      <Grow 
        {...TransitionProps}
        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
      >
        <Paper>
          <ClickAwayListener onClickAway={props.onClose}>
              {props.children}
            </ClickAwayListener>
            </Paper>
        </Grow>
        )}
    </Popper>

export default popperMenu;