import { createTheme } from '@mui/material/styles';
import { lightBlue,grey } from '@mui/material/colors';
export const lightTheme =  createTheme(
  {
    palette: {
      mode: 'light',
      primary: lightBlue,
      divider: lightBlue[200],
      text: {
        primary: grey[900],
        secondary: grey[800],
      },
      // primary: {
      //   main: "#DCDBD6", //light grey
      //   inverted: "#005DBB", //light blue
      //   background: "#000", //black
      // },
      // secondary: {
      //   main: "#005DBB", //light blue
      //   inverted: "#DCDBD6", //light grey
      //   error: "#f44336", //red
      //   background: "#e9e8e5", //lighter grey
      // }
    }
  }
);
export const darkTheme =  createTheme(
  {
    palette: {
      mode: 'dark',
      primary: grey,
      divider: grey[700],
      background: {
        default: grey[900],
        paper: grey[900],
      },
      text: {
        primary: '#fff',
        secondary: grey[500],
      },
      // primary: {
      //   main: "#DCDBD6", //light grey
      //   inverted: "#fff", //light grey
      //   background: "#000", //black
      // },
      // secondary: {
      //   main: "#DCDBD6", //light grey
      //   inverted: "#000",//black
      //   error: "#f44336", //red
      //   background: "#000", //black
      // }
    }
  }
);

