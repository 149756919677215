import { styled } from '@mui/material/styles';
import { Container } from '@mui/material'
import Footer from './Footer'
import Lottie from 'react-lottie';
import Anim404 from '../components/LottieAnim/404.json';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import Typo from '../components/Text/Typo';

const PREFIX = 'About';

const classes = {
    root: `${PREFIX}-root`,
    headingText: `${PREFIX}-headingText`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        paddingTop: '32px !important',
        paddingBottom: '80px !important'
    },

    [`& .${classes.headingText}`]: {
        marginTop: 0,
        marginBottom: '1rem',
        fontWeight: 600,
        fontSize: '2rem'
    }
}));

const About = () => {
    const history = useHistory();


    return (
        (<Root>
            <Container className={classes.root}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={5} xs={12}>
                    <Lottie className="{classes.LottieImg}"
                        options={{loop: true,
                                autoplay: true, 
                                animationData: Anim404,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice'
                                }}}
                    />
                    </Grid>
                    <Grid item lg={7}  md={7} sm={7} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding:'5%' }}>
                        <Typo variant="h4" type="title" fontWeight="bold">
                            This isn't the page you're looking for
                        </Typo>
                        <Typo variant="h6" type="title">
                            Head back, or move along to the homepage to find a new way forward.
                        </Typo>
                        <Button variant="contained" onClick={() => history.push('/')}>
                            Head To Home
                        </Button>
                    </Grid>
                </Grid>
              
            </Container>
            <Footer/>
        </Root>)
    );
}

export default About;