import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

// hoc
import ExchangeInfo from '../hoc/Layouts/ExchangeInfo';

// components
import useMediaQuery from '@mui/material/useMediaQuery';
import Share, { PhoneShare, isPhone, getCurrentURL } from '../components/Share'

// material UI
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import BasicCard from '../components/Card/BasicCard';
import BasicTable from '../components/Table/BasicTable';
import CardMedia from '@mui/material/CardMedia';

// icon
import Favourite from '../icon/favourite';
import ShareIcon from '@mui/icons-material/Share';

// styles
import { useTheme } from '@mui/material/styles';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DoughnutGraph from '../components/Graph/DoughnutGraph';
import VerticalBarChart, { HorizontalBarChart } from '../components/Graph/BarChart';
import TableRow from '../components/Table/TableRow';
import LineGraph from '../components/Graph/LineGraph';
import Typo from '../components/Text/Typo';
const PREFIX = 'Home';

const classes = {
  hiddenspace: `${PREFIX}-hiddenspace`
};

const StyledExchangeInfo = styled(ExchangeInfo)((
  {
    theme
  }
) => ({
  [`& .${classes.hiddenspace}`]: {
    padding: '2%',
    [theme.breakpoints.down('xl')]: {
      padding: '10%',
    },
  }
}));

function PopUp() {
  const open = useState(true);
  const setOpen = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'));
  const theme = useTheme();
}

const Home = () => {
  const { t } = useTranslation()
  const history = useHistory();


  // Share Component
  const [openShare, setOpenShare] = useState(false);
  const handleOpenShare = () => { isPhone() ? PhoneShare('Latest News', 'Check this out!', getCurrentURL()) : setOpenShare(true); }
  const handleCloseShare = () => { setOpenShare(false); }

  // Dummy ticker data
  const data = [
    { day: 1, value: 200 },
    { day: 2, value: 500 },
    { day: 3, value: 212 },
    { day: 4, value: 900 },
    { day: 5, value: 300 },
    { day: 6, value: 543 },
    { day: 7, value: 1000 },
  ]

  const [dailyHit, setDailyHit] = useState(
    {
      key: ['exchange', 'ticker'],
      header: [
        {
          headerName: '',
          field: 'favourite',
          render: dataRow =>
            <Favourite favourite={dataRow.favourite} />,
          onClick: dataRow =>
            setFavouriteHandler({ exchange: dataRow.exchange, ticker: dataRow.ticker })
        },
        {
          headerName: 'Name',
          field: 'equity',
          render: dataRow =>
            <TableRow
              title={dataRow.exchange}
              subtitle={dataRow.ticker}
            />
        },
        {
          headerName: '7 Days Trend',
          field: '',
          render: dataRow =>
            <LineGraph timeframe={dataRow.timeframe} width={'300'} height={'100'} />
        },
        {
          headerName: 'Price Change',
          field: 'change',
          align: 'right',
          key: false,
          render: dataRow =>
            <HorizontalBarChart data={[{
                                        name: "Weekly Changes",
                                        highest: dataRow.price + dataRow.change,
                                        current: dataRow.price,
                                      }]}
            />
        },
        {
          headerName: 'Volume Change',
          field: 'change',
          align: 'right',
          key: false,
          render: dataRow =>
            <HorizontalBarChart data={[{
                                        name: "Weekly Changes",
                                        highest: dataRow.volume + 1000000,
                                        current: dataRow.volume,
                                      }]}
            />
        }
      ],
      feeds: [
        {
          exchange: 'BINANCE',
          ticker: 'BTCUSD',
          price: 45.23,
          change: 1.6,
          volume: 172729,
          changePercentage: 5,
          favourite: true,
          timeframe: data
        },
        {
          exchange: 'COINBASE',
          ticker: 'XRPUSD',
          price: 3,
          change: 1.62,
          volume: 212351,
          changePercentage: 5,
          favourite: false,
          timeframe: data
        },
        {
          exchange: 'BINANCE',
          ticker: 'WELUSD',
          price: 36,
          change: 19.2,
          volume: 372729,
          changePercentage: 55,
          timeframe: data
        },
        {
          exchange: 'COINBASE',
          ticker: 'ABCUSD',
          price: 25,
          change: -1.62,
          volume: 472729,
          changePercentage: -0.05,
          timeframe: data
        },
        {
          exchange: 'BINANCE',
          ticker: 'UASAUD',
          price: 31,
          change: -12.62,
          volume: 373129,
          changePercentage: -1.3,
          timeframe: data
        },
        {
          exchange: 'BINANCE',
          ticker: 'SOAUSD',
          price: 212,
          change: -11.62,
          volume: 92726,
          changePercentage: -0.1,
          timeframe: data
        },
        {
          exchange: 'BINANCE',
          ticker: 'AAXUADT',
          price: 113,
          change: 11.62,
          volume: 192726,
          changePercentage: 1,
          timeframe: data
        },
      ]
    }
  );
  
  
  const dailyActivity = [
    {
      title: 'Most Gainer',
      redirect: '/Highlights',
      name: 'Binance: ETHUSD',
      price: '170',
      changePercentage: '78',
      trxdate: '12 dec 2020',
      xs:6,
      lg:3,
      padding: '0.5%',
      activities: true
    },
    {
      title: 'Yesterday Gainer',
      redirect: '/Highlights',
      name: 'Binance: DEXEUSDT',
      price: '120',
      changePercentage: '1.2',
      trxdate: '12 dec 2020',
      xs:6,
      lg:3,
      padding: '0.5%',
      activities: true
    },{
      title: 'Top Loser',
      redirect: '/Highlights',
      name: 'Binance: BTCUSDT',
      price: '-1.15',
      changePercentage: '-20',
      trxdate: '12 dec 2020',
      xs:6,
      lg:3,
      padding: '0.5%',
      activities: true
    },
    {
      title: 'Most Triggered',
      redirect: '/Highlights',
      name: 'Binance: ZRXUSDT',
      price: '122',
      changePercentage: '20',
      trxdate: '12 dec 2020',
      xs:6,
      lg:3,
      padding: '0.5%',
      activities: true
    }
  ];

  const setFavouriteHandler = (equity) => {
    let updatedState = { ...dailyHit };

    let feeds = updatedState.feeds;
    let index = feeds.findIndex((feed) =>
      equity.exchange === feed.exchange && equity.ticker === feed.ticker
    );

    let equityToBeUpdated = feeds[index];
    equityToBeUpdated.favourite = !equityToBeUpdated.favourite;

    setDailyHit(updatedState);
  }

  const profileRedirectHandler = (equity) => {
    const queryParams = '?' + encodeURIComponent('exchange') + '=' + encodeURIComponent(equity.exchange)
      + '&' + encodeURIComponent('ticker') + '=' + encodeURIComponent(equity.ticker);

    history.push({
      pathname: '/Equity',
      search: queryParams,
    });
  }

  const bull = <span>•</span>;

  const hdata = [
    {
      name: 'BTCUSD',
      highest: 15000,
      current: 12345,
    },
    {
      name: 'ETHUSD',
      highest: 3000,
      current: 1398,
    },
    {
      name: 'RPXUSD',
      highest: 9800,
      current: 1000,
    },
    {
      name: 'JSQAHS',
      highest: 3908,
      current: 1522,
    },
    {
      name: 'RPXUSTE',
      highest: 1.5,
      current: 1.2,
    },
    {
      name: 'FJAHAS',
      highest: 3800,
      current: 1200,
    },
  ];
  
  const ddata = [
    { name: 'Binance', value: 400 },
    { name: 'Ath Coin B', value: 300 },
    { name: 'Ath Coin C', value: 300 },
    { name: 'Ath D', value: 200 },
  ];

  return (
    <StyledExchangeInfo>
      <Container disableGutters maxWidth="lg" >
        <Typo variant="h6" type="title" fontWeight="bold">
          {t('Top Activities')}
        </Typo>

        <BasicCard content={dailyActivity}/>
        
        <Grid container>
          {/* Hidden Away Until Further Enhancement */}
          {/* <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingTop: '5%' }}>
            <Typo variant="h6" type="title" fontWeight="bold">
                {t('Hot Sector')}
            </Typo>
            <DoughnutGraph data={ddata} />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ paddingTop: '5%' }}>
            <Typo variant="h6" type="title" fontWeight="bold">
                {t('Most Searched')}
            </Typo>
            <VerticalBarChart data={hdata}/>
          </Grid> */}
          {/* Hidden End */}
          <Grid item lg={12} xs={12} style={{ paddingTop: '5%' }}>
            <Typo variant="h6" type="title" fontWeight="bold">
                {t('Today Hit')}
            </Typo>
            <BasicTable
              placeholder='Search'
              size='small'
              searchable
              keys={dailyHit.key}
              header={dailyHit.header}
              feeds={dailyHit.feeds}
              paginable
              trxdate='21 Jan 2021 1300'
              pageSize={5}
              onDetail={profileRedirectHandler}
              setFavourite={setFavouriteHandler}
              maxMobileColumn={2}
              switchColumn
            />
          </Grid>
        </Grid>
        
        {/* Hidden Away Until Further Enhancement */}
        {/* <Grid container style={{paddingTop: '5%'}}>
          <Typo variant="h6" type="title" fontWeight="bold">
              {t('Latest News')}
          </Typo>
          <Card>
            <Grid container>
              <Grid item lg={3} md={3} xs={12}>
                <CardMedia
                  src={'https://cdn.vox-cdn.com/thumbor/S3DPqwSdaGw7i-5Y_Co7UzXfJeA=/0x0:4000x2667/920x613/filters:focal(1743x482:2383x1122):format(webp)/cdn.vox-cdn.com/uploads/chorus_image/image/68792738/1183490758.0.jpg'}
                  style={{ padding: '15px' }}
                  component="img"
                  title="Paella dish"
                />
              </Grid>
              <Grid item lg={8} md={8} xs={12}>
                <CardContent>
                  <Typo variant="subtitle2" type="title">
                    Bloomberg  News {bull} February 4, 2021 10.30 AM
                  </Typo>
                  <Typo variant="h6" type="title" fontWeight="bold">
                    Family Setup on Apple Watch is coming to Singapore
                  </Typo>
                  <Typo variant="subtitle1" type="title">
                    Family Setup on Apple Watch SE and Apple Watch Series 4 or later will be available in Singapore on Tuesday, February 9, with iOS 14.3 and watchOS 7.2, bringing the communication, health, fitness, and safety features of Apple Watch to kids and older adults in the household who do not have iPhone.                    
                  </Typo>
                </CardContent>
              </Grid>
              <Grid item lg={1} md={1} xs={12}>
                <CardActions>
                  <IconButton size="large">
                    <BookmarkBorderIcon />
                  </IconButton>
                  <IconButton aria-label="share" onClick={handleOpenShare} size="large">
                    <ShareIcon />
                  </IconButton>
                </CardActions>
                <Share
                  open={openShare}
                  onClose={handleCloseShare}
                  url={getCurrentURL()} />
              </Grid>

            </Grid>
          </Card>
        </Grid> */}
        {/* Hidden end */}
      </Container>
      <div className={classes.hiddenspace} />
    </StyledExchangeInfo>
  );
}

export default Home