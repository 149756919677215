import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import { MultiTabs } from '../components/Tab/SimpleTabs'

import { IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/AddCircleOutlineSharp'
import CloseIcon from '@mui/icons-material/HighlightOffSharp'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import DescriptionIcon from '@mui/icons-material/Description'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

// components
import Content from '../hoc/Content'
import BasicTable from '../components/Table/BasicTable'
import Typo from '../components/Text/Typo'

import FormGenerator from '../helper/form-generator'

// hooks
import { useUserCustomers } from '../hooks/use-user-management'

const PREFIX = 'SalesManagement'

const classes = {
  hiddenspace: `${PREFIX}-hiddenspace`
}

const StyledContent = styled(Content)((
  {
    theme
  }
) => ({
  [`& .${classes.hiddenspace}`]: {
    padding: '2%',
    [theme.breakpoints.down('xl')]: {
      padding: '10%',
    },
  }
}))

export default function SalesManagement(props) {
  const history = useHistory()
  const { t } = useTranslation()

  const userId = useSelector(state => state.auth.user.$id)

  const { data: customers } = useUserCustomers(userId)
  console.log('user-customer', customers)

  const [state, setState] = useState({
    fields: [
      {
        name: 'exchange',
        value: [],
        required: true,
        label: 'Exchange',
        fullWidth: true,
        type: 'select-multi',
        options: [
          {
            displayValue: 'Binance',
            value: 'binance',
          },
          {
            displayValue: 'Binance Future Perpetual',
            value: 'binancefPerp',
          },
          {
            displayValue: 'Binance Future Delivery',
            value: 'binancefDelivery',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'duration',
        value: '',
        required: true,
        label: 'Duration',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: '1 Month',
            value: '1',
          },
          {
            displayValue: '3 Months',
            value: '3',
          },
          {
            displayValue: '9 Months',
            value: '9',
          },
          {
            displayValue: '1 Year',
            value: '12',
          }
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'validFrom',
        disabled: true,
        value: '',
        label: 'Valid From (Auto Populate)', //based on current date dd/mm/yyyy... Example: 1 November
        type: 'date',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'validTill',
        disabled: true,
        value: '',
        label: 'Valid Till (Auto Populate)', //based on current date + selected duration... Example: 1 November + 1 month = 1 December
        type: 'date',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'firstname',
        value: '',
        required: true,
        label: 'First Name',
        fullWidth: true,
        autoFocus: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'lastname',
        value: '',
        required: true,
        label: 'Last Name',
        fullWidth: true,
        autoFocus: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'email',
        value: '',
        required: true,
        label: 'Email Address',
        fullWidth: true,
      },
      {
        name: 'phone',
        value: '',
        required: true,
        label: 'Phone Number',
        fullWidth: true,
      },
      {
        name: 'action',
        value: '',
        required: true,
        label: 'Action',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: 'Request Reset Password',
            value: 'resetpassword',
          },
          {
            displayValue: 'Cancel Subscription',
            value: 'cancel',
          },
          {
            displayValue: 'Activate Subscription',
            value: 'unsubscribe',
          },
          {
            displayValue: 'Update Record',
            value: 'update',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'role',
        value: '',
        required: true,
        label: 'Role Type',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: 'Customer',
            value: 'customer',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
    ],
  })

  const handleChange = (event) => {
    const { name, value } = event.target

    let newState = { ...state }

    newState.fields
      .find((field) => field.name === name)
      .value = value

    setState(newState)
  }

  const handleMultiChange = (event) => {
    const { name, value } = event.target

    let newState = { ...state }

    newState.fields
      .find((field) => field.name === name)
      .value = value === 'string' ? value.split(',') : value

    setState(newState)
  }

  const [pendingjob, setPendingjob] = useState({
    key: ['first', 'last', 'email'],
    header: [
      {
        headerName: 'First Name',
        field: 'first',
      },
      {
        headerName: 'Last Name',
        field: 'last',
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone No.',
        field: 'phone',
      },
      {
        headerName: 'Exchange',
        field: 'exchange',
      },
      {
        headerName: 'Subscription',
        field: 'subscription',
      },
      {
        headerName: 'Duration',
        field: 'duration',
      },
    ],
    feeds: [
      {
        first: 'Yong',
        last: 'Tan Xing',
        email: 'yongtanxing@gmail.com',
        phone: '+65918221',
        exchange: 'USA',
        subscription: 'Pro',
        duration: '3 months',
      },
      {
        first: 'Micheal',
        last: 'Yong',
        email: 'yongM@gmail.com',
        phone: '+65918221',
        exchange: 'MYX',
        subscription: 'Pro',
        duration: '3 months',
      },
    ],
  })

  const [myCustomer, setMyCustomer] = useState({
    key: ['first', 'last', 'email'],
    header: [
      {
        headerName: 'First Name',
        field: 'first',
      },
      {
        headerName: 'Last Name',
        field: 'last',
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone No.',
        field: 'phone',
      },
      {
        headerName: 'Exchange',
        field: 'exchange',
      },
      {
        headerName: 'Subscription',
        field: 'subscription',
      },
      {
        headerName: 'Duration',
        field: 'duration',
      },
      {
        headerName: 'Status',
        field: 'status',
      },
    ],
    feeds: [
      {
        status: 'Active',
        first: 'Calvin',
        last: 'Yaw',
        email: 'Cyaw@gmail.com',
        phone: '+65912122',
        exchange: 'US',
        subscription: 'Premium',
        duration: '3 months',
      },
      {
        status: 'Expired',
        first: 'James',
        last: 'Tna',
        email: 'Tna@gmail.com',
        phone: '+65912122',
        exchange: 'US',
        subscription: 'Premium',
        duration: '3 months',
      },
    ],
  })

  const [cancelled, setCancelled] = useState({
    key: ['first', 'last', 'email'],
    header: [
      {
        headerName: 'First Name',
        field: 'first',
      },
      {
        headerName: 'Last Name',
        field: 'last',
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone No.',
        field: 'phone',
      },
      {
        headerName: 'Exchange',
        field: 'exchange',
      },
      {
        headerName: 'Subscription',
        field: 'subscription',
      },
      {
        headerName: 'Duration',
        field: 'duration',
      },
    ],
    feeds: [
      {
        first: 'Tan',
        last: 'Jue JE',
        email: 'TJJ@gmail.com',
        phone: '+65912122',
        exchange: 'MY',
        subscription: 'Premium',
        duration: '3 months',
      },
    ],
  })

  const [myKey, setMyKey] = useState({
    key: ['key', 'name'],
    header: [
      {
        headerName: '',
        field: 'key',
        render: (dataRow) =>
          <VpnKeyIcon color="primary" />,
      },
      {
        headerName: '',
        field: 'receipt',
        render: (dataRow) =>
          <DescriptionIcon color="primary" />,
      },
      {
        headerName: 'Subscription',
        field: 'subscription',
      },
      {
        headerName: 'Duration',
        field: 'duration',
      },
      {
        headerName: 'Purchaser Name',
        field: 'name',
      },
      {
        headerName: 'Contact No',
        field: 'contact',
      },
      {
        headerName: 'Email',
        field: 'email',
      },
    ],
    feeds: [
      {
        duration: '1 Year',
        subscription: 'Premium',
        name: 'Yang Jang',
        contact: '+698182921',
        email: 'yangjang@gmail.com',
      },
    ],
  })

  const content = [
    {
      // Inclusive of all incoming jobs from sign up. 
      // Action buttons: "Lock Job", "Close job", and "Cancel Job" 
      // allow upload of payment evidance at google drive
      // If customer cancelled the job and wanted to reapply. -> they have to reapply via portal.

      label: 'Pending Job',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='medium'
            searchable
            sortable
            paginable
            pageSize={7}
            keys={pendingjob.key}
            header={pendingjob.header}
            feeds={pendingjob.feeds}
            editable
            editOptions={{
              add: false,
              edit: {
                modal: {
                  title: 'Edit Customer Info',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                }
              }
            }}
          />
        </Grid>,
    },
    {
      // Inclusive of all incoming jobs from sign up. 
      // Action buttons: "Lock Job", "Close job", and "Cancel Job" 
      // allow upload of payment evidance at google drive
      // If customer cancelled the job and wanted to reapply. -> they have to reapply via portal.

      label: 'My Customers',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='medium'
            searchable
            sortable
            paginable
            pageSize={7}
            keys={myCustomer.key}
            header={myCustomer.header}
            feeds={myCustomer.feeds}
            editable
            editOptions={{
              add: false,
              edit: {
                modal: {
                  title: 'Edit Customer Info',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                }
              }
            }}
          />
        </Grid>,
    },
    {
      // Inclusive of all incoming jobs from sign up. 
      // Action buttons: "Lock Job", "Close job", and "Cancel Job" 
      // allow upload of payment evidance at google drive
      // If customer cancelled the job and wanted to reapply. -> they have to reapply via portal.

      label: 'Cancelled Job',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='medium'
            searchable
            sortable
            paginable
            pageSize={7}
            keys={cancelled.key}
            header={cancelled.header}
            feeds={cancelled.feeds}
            editable
            editOptions={{
              add: false,
              edit: {
                modal: {
                  title: 'Edit Customer Info',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                }
              }
            }}
          />
        </Grid>,
    },
    // {
    //   // Batch generation of activation key
    //   // Sending of licences and receipt to purchaser email address
    //   // Registration with activation key can bypass the payment stage
    //   // Salesman can download the receipt for customer

    //   label: 'Key Activation',
    //   render:
    //     <Grid
    //       container
    //       item
    //       lg={12}
    //       xs={12}
    //       style={{ paddingBottom: '1%' }}
    //     >
    //       <BasicTable
    //         placeholder='Search'
    //         size='medium'
    //         searchable
    //         sortable
    //         paginable
    //         pageSize={10}
    //         keys={myKey.key}
    //         header={myKey.header}
    //         feeds={myKey.feeds}
    //         editable
    //         editOptions={{
    //           add: {
    //             modal: {
    //               title: 'Activation Key Generation',
    //               content:
    //                 <form noValidate>
    //                   <Grid container spacing={2}>
    //                     <FormGenerator fields={state.fields} onChange={handleChange} />
    //                   </Grid>
    //                 </form>,
    //             }
    //           },
    //           edit: false
    //         }}
    //       />
    //     </Grid>,
    // },
  ]

  return (
    <Content>
      <Container disableGutters maxWidth="lg" style={{ paddingBottom: '1%', paddingTop: '5%' }}>
        <Typo variant="h6" type="title" fontWeight="bold">
          {t('Sales Management')}
        </Typo>
        <MultiTabs content={content} />
      </Container>
      <div className={classes.hiddenspace} />
    </Content>
  )
}