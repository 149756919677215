import { useState } from "react";
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'

import {
  Grid,
  Tabs, Tab,
  Button,
  Snackbar,
  Slide,
  Container,
  Card, CardHeader, CardContent,
  Avatar,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import Lottie from 'react-lottie';
import AnimInterface from '../components/LottieAnim/rocket.json';
import AnimNotification from '../components/LottieAnim/scrolling.json';
import AnimRelaxing from '../components/LottieAnim/task.json';
import AnimStudy from '../components/LottieAnim/idea.json';
import AnimJoinUs from '../components/LottieAnim/joinus.json';
import AnimBackground from '../components/LottieAnim/header.json';
import AnimGroupChat from '../components/LottieAnim/groupchat.json';
import PriceCard from '../components/Card/PriceCard';
import Footer from '../views/Footer';
import Typo from "../components/Text/Typo";
import bg2 from '../../src/textures/bg2.png';

const PREFIX = 'Main';

const classes = {
  content: `${PREFIX}-content`,
  intro: `${PREFIX}-intro`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.content}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  [`& .${classes.intro}`]: {
    backgroundColor:theme.palette.primary.background,
    backgroundImage: `url(${bg2})`,
    backgroundRepeat: 'repeat',
    height: '100%',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Typo variant="body1" type="title">{children}</Typo>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function MainBody(props) {

  const history = useHistory()
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const [value, setValue] = useState(2);
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const [trialPrice, setTrialPrice] = useState([
    {},
    {
      key: ['pricing', 'list'],
      header: {
        lg: 12,
        md: 12,
        sm: 12,
        xs: 12,
        plan: t('Trial'),
        title: 'Get Starter For Free',
        prices: 0,
        buttonTitle: 'Get Started',
        buttonRedirect: '/SignUp',
      },
      body: [
        'Limited Daily Top Picks',
        'Acessible To 1 Exchange Only',
        'No Access To Historical Picks',
        'Limited To 3 Favourite Pick Only',
        'Limited To 3 Notification Alert Only',
        'Might Have Ads',
        'Accessible To Telegram Group',
      ]
    },
    {}
  ]);

  const [price, setPrice] = useState([
    {
      key: ['pricing', 'list'],
      header: {
        lg: 4,
        md: 4,
        sm: 12,
        xs: 12,
        plan: t('Starter'),
        title: 'For New Investor',
        prices: 150,
        buttonTitle: 'Get Started',
        buttonRedirect: '/SignUp',
      },
      body: [
        'All features included with Free plan',
        'Stock Lookup',
        'Stock Balance Sheet',
        'Stock Profile',
        'Stock Fundamentals',
        'Corporate Events',
        'Market Sector Performance',
        'Ads Free',
      ]
    },
    {
      key: ['pricing', 'list'],
      header: {
        lg: 4,
        md: 4,
        sm: 12,
        xs: 12,
        plan: t('Pro'),
        title: 'For Smart Trader',
        prices: 200,
        buttonTitle: 'Get Started',
        buttonRedirect: '/SignUp',
      },
      body: [
        'All features included with Starter plan',
        'Unlimited Daily Top Picks',
        'Access To Earning Calander',
        'Ability To Favourite Pick',
        'Receive Notification For Favourite Pick',
        'Full Access To Historical Picks',
        'Whisper Notification Email',
        'Email Support',
      ]
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    (<Root>
      <div className={classes.intro}>
        <Grid container style={{ padding: 30 }}>
          <Grid item lg={5} md={5} sm={12} xs={12}>
            <Lottie
              options={{
                loop: false,
                autoplay: true,
                animationData: AnimBackground,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
            />
          </Grid>
          <Grid item lg={7} md={7} sm={12} xs={12} className={classes.content}>
            <Typo variant="h3" type="title" fontWeight="bold" >{t('Removed the noise. Focus on what matters.')}</Typo>
            <Typo variant="h6" type="title" >{t('Take the complication out of trading with Whisper.')}</Typo>
            <Grid item lg={2} md={2} xs={4} style={{paddingTop:'5%'}}>
              <Button variant="contained" onClick={() => history.push('/SignUp')}>
                Sign Up
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Container disableGutters maxWidth="lg" style={{ paddingTop: '5%' }}>
        <div id="aboutJump">
          <Typo variant="h3" type="title" fontWeight="bold">
            {t('Powerful Simplicity')}
          </Typo>

          <Typo variant="h6" type="title">
          {t('Intelligently adapts to any device. Tables are carefully engineered for best interactivity, both for powerful desktops with a mouse, and touch-optimized for tablets and phones.')}
          </Typo>
          <Grid container>
            {/* 1 */}
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AnimInterface,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5%' }}>
              <Typo variant="h3" type="title" fontWeight="bold">{t('Simple on the surface, intelligent underneath.')}:{t('Simple on the surface, intelligent underneath.')}</Typo>
              <Typo variant="h6" type="title">{t('Our proprietary algorithm is connected to private data provider that provide the most accurate data.')}</Typo>
            </Grid>
            {/* 2 */}
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AnimNotification,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5%' }}>
              <Typo variant="h3" type="title" fontWeight="bold">{t('Access to Whisper, any time.')}</Typo>
              <Typo variant="h6" type="title">{t('Whisper works on the biggest market such as Cryptocurrency Exchange.')}</Typo>
            </Grid>
            {/* 3 */}
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AnimRelaxing,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5%' }}>
              <Typo variant="h3" type="title" fontWeight="bold">{t('Understand hidden divergences')}</Typo>
              <Typo variant="h6" type="title">{t('Additional features such as Whisper favourites help you remember your favourite ticker for future references')}</Typo>
            </Grid>
            {/* 4 */}
            <Grid item lg={5} md={5} sm={5} xs={12}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AnimStudy,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5%' }}>
              <Typo variant="h3" type="title" fontWeight="bold">{t('Never miss out on trending tickers again')}</Typo>
              <Typo variant="h6" type="title">{t('Get access to automated daily top searched within our website.')}</Typo>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container disableGutters maxWidth="lg">
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5%' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typo variant="h6" type="title">{t('Whisper make simple')}</Typo>
              <Typo variant="h3" type="title">{t('Join our free private discussion group by signing up!')}</Typo>
                <div  style={{paddingTop:'5%'}}>
                  <Button variant="contained" onClick={() => history.push('/SignUp')}>
                    Sign UP
                  </Button>
                </div>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <Lottie
              maxHeight="100%"
              maxWidth="100%"
              options={{
                loop: true,
                autoplay: true,
                animationData: AnimGroupChat,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice'
                }
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container disableGutters maxWidth="lg" style={{ paddingTop: '5%' }}>

        <div id="testimonialJump" style={{ paddingBottom: 30 }}>
          <Typo variant="h3" type="title" fontWeight="bold">
          {t('What our customer say')}
          </Typo>

          <Grid container style={{ paddingTop: 10, paddingBottom: 10 }}>
            {/* 1 */}
            <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: '1%' }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar src="../img/testimonials/01.jpg" />
                  }
                  title={t('Anonymous')}
                />
                <CardContent>
                  <Typo variant="body2" type="title">
                  {t('I have been investing for about 15 years, mainly long term, blue chip dividends, etc. But I wanted a little extra edge/confirmation with my day trades. This took away all the extra noise and helped me confirm my picks.')}
                  </Typo>
                </CardContent>
              </Card>
            </Grid>
            {/* 2 */}
            <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: '1%' }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar src="../img/testimonials/03.jpg" />
                  }
                  title={t('Anonymous')}
                />
                <CardContent>
                  <Typo variant="body2" type="title">
                  {t('Whisper is amazing. I am very much an inexperienced trader (3months) and up to a week ago have been following signals from other traders. Whisper is not just update daily tickers but what it does do is give you an idea of how the market is trending.')}
                  </Typo>
                </CardContent>
              </Card>
            </Grid>
            {/* 3 */}
            <Grid item lg={4} md={4} sm={4} xs={12} style={{ padding: '1%' }}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar src="../img/testimonials/02.jpg" />
                  }
                  title={t('Anonymous')}
                />
                <CardContent>
                  <Typo variant="body2" type="title">
                  {t('Whisper telegram community is great. It gives me the opportunity to meet and discuss my trading strategy with other expert.')}
                  </Typo>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        
        <div id="pricingJump" style={{ paddingBottom: 30 }}>
          <Grid container>
            <Grid item lg={4} md={4} sm={4} xs={12}>
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: AnimJoinUs,
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </Grid>
            <Grid item lg={8} md={8} sm={8} xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5%' }}>
              <Typo variant="h4" type="title" fontWeight="bold">
              {t('Start Your Journey Today')}
              </Typo>
              <Typo variant="h6" type="title">
              {t('Be a part of a group of community who use whisper to view the market trend.')}
              </Typo>
              <Grid item lg={2} md={2} xs={4} style={{ paddingTop: 10 }}>
                <Button variant="contained" onClick={() => history.push('/SignUp')}>
                  Join Now
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div>
          <Slide direction="left">
            <Snackbar
              open={true}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              autoHideDuration={6000}
              message={<Typo variant="body1" type="title">This website uses cookies to ensure you get the best experience on our website. <a href="/privacy-policy">Learn More</a></Typo>}
              action={<Button size="small"> Accept </Button>}
            // onClose={handleClose}
            />
          </Slide>
        </div>
      </Container>
      <Footer />
    </Root>)
  );
}
