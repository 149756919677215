import { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

import { Container, Button } from '@mui/material'

const PREFIX = 'UserAgreement';

const classes = {
    root: `${PREFIX}-root`,
    headingText: `${PREFIX}-headingText`,
    button: `${PREFIX}-button`
};

const StyledContainer = styled(Container)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        paddingTop: '32px !important',
        paddingBottom: '80px !important'
    },

    [`& .${classes.headingText}`]: {
        marginTop: 0,
        marginBottom: '1px',
        fontWeight: 600,
        fontSize: '2rem'
    },

    [`& .${classes.button}`]: {
        marginTop: theme.spacing(2),
        justifyContent: 'flex-start',
        fontSize: '1rem',
        fontWeight: 600
    }
}));

const UserAgreement = () => {

    const { t } = useTranslation()

    return (
        <StyledContainer className={classes.root}>
            <h2 className={classes.headingText}>{t('User Agreements')}</h2>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                // target="_blank" href={PrivacyStatementHtml}
            >
                {t('Terms of Use')}
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                // target="_blank" href={PrivacyStatementHtml}
            >
                {t('Disclaimer')}
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                // target="_blank" href={PrivacyStatementHtml}
            >
                {t('Cookie Policy')}
            </Button>
            <Button
                className={classes.button}
                variant="outlined"
                fullWidth
                // target="_blank" href={PrivacyStatementHtml}
            >
                {t('Privacy Policy')}
            </Button>

        </StyledContainer>
    );
}

export default UserAgreement;