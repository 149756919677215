import { useState } from "react"

// components
import AppBar from '../components/AppBar/PrimaryAppBar';
import AppDrawer from '../components/Drawer/AppDrawer';
import Footer from '../components/Footer/Footer';
import BasicBottomNavigation from '../components/BottomNavigation/BasicBottomNavigation';

const Layout = ({ children }) => {
  const [uiState, setUIState] = useState({
    showSideDrawer: false,
    botNavValue: 0,
    anchorEl: null,
    mobileMoreAnchorEl: null
  })
  const [notifications, setNotifications] = useState([
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Breaking News',
      content: 'NNOX gap up 50% a day!',
      provider: 'Nasdaq news',
      imgurl: '/',
      dot: true,
      read: false,
    },
    {
      title: 'Whisper',
      content: 'Your subscription is going to expired in 1 week',
      provider: 'Whisper',
      imgurl: '/',
      dot: false,
      read: true,
    },
  ])

  const toggleDrawer = (show) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    
    setUIState(prevState => ({ ...prevState, showSideDrawer: show }))
  }
  const handleBotNavChg = (newValue) => setUIState({ ...uiState, botNavValue: newValue })

  return (
    <>
      <AppBar
        anchorEl={uiState.enchorEl}
        mobileMoreAnchorEl={uiState.mobileMoreAnchorEl}
        openDrawer={toggleDrawer}
        onHome={() => handleBotNavChg(0)}
        notifications={notifications}
      />
      <AppDrawer
        show={uiState.showSideDrawer}
        toggle={toggleDrawer}
      />
      <main style={{ paddingTop: 56 }}>
        {children}
      </main>
    </>
  )
}

export default Layout