import React, { useContext } from 'react';
import { ThemeContext } from '../../context/theme-context';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const VerticalBarChart = (props) => {

    const themeContext = useContext(ThemeContext)
    const text = themeContext.theme.palette.text.primary

    return (
        <ResponsiveContainer width={props.width?parseInt(props.width):"100%"} height={props.height?parseInt(props.height):300}>
            <BarChart
                width={500}
                height={300}
                data={props.data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" stroke={text}/>
            <YAxis stroke={text}/>
            <Tooltip />
            <Legend />
            <Bar dataKey="current" stackId="a" fill="#0088FE" />
            <Bar dataKey="highest" stackId="a" fill="#FF8042" />
            </BarChart>
      </ResponsiveContainer>
    )
}

export default VerticalBarChart;

export function HorizontalBarChart(props){

    const themeContext = useContext(ThemeContext)
    const text = themeContext.theme.palette.text.primary
    const background = themeContext.theme.palette.background.default

    const renderTooltip = (props) => {
        if (props && props.payload[0]) {
            return (
            <div style={{padding: "5px", background: background, color: text, fontSize: "12px"}}>
                <div>{props.payload[0].payload.name}</div>
                <div>Highest: {props.payload[0].payload.highest}</div>
                <div>Current: {props.payload[0].payload.current}</div>
            </div>
            )
        }
    }

    return (
        <ResponsiveContainer width={props.width?parseInt(props.width):"100%"} height={props.height?parseInt(props.height):50}>
            <BarChart
                width={500}
                height={300}
                data={props.data}
                layout="vertical"
                margin={{
                    top: 0, right: 50, left: 0, bottom: 0
                }}
            >
            <XAxis type="number" hide />
            <YAxis type="category" dataKey="name" stroke={text} />
            <Tooltip content={renderTooltip}/>
            <Bar dataKey="current" stackId="a" fill="#0088FE" />
            <Bar dataKey="highest" stackId="a" fill="#FF8042" />
            </BarChart>
      </ResponsiveContainer>
    )
}


