import { useState } from 'react';
import { useTranslation } from 'react-i18next'

// components
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import Typo from '../Text/Typo';

export default function SimpleTabs(props) {
  const [value, setValue] = useState(0);

  const StyledTabs = (Tabs);

  const handleChange = (event, newValue) => {
    props.switch(newValue);
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg">
      <StyledTabs
        value={value}
        onChange={handleChange}
      >
        {props.children}
      </StyledTabs>
    </Container>
  );
}

const MultiTabs = (props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => setValue(newValue);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {props.content.map(content =>
          <Tab key={content.label} label={
            <Typo variant="subtitle2" type="title">
              {t(content.label)}
            </Typo>
          } />
        )}
      </Tabs>
      <Divider />
      {props.content.map((content, index) =>
        <>
          {value === index &&
            content.render
          }
        </>
      )
      }
    </>
  );
}

export { MultiTabs };