import Typography from '@mui/material/Typography';
import numericColorFormatter from '../../formatter/numeric-formatter';

export default function Typo(props) {

    switch (props.type) {
        case 'title':
            return(
                <Typography variant={props.variant} color="text.primary"
                    style={{
                        fontWeight: props.fontWeight, 
                        fontSize: props.fontSize
                        }} 
                >
                    {props.children !== null ? props.children : null}
                </Typography>
            );
        case 'error':
            return(
                <Typography variant={props.variant}  
                    style={{
                            fontWeight: props.fontWeight, 
                            fontSize: props.fontSize,
                        }} 
                >
                    {props.children !== null ? props.children : null}
                </Typography>
            );
        case 'title-inverted':
            return(
                <Typography variant={props.variant}  
                    style={{
                            fontWeight: props.fontWeight, 
                            fontSize: props.fontSize,
                            alignItems: 'center',
                        }} 
                >
                    {props.children !== null ? props.children : null}
                </Typography>
            );
        case 'numeric':
            return (
                <Typography variant={props.variant}  
                    style={{
                            fontWeight: props.fontWeight, 
                            color: numericColorFormatter(props.children)
                        }}
                >
                    {props.children !== null ? props.children : null}
                </Typography>
            );
        default:
            return null;
    }

    // return (
    //     props.type == "title" ?
    //         <Typography variant={props.variant} color="text.primary"
    //             style={{
    //                 fontWeight: props.fontWeight, 
    //                 fontSize: props.fontSize
    //             }} 
    //         >
    //             {props.children !== null ? props.children : null}
    //         </Typography>
    //     : props.type == "error" ?
    //     <Typography variant={props.variant}  
    //         style={{
    //                 fontWeight: props.fontWeight, 
    //                 fontSize: props.fontSize,
    //             }} 
    //     >
    //         {props.children !== null ? props.children : null}
    //     </Typography>
    //     : props.type == "title-inverted" ?
    //         <Typography variant={props.variant}  
    //             style={{
    //                     fontWeight: props.fontWeight, 
    //                     fontSize: props.fontSize,
    //                     alignItems: 'center',
    //                 }} 
    //         >
    //             {props.children !== null ? props.children : null}
    //         </Typography>
    //     : props.type == "numeric" ?
    //         <Typography variant={props.variant}  
    //             style={{
    //                 fontWeight: props.fontWeight, 
    //                 color: numericColorFormatter(props.children)
    //             }}
    //         >
    //             {props.children !== null ? props.children : null}
    //         </Typography>
    //     : null
    // )
}