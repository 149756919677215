import { configureStore } from '@reduxjs/toolkit'

import uiReducer from './uiSlice'
import authReducer from './authSlice'
import signupReducer from './signupSlice'

export default configureStore({
    reducer: {
        ui: uiReducer,
        auth: authReducer,
        signup: signupReducer,
    },
    // devTools: false // TODO: uncomment this line to disable Redux DevTool
})