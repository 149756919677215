import { useEffect, useRef, useContext } from "react";
import { ThemeContext } from '../../context/theme-context';

const Profile = (props) => {

    const _ref = useRef();
    const themeContext = useContext(ThemeContext)
    const mode = themeContext.isDarkMode !== true ? 'light': 'dark'

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js'
        script.async = true;
        script.innerHTML = JSON.stringify({
            symbol: props.exchange + ':' + props.ticker,
            width: "100%",
            height: "600",
            colorTheme: mode,
            isTransparent: props.transparent,
            locale: "en"
        });

        _ref.current.appendChild(script);
    }, [])

        return (
            <div className="tradingview-widget-container" ref={_ref}>
                <div className="tradingview-widget-container__widget"></div>
            </div>
        );
}

export default Profile