import { FMPAxios } from './axios'

// @GET Ticker
// @query symbol
// @respond [{}]
async function getTickerBySymbol(symbol) {
    try {
        const response = await FMPAxios.get('/api/v3/search', {
            params: {
                query: symbol.toString(),
                limit: 20,
                apikey: 'demo'
            }
        })

        if (response.status === 200) return { data: response.data }
    } catch (error) {
        console.log('getTickerBySymbol catch: ', error)
        return { error: error }
    }
}

export {
    getTickerBySymbol
}