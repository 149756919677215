import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'

// hoc
import Content from '../../hoc/Content';

// components
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';

// icons
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import IconButton from '@mui/material/IconButton';
import BasicButton from '../Button/BasicButton';

const PREFIX = 'SearchBar';

const classes = {
    search: `${PREFIX}-search`,
    searchFullWidth: `${PREFIX}-searchFullWidth`,
    inputRootWithUnderline: `${PREFIX}-inputRootWithUnderline`,
    inputRoot: `${PREFIX}-inputRoot`,
    inputInput: `${PREFIX}-inputInput`,
    inputAdornment: `${PREFIX}-inputAdornment`
};

const StyledContent = styled(Content)((
    {
        theme
    }
) => ({
    [`& .${classes.search}`]: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
    },

    [`& .${classes.searchFullWidth}`]: {
        position: 'relative',
    },

    [`& .${classes.inputAdornment}`]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

export default function SearchBar(props) {

    const { t } = useTranslation()

    const [expand, setExpand] = useState(false);

    return (
        <StyledContent>
            {
                ((props.collapsible && !expand)) ?
                    <BasicButton
                        type="iconbutton"
                        edge='start'
                        onClick={() => props.fullWidth ? props.onSearch() : setExpand(true)}
                        icon={<SearchIcon sx={{color:"text.primary"}}/> }
                    />
                    : <div className={props.fullWidth ? classes.searchFullWidth : classes.search} style={{ textAlign: props.center ? 'center' : null }}>
                        <InputBase
                            placeholder={t(props.placeholder)}
                            color="text.primary"
                            inputProps={{ 'aria-label': 'search' }}
                            startAdornment={
                                <InputAdornment position='start'>
                                    {((props.collapsible && expand) || props.fullWidth)
                                        ?  <BasicButton
                                                type="iconbutton"
                                                edge='start'
                                                onClick={() => props.fullWidth ? props.onBack() : setExpand(false)} 
                                                icon={<ArrowBackIcon sx={{color:"text.primary"}}/> }
                                            />
                                        : 
                                        <SearchIcon sx={{color:"text.primary"}} />
                                    }
                                </InputAdornment>
                            }
                            endAdornment={
                                props.value !== '' ?
                                    <InputAdornment position='end'>
                                        <BasicButton
                                            type="iconbutton"
                                            edge='end'
                                            onClick={props.clear}
                                            icon={<CloseIcon sx={{color:"text.primary"}}/> }
                                        />
                                    </InputAdornment>
                                    : null
                            }
                            onChange={props.change}
                            value={props.value}
                        />
                    </div>
            }
        </StyledContent>
    );
}