// components
import Badge from '@mui/material/Badge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';

// icons
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';

// helpers
import Iterator from '../helper/iterator';
import { Box } from '@mui/material';

export default function Notification(props) {

    return (
        <Badge badgeContent={props.count} color="warning">
            <NotificationsIcon sx={{color:"text.primary"}} />
        </Badge>
    );
}

export function NotificationContent(props) {
    return (
      <Box open={props.open} anchorEl={props.anchorEl} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper style={{height: 400,  maxHeight: 350, overflow: 'auto',position:'static' }}>
              <ClickAwayListener onClickAway={props.onClose}>
                  <Iterator 
                      listItems={props.notifications}
                      wrapper={(content) => <List>{content}</List>}
                      content={(listItem, index) => (
                          <ListItem key={index} alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                              </ListItemAvatar>
                              <ListItemText
                                primary={listItem.title}
                                secondary={listItem.content}
                              />
                          </ListItem>
                      )}
                  />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Box>
    );
}