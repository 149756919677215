import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'

// components
import BasicTable from '../components/Table/BasicTable'
import { MultiTabs } from '../components/Tab/SimpleTabs'
import Typo from '../components/Text/Typo'
import UserManagementTabs from '../features/user-management/UserManagementTabs'

// hooks
import { useAdmin_listCustomer } from '../hooks/use-user-management'

import FormGenerator from '../helper/form-generator'

export default function UserManagement(props) {
  const { t } = useTranslation()
  const history = useHistory()

  // const userId = useSelector(state => state.auth.user.$id)

  const { data: customers } = useAdmin_listCustomer()
  console.log('customers', customers)

  const [state, setState] = useState({
    fields: [
      {
        name: 'exchange',
        value: [],
        required: true,
        label: 'Exchange',
        fullWidth: true,
        type: 'select-multi',
        options: [
          {
            displayValue: 'Binance',
            value: 'binance',
          },
          {
            displayValue: 'Binance Future Perpetual',
            value: 'binancefPerp',
          },
          {
            displayValue: 'Binance Future Delivery',
            value: 'binancefDelivery',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'duration',
        value: '',
        required: true,
        label: 'Duration',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: '1 Month',
            value: '1',
          },
          {
            displayValue: '3 Months',
            value: '3',
          },
          {
            displayValue: '9 Months',
            value: '9',
          },
          {
            displayValue: '1 Year',
            value: '12',
          }
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'validFrom',
        disabled: true,
        value: '',
        label: 'Valid From (Auto Populate)', //based on current date dd/mm/yyyy... Example: 1 November
        type: 'date',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'validTill',
        disabled: true,
        value: '',
        label: 'Valid Till (Auto Populate)', //based on current date + selected duration... Example: 1 November + 1 month = 1 December
        type: 'date',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'fistName',
        value: '',
        required: true,
        label: 'First Name',
        fullWidth: true,
        autoFocus: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'lastName',
        value: '',
        required: true,
        label: 'Last Name',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'email',
        value: '',
        required: true,
        label: 'Email Address',
        fullWidth: true,
      },
      {
        name: 'phone',
        value: '',
        required: true,
        label: 'Phone Number',
        fullWidth: true,
      },
      {
        name: 'password',
        value: '',
        required: true,
        label: 'Password',
        type: 'password',
        fullWidth: true,
      },
      {
        name: 'repeatpassword',
        value: '',
        required: true,
        label: 'Repeat Password',
        type: 'password',
        fullWidth: true,
      },
      {
        name: 'action',
        value: '',
        required: true,
        label: 'Action',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: 'Request Reset Password',
            value: 'resetpassword',
          },
          {
            displayValue: 'Cancel Subscription',
            value: 'cancel',
          },
          {
            displayValue: 'Activate Subscription',
            value: 'unsubscribe',
          },
          {
            displayValue: 'Update Record',
            value: 'update',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'role',
        value: '',
        required: true,
        label: 'Role Type',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: 'Admin',
            value: 'admin',
          },
          {
            displayValue: 'Agent',
            value: 'agent',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
    ],
  })
  const handleChange = (event) => {
    const { name, value } = event.target

    let newState = { ...state }

    newState.fields
      .find((field) => field.name === name)
      .value = value

    setState(newState)
  }

  const handleMultiChange = (event) => {
    const { name, value } = event.target

    let newState = { ...state }

    newState.fields
      .find((field) => field.name === name)
      .value = value === 'string' ? value.split(',') : value

    setState(newState)
  }

  // Customer Dummy Data
  const [customer, setCustomer] = useState({
    key: ['first', 'last', 'email'],
    header: [
      {
        headerName: 'First Name',
        field: 'first',
      },
      {
        headerName: 'Last Name',
        field: 'last',
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone No.',
        field: 'phone',
      },
      {
        headerName: 'Exchange',
        field: 'exchange',
      },
      {
        headerName: 'Duration',
        field: 'duration',
      },
    ],
    feeds: [
      {
        first: 'James',
        last: 'Erika',
        email: 'erika.j@gmail.com',
        phone: '+324822282',
        exchange: 'USA',
        duration: '3 months',
      },
      {
        first: 'Mike',
        last: 'Yong',
        email: 'yongM@gmail.com',
        phone: '+65918221',
        exchange: 'MYX',
        duration: '3 months',
      },
      {
        first: 'Yusof',
        last: 'Yaw',
        email: 'yyyaw@gmail.com',
        phone: '+65912122',
        exchange: 'US',
        duration: '3 months',
      },
      {
        first: 'Irin',
        last: 'June',
        email: 'Irin.June@gmail.com',
        phone: '+65912122',
        exchange: 'US',
        duration: '9 months',
      },
      {
        first: 'Muhammad',
        last: 'Yu',
        email: 'Yu.m@yahoo.com',
        phone: '+60192821',
        exchange: 'MYX',
        duration: '9 months',
      },
    ],
  })
  // Agent Dummy Data
  const [agent, setAgent] = useState({
    key: ['first', 'last', 'email'],
    header: [
      {
        headerName: 'First Name',
        field: 'first',
      },
      {
        headerName: 'Last Name',
        field: 'last',
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone No.',
        field: 'phone',
      },
    ],
    feeds: [
      {
        first: 'Micheal',
        last: 'Yong',
        email: 'yongM@gmail.com',
        phone: '+65918221',
      },
      {
        first: 'Irin',
        last: 'June',
        email: 'Irin.June@gmail.com',
        phone: '+65912122',
      },
    ],
  })
  // Admin Dummy Data
  const [admin, setAdmin] = useState({
    key: ['first', 'last', 'email'],
    header: [
      {
        headerName: 'First Name',
        field: 'first',
      },
      {
        headerName: 'Last Name',
        field: 'last',
      },
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Phone No.',
        field: 'phone',
      },
    ],
    feeds: [
      {
        first: 'Kaline',
        last: 'Jane',
        email: 'Kaline.jane@gmail.com',
        phone: '+60102545854',
      },
      {
        first: 'James',
        last: 'Yong',
        email: 'Jamesyong1234@gmail.com',
        phone: '+60102545854',
      }
    ],
  })

  const generateTabsJSON = [
    {
      // accessible by admin
      // show statistic only
      label: 'Customer List',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            searchable
            sortable
            paginable
            editable
            placeholder='Search'
            size='medium'
            pageSize={7}
            editOptions={{
              add: {
                modal: {
                  title: 'Add Customer',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                },
              },
              edit: {
                modal: {
                  title: 'Edit Customer Info',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                }
              }
            }}
            trxdate='21 Jan 2021 1300'
            keys={customer.key}
            header={customer.header}
            feeds={customer.feeds}
          />
        </Grid>,
    },
    {
      // This module only accessible by admins
      // Admin add/modify/inactive of agents
      label: 'Agent List',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='medium'
            editable
            editOptions={{
              add: {
                modal: {
                  title: 'Add Agent',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                },
              },
              edit: {
                modal: {
                  title: 'Edit Agent Info',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                }
              }
            }}
            searchable
            sortable
            paginable
            trxdate='21 Jan 2021 1300'
            pageSize={7}
            keys={agent.key}
            header={agent.header}
            feeds={agent.feeds}
          />
        </Grid>,
    },
    {
      // This module only accessible by admins
      // Allow change of password and validity for other admins
      label: 'Admin List',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='medium'
            editable
            editOptions={{
              add: {
                modal: {
                  title: 'Add Admin',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                },
              },
              edit: {
                modal: {
                  title: 'Edit Admin Info',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={state.fields} onChange={handleChange} onMultiChange={handleMultiChange} />
                      </Grid>
                    </form>,
                }
              }
            }}
            searchable
            sortable
            paginable
            trxdate='21 Jan 2021 1300'
            pageSize={7}
            keys={admin.key}
            header={admin.header}
            feeds={admin.feeds}
          />
        </Grid>,
    }
  ]

  const profileRedirectHandler = (equity) => {
    const queryParams = '?' + encodeURIComponent('exchange') + '=' + encodeURIComponent(equity.exchange)
      + '&' + encodeURIComponent('ticker') + '=' + encodeURIComponent(equity.ticker)

    history.push({
      pathname: '/Equity',
      search: queryParams,
    })
  }

  // useEffect(() => {
  //   async function getUserCustomer() {
  //     console.log('getUserCustomer')
  //     const customer = await api.listAgentCustomers(userId)
  //     console.log('customer', customer)
  //   }

  //   console.log('userId', userId)
  //   if (userId.length > 0) {
  //     getUserCustomer()
  //   }
  // }, [])

  return (
    <>
      <Container disableGutters maxWidth="lg" style={{ paddingBottom: '1%', paddingTop: '5%' }}>
        <Typo variant="h6" type="title" fontWeight="bold">
          {t('User Management')}
        </Typo>
        <MultiTabs content={generateTabsJSON} />

        {/* <UserManagementTabs /> */}
      </Container>
      <Box sx={(theme) => ({
        padding: '2%',
        [theme.breakpoints.down('xl')]: { padding: '10%' }
      })} />
    </>
  )
}