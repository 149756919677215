import { BrowserRouter } from 'react-router-dom'
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import './i18n'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CssBaseline from '@mui/material/CssBaseline';
import CustomThemeProvider from './context/theme-context';

// redux
import store from './redux/store'
import { Provider } from 'react-redux'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyASKGBjBHIucT5iYk8RNcCB14aA1Ve-ITQ",
  authDomain: "whisper-4170c.firebaseapp.com",
  projectId: "whisper-4170c",
  storageBucket: "whisper-4170c.appspot.com",
  messagingSenderId: "440381907004",
  appId: "1:440381907004:web:f6f7ec92ec297a4b056339",
  measurementId: "G-YKP2MC1492"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <CustomThemeProvider>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </CustomThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
