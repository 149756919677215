import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Grid,
  Container,
  Paper,
  Fab,
} from '@mui/material'
import ShareIcon from '@mui/icons-material/Share';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import Content from '../hoc/Content';
import { MultiTabs } from '../components/Tab/SimpleTabs';

//List
import Profile from '../components/TradingView/Profile';
import Fundamental from '../components/TradingView/Fundamental';
import BasicTable from '../components/Table/BasicTable';

// Float Share
import Share, { PhoneShare, isPhone, getCurrentURL } from '../components/Share'
import useWindowDimensions from '../../src/services/useWindowDimensions';
import TableRow from '../components/Table/TableRow';
import TechnicalChart from '../components/TradingView/TechnicalChart';

const PREFIX = 'Equity';

const classes = {
  hiddenspace: `${PREFIX}-hiddenspace`,
  fab: `${PREFIX}-fab`,
  title: `${PREFIX}-title`,
  subtitle: `${PREFIX}-subtitle`,
  desktop: `${PREFIX}-desktop`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.hiddenspace}`]: {
    padding: '2%',
    [theme.breakpoints.down('xl')]: {
      padding: '10%',
    },
  },

  [`& .${classes.fab}`]: {
    position: 'absolute',
    bottom: '67px',
    right: '58px'
  },

  // [`& .${classes.title}`]: {
  //   color: theme.palette.primary.main,
  // },

  // [`& .${classes.subtitle}`]: {
  //   color: theme.palette.secondary.inverted,
  // },

  [`& .${classes.desktop}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  }
}));

function useURLQuery() {
  return new URLSearchParams(useLocation().search)
}

export default function Highlights(props) {

  const history = useHistory()
  const urlParams = useURLQuery()

  // Share
  const [hideShare, setHideShare] = useState(false)
  const hHideShare_onClick = () => { setHideShare((prev) => !prev); }
  const [openShare, setOpenShare] = useState(false)
  const hFabShare_onClick = () => { isPhone() ? PhoneShare('Equity', 'Check this out!', getCurrentURL()) : setOpenShare(true) }
  const hCloseShare = () => { setOpenShare(false) }

  const [earning, setEarning] = useState(
    {
      key: ['date', 'time'],
      header: [
        {
          headerName: 'Date',
          field: 'date',
        },
        {
          headerName: 'Time',
          field: 'time',
        },
        {
          headerName: 'Estimated EPS',
          field: 'estimateEPS',
          align: 'right',
          numeric: true,
        },
        {
          headerName: 'Actual EPS',
          field: 'actualEPS',
          align: 'right',
          numeric: true,
        },
        {
          headerName: 'Differences',
          field: 'difference',
          align: 'right',
          numeric: true,
        },
        {
          headerName: 'Surprise',
          field: 'surprise',
          align: 'right',
          numeric: true,
        },
      ],
      feeds: [
        {
          date: '2020-04-30',
          time: 'After Hours',
          estimateEPS: 2.09,
          actualEPS: 2.55,
          difference: 0.46,
          surprise: 22.01
        },
        {
          date: '2020-01-28',
          time: 'After Hours',
          estimateEPS: 4.54,
          actualEPS: 4.99,
          difference: 0.45,
          surprise: 9.91
        },
        {
          date: '2019-10-30',
          time: 'After Hours',
          estimateEPS: 2.84,
          actualEPS: 3.03,
          difference: 0.19,
          surprise: 6.69
        },
        {
          date: '2010-07-30',
          time: 'After Hours',
          estimateEPS: 2.10,
          actualEPS: 2.18,
          difference: 0.08,
          surprise: 3.81
        },
        {
          date: '2019-04-30',
          time: 'After Hours',
          estimateEPS: 2.37,
          actualEPS: 2.46,
          difference: 0.09,
          surprise: 3.8
        },
      ]
    }
  );

  const [news, setNews] = useState(
    {
      key: ['exchange', 'ticker'],
      header: [
        {
          headerName: '',
          field: 'bookmark',
          key: false,
          render: dataRow =>
            <BookmarkBorderIcon bookmark={dataRow.bookmark} />,
        },
        {
          headerName: 'Title',
          field: 'title',
          render: dataRow =>
            <TableRow
              title={dataRow.title}
              subtitle={dataRow.provider}
            />
        },
        {
          headerName: 'Publish Date',
          field: 'date',
        },
        {
          headerName: '',
          field: 'share',
          key: false,
          render: dataRow =>
            <ShareIcon share={dataRow.share} />,
        },
      ],
      feeds: [
        {
          bookmark: true,
          title: 'This Is Not 1999',
          provider: 'seekingalpha.com',
          date: '2020-09-08 09:25:00',
          share: true
        },
        {
          bookmark: true,
          title: 'Facebook: 25% Upside As It Climbs To The Trillion Mark',
          provider: 'seekingalpha.com',
          date: '2020-09-08 09:00:00',
          share: true
        },
        {
          bookmark: true,
          title: 'Where Fundamentals Meet Technicals',
          provider: 'seekingalpha.com',
          date: '2020-09-08 09:25:00',
          share: true
        },
      ]
    }
  );

  const { height, width } = useWindowDimensions();
  var newheight = (width < 599) ? (height - 56 - 48) : (height - 64 - 48);
  var newwidth = (width > 1280) ? 1280 : width;
  // console.log(width, newheight)
  const content = [
    {
      label: 'Graph',
      render:
        <Content>
          <Grid container item lg={12} xs={12}>
            <Paper variant="outlined" square style={{ height: newheight, width: newwidth, position: 'fixed' }}>
              <TechnicalChart exchange={'BINANCE'} ticker={'BTCUSDT'} />
            </Paper>
          </Grid>
          <Fab size="small" className={classes.fab} onClick={hFabShare_onClick}>
            <ShareIcon />
          </Fab>
          <Share open={openShare} onClose={hCloseShare} url={getCurrentURL()} imageUrl={getCurrentURL()} />
        </Content>,
    },
    {
      label: 'Profile',
      render:
        <Content>
          <Grid container item lg={12} xs={12}>
            <Paper variant="outlined" square style={{ height: newheight, width: newwidth }}>
              <Profile exchange={"BINANCE"} ticker={"BTCUSDT"} />
            </Paper>
          </Grid>
        </Content>,
    },
    {
      label: 'Fundamental',
      render:
        <Content>
          <Grid container item lg={12} xs={12}>
            <Paper variant="outlined" square style={{ height: newheight, width: newwidth }}>
              <Fundamental exchange={'BINANCE'} ticker={'BTCUSDT'} />
            </Paper>
          </Grid>
        </Content>,
    },
    // Hidden Away Until Further Enhancement
    // {
    //   label: 'News',
    //   render:
    //     <Content>
    //       <Grid container item lg={12} xs={12}>
    //         <Paper variant="outlined" square style={{ height: newheight, width: newwidth }}>
    //           <BasicTable
    //             placeholder='Search'
    //             size='small'
    //             keys={news.key}
    //             header={news.header}
    //             feeds={news.feeds}
    //           />
    //         </Paper>
    //       </Grid>
    //     </Content>,
    // },
    // {
    //   label: 'Earnings',
    //   render:
    //     <Content>
    //       <Grid container item lg={12} xs={12}>
    //         <Paper variant="outlined" square style={{ height: newheight, width: newwidth, position: 'fixed' }}>
    //           <BasicTable
    //             placeholder='Search'
    //             size='small'
    //             keys={earning.key}
    //             header={earning.header}
    //             feeds={earning.feeds}
    //           />
    //         </Paper>
    //       </Grid>
    //     </Content>
    //   ,
    // },
    // Hidden end
  ];

  const profileRedirectHandler = (equity) => {
    const queryParams = '?' + encodeURIComponent('exchange') + '=' + encodeURIComponent(equity.exchange)
      + '&' + encodeURIComponent('ticker') + '=' + encodeURIComponent(equity.ticker);

    history.push({
      pathname: '/Equity',
      search: queryParams,
    });
  }

  return (
    <StyledContainer disableGutters maxWidth="lg">
      {/* <Card className={classes.desktop}>
        <CardContent >
          <h3 className={classes.title} style={{ fontSize: '15px', margin: 0 }}>PLUG INC</h3>
          <h2 className={classes.subtitle} style={{ fontSize: '24px', margin: 0 }}>NASDAQ : PLUG
            <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="add to favorites">
              <AddAlarmIcon />
            </IconButton>
          </h2>
          <p className={classes.subtitle} style={{fontSize: '15px', margin: 0 }}>137.09 USD −4.97 (−3.50%) AS OF 01/28/2021 4:00:00PM </p>
        </CardContent>
      </Card> */}
      <MultiTabs content={content} />
      <div className={classes.hiddenspace} />
    </StyledContainer>
  );
}