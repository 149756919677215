import Axios from 'axios'

const WHISPER_URL = 'https://whisper.com'
const FMP_URL = 'https://financialmodelingprep.com'
const TWELVEDATA_URL = 'https://api.twelvedata.com'

// Axios instance - Whisper
export const WhisperAxios = Axios.create({
    baseURL: WHISPER_URL
})
// Error handler
WhisperAxios.interceptors.response.use((response) => {
    return response
}, (error) => {
    // console.log(error, 'WhisperAxios interceptor - response error')
    throw error;
})

// Axios instance - Financial Modeling Prep (FMP)
export const FMPAxios = Axios.create({
    baseURL: FMP_URL,
})

// Axios instance - Twelve Data
export const TDAxios = Axios.create({
    baseURL: TWELVEDATA_URL,
})