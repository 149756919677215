

export default function dateFormatter(props) {
    const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const day = props.date.getDate();
    const month = shortMonths[props.date.getMonth()];
    const year = props.date.getFullYear();

    return `${day} ${month} ${year}`;
}