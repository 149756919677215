const env = process.env

export const Config = {
    dbEndpoint: env.REACT_APP_DB_ENDPOINT,
    projectId: env.REACT_APP_PROJECTID,

    dbId_webapp: env.REACT_APP_DBID_WEBAPP,

    cId_user: env.REACT_APP_CID_USER,

    fId_registerNewUser: env.REACT_APP_FID_REGISTER_NEW_USER,
    fId_adminListCustomer: env.REACT_APP_FID_ADMIN_LIST_CUSTOMER,
}