import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles';

// hoc
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Typo from '../components/Text/Typo';

const PREFIX = 'FAQ';

const classes = {
    root: `${PREFIX}-root`,
    container: `${PREFIX}-container`,
    content: `${PREFIX}-content`
};

const Root = styled('main')((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        width: '100%',
    },

    [`& .${classes.container}`]: {
        paddingTop: '32px !important',
        paddingBottom: '32px !important',
    },

    [`&.${classes.content}`]: {
        flexGrow: 1
    }
}));

export default function LayoutTextFields() {

    const {t} = useTranslation()

    return (
        <Root className={classes.content}>
            <Container maxWidth="lg" className={classes.container} style={{ paddingBottom: '30px' }}>
                <Typo variant="h6" type="title" fontWeight="bold">
                    {t('Frequently Asked Questions')}
                </Typo>
                <Typo variant="h6" type="title">
                    {t('General')}
                </Typo>

                <div className={classes.root}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typo variant="subtitle2" type="title" fontWeight="bold">
                                How to login and logout
                            </Typo>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typo variant="subtitle2" type="title">
                                Step to login and logout
                            </Typo>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typo variant="subtitle2" type="title" fontWeight="bold">
                                How to reset my account password?
                            </Typo>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typo variant="subtitle2" type="title">
                                Go to www.whisper.com.sg and click login. Next click on the forgot password at the login page.
                                Type in your account email address and click on submit. A password reset email will send to your
                                email. Click on the password reset email link.
                                Subsequently, type in your new password and repeat your new password and click on submit.
                                Your password is now updated, please go back to www.whisper.com.sg to proceed with login.
                            </Typo>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typo variant="subtitle2" type="title" fontWeight="bold">
                                I did not receive my password reset email
                            </Typo>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typo variant="subtitle2" type="title">
                                If you requested a password reset but did not receive a reset email in your inbox, one of the following may have happened:

                                1. The email was caught in your SPAM/Junk filter: Please be sure to check your spam/junk folder.
                                2. If you are using Gmail, you may also want to check the Promotions tab.
                                3. You may also want to add no-reply@whisper.com.sg as a trusted source so our emails do not go into your spam folder in the future.
                            </Typo>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Container>
        </Root>
    );
}