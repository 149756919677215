import { Client, Account, Databases, Query, Functions, Teams } from 'appwrite'
import { Config } from './config'

const client = new Client()
client.setEndpoint(Config.dbEndpoint).setProject(Config.projectId)

const account = new Account(client)
const database = new Databases(client)
const functions = new Functions(client)
const teams = new Teams(client)

const api = {
    // -------------------------------- Account -----------------------------------
    executeRegisterUser: (data) => {
        return functions.createExecution(Config.fId_registerNewUser, JSON.stringify(data))
    },
    createSession: (email, password) => {
        return account.createEmailSession(email, password)
    },
    getAccount: () => {
        return account.get()
    },
    deleteSession: () => {
        return account.deleteSession('current')
    },
    getUserTeam: () => {
        return teams.list()
    },
    getUserMemberships: (teamId) => {
        return teams.listMemberships(teamId)
    },

    // -------------------------------- User Management (Admin) -----------------------------------
    executeAdminListCustomers: (offset = 0) => {
        const data = JSON.stringify({ offset })
        return functions.createExecution(Config.fId_adminListCustomer, data)
    },
    listUsers: (offset = 0) => {
        return database.listDocuments(Config.dbId_webapp, Config.cId_user, [
            Query.limit(100),
            Query.offset(offset * 100)
        ])
    },

    listUserCustomers: (agentId) => {
        return database.listDocuments(Config.dbId_webapp, Config.cId_user, [
            Query.equal('refId', agentId),
            Query.limit(100)
        ])
    },

    // -------------------------------- Sales Management (Agent) -----------------------------------


    // -------------------------------- Agent -----------------------------------

    // updateUser, userExchanges

    // deleteUser, docId = userId
    deleteAgentCustomer: (docId) => {
        return database.updateDocument(Config.dbId_webapp, Config.cId_user, docId, { isDeleted: true })
    },
    // reset password

    // cancel sub, docId = userId
    deactivateAgentCustomerSub: (docId) => {
        return database.updateDocument(Config.dbId_webapp, Config.cId_user, docId, { isActive: false })
    },
    // Q: can user login when isActive = false, on login level?
    // active sub
    activeAgentCustomer: (docId) => {
        return database.updateDocument(Config.dbId_webapp, Config.cId_user, docId, { isActive: true })
    }
}

export default api