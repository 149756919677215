import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';

import Container from '@mui/material/Container';
import ExchangeInfo from '../hoc/Layouts/ExchangeInfo';

// components 
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import BasicTable from '../components/Table/BasicTable';
import SearchBar from '../components/SearchBar/SearchBar';
import Steps from '../components/Steps';
import FormGenerator from '../helper/form-generator';

// hoc
import { MultiTabs } from '../components/Tab/SimpleTabs';
import ShareIcon from '@mui/icons-material/Share';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import FavouriteIcon from '../icon/favourite';
import TableRow from '../components/Table/TableRow';
import LineGraph from '../components/Graph/LineGraph';
import Typo from '../components/Text/Typo';
import { HorizontalBarChart } from '../components/Graph/BarChart';

const PREFIX = 'Favourite';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  hiddenspace: `${PREFIX}-hiddenspace`
};

const StyledExchangeInfo = styled(ExchangeInfo)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  [`& .${classes.container}`]: {
    paddingBottom: '32px !important',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1
  },

  [`& .${classes.hiddenspace}`]: {
    padding: '2%',
    [theme.breakpoints.down('xl')]: {
      padding: '10%',
    },
  }
}));

const Favourite = () => {

  const { t } = useTranslation()
  const history = useHistory();
  
  // Dummy ticker data
  const data = [
    { day: 1, value: 200 },
    { day: 2, value: 500 },
    { day: 3, value: 212 },
    { day: 4, value: 900 },
    { day: 5, value: 300 },
    { day: 6, value: 543 },
    { day: 7, value: 1000 },
  ]

  const [favourite, setFavourite] = useState({
    key: ['exchange', 'ticker'],
    header: [
      {
        headerName: '',
        field: '',
        key: false,
        render: dataRow =>
          <FavouriteIcon favourite={dataRow.favourite} />,
      },
      {
        headerName: 'Name',
        field: 'equity',
        render: dataRow =>
          <TableRow
            title={dataRow.ticker}
            subtitle={dataRow.name}
          />
      },
      {
        headerName: '7 Days Trend',
        field: '',
        render: () =>
          <LineGraph timeframe={data} width={'300'} height={'100'} />
      },
      {
        headerName: 'Price Change',
        field: 'change',
        align: 'right',
        key: false,
        render: dataRow =>
          <HorizontalBarChart data={[{
                                      name: dataRow.ticker,
                                      highest: dataRow.price + dataRow.change,
                                      current: dataRow.price,
                                    }]}
          />
      }
    ],
    feeds: [
      {
        exchange: 'NASDAQ',
        ticker: 'MVIS',
        name: 'MICROVISION INC WASHINGTON',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 5,
        favourite: true,
        timeframe: data,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'CLNE',
        name: 'CLEAN ENERGY FUELS CORPORATION',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 5,
        favourite: true,
        timeframe: data
      },
      {
        exchange: 'NASDAQ',
        ticker: 'VERU',
        name: 'VERU INC',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 3,
        favourite: true,
        timeframe: data,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'MARA',
        name: 'MARATHON PATENT GROUP INC',
        country: 'US',
        price: 45.23,
        change: 1.6,
        changePercentage: 2,
        favourite: true,
        timeframe: data
      },
    ],
  });

  const [news, setNews] = useState({
    key: ['exchange', 'ticker'],
    header: [
      {
        headerName: '',
        field: 'bookmark',
        key: false,
        render: dataRow =>
          <BookmarkBorderIcon bookmark={dataRow.bookmark} />,
      },
      {
        headerName: 'Name',
        field: 'name',
        render: dataRow =>
          <TableRow
            title={dataRow.title}
            subtitle={dataRow.date}
          />
      },
      {
        headerName: '',
        field: 'share',
        key: false,
        render: dataRow =>
          <ShareIcon share={dataRow.share} />,
      },
    ],
    feeds: [
      {
        exchange: 'NASDAQ',
        title: 'GOP pushes bills to allow social media ‘censorship’ lawsuits',
        date: '1/2/2012',
        bookmark: true,
        share: true,
      },
    ],
  });

  const [journal, setJournal] = useState({
    key: ['exchange', 'ticker'],
    header: [
      {
        headerName: 'Equity',
        field: ['ticker', 'name'],
        render: dataRow =>
          <TableRow
            title={dataRow.ticker}
            subtitle={dataRow.name}
          />
      },
      {
        headerName: '7 Days Trend',
        field: '',
        render: () =>
          <LineGraph timeframe={data} width={'300'} height={'100'} />
      },
      {
        headerName: 'Entry Date',
        field: 'entryDate',
        date: true,
        width: 110,
      },
      {
        headerName: 'Entry Price',
        field: 'entryPrice',
        numeric: true,
        width: 110,
      },
      {
        headerName: 'Entry No of Share(s)',
        field: 'entryNoofShare',
        numeric: true,
      },
      {
        headerName: 'Entry Amount',
        field: 'entryAmount',
        numeric: true,
      },
      {
        headerName: 'Exit Date',
        field: 'exitDate',
        date: true,
      },
      {
        headerName: 'Exit Price',
        field: 'exitPrice',
        numeric: true,
      },
      {
        headerName: 'Exit No of Share(s)',
        field: 'exitNoofShare',
        numeric: true,
      },
      {
        headerName: 'Exit Amount',
        field: 'exitAmount',
        numeric: true,
      },
    ],
    feeds: [
      {
        exchange: 'NASDAQ',
        ticker: 'MVIS',
        name: 'MICROVISION INC WASHINGTON',
        country: 'US',
        entryDate: new Date(2020, 9, 21),
        entryPrice: 451.00,
        entryNoofShare: 6,
        entryAmount: 451.00 * 6,
        exitDate: new Date(2020, 10, 2),
        exitPrice: 418.00,
        exitNoofShare: 6,
        exitAmount: 418.00 * 6,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'CLNE',
        name: 'CLEAN ENERGY FUELS CORPORATION',
        country: 'US',
        entryDate: new Date(2020, 9, 21),
        entryPrice: 321.00,
        entryNoofShare: 6,
        entryAmount: 321.00 * 6,
        exitDate: new Date(2020, 10, 2),
        exitPrice: 123.00,
        exitNoofShare: 6,
        exitAmount: 123.00 * 6,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'VERU',
        name: 'VERU INC',
        country: 'US',
        entryDate: new Date(2020, 9, 21),
        entryPrice: 732.00,
        entryNoofShare: 6,
        entryAmount: 732.00 * 6,
        exitDate: new Date(2020, 10, 2),
        exitPrice: 890.00,
        exitNoofShare: 6,
        exitAmount: 890.00 * 6,
      },
      {
        exchange: 'NASDAQ',
        ticker: 'MARA',
        name: 'MARATHON PATENT GROUP INC',
        country: 'US',
        entryDate: new Date(2020, 9, 21),
        entryPrice: 123.00,
        entryNoofShare: 6,
        entryAmount: 123.00 * 6,
        exitDate: new Date(2020, 10, 2),
        exitPrice: 456.00,
        exitNoofShare: 6,
        exitAmount: 456.00 * 6,
      },
    ],
  });

  const [state, setState] = useState({
    fields: [
      {
        name: 'entryDate',
        value: '',
        label: 'Entry Date',
        type: 'date',
        fullWidth: true,
      },
      {
        name: 'entryPrice',
        value: '',
        label: 'Entry Price',
        required: true,
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'entryNoofShare',
        value: '',
        label: 'Entry No of Share(s)',
        required: true,
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
    ]
  });

  const [notification, setNotification] = useState({
    fields: [
      {
        name: 'symbol',
        value: 'ETHUSDT',
        disabled: true,
        label: 'Symbol',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'highest',
        value: '0.20',
        disabled: true,
        label: 'Highest Price',
        input: '$',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'lowest',
        value: '0.13',
        disabled: true,
        label: 'Highest Price',
        input: '$',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'current',
        value: '0.14',
        disabled: true,
        label: 'Current Price',
        input: '$',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'type',
        value: '',
        required: true,
        label: 'Short/Long',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: 'Short',
            value: 'short',
          },
          {
            displayValue: 'Long',
            value: 'long',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'condition',
        value: '',
        required: true,
        label: 'Notify When',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: 'Price Reached Target Price',
            value: 'target',
          }
        ],
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'targetPercentage',
        value: '',
        label: 'Target Price Percentage',
        inputEnd: '%',
        required: true,
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'target',
        value: '0.22',
        label: 'Target Price (Auto Populate)',
        input: '$',
        disabled: true,
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'validFrom',
        value: '',
        label: 'Valid From',
        type: 'date',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'validTill',
        value: '',
        label: 'Valid Till',
        type: 'date',
        fullWidth: true,
        sizeOptions: {
          xs: 6
        },
      },
      {
        name: 'status',
        value: '',
        required: true,
        label: 'Notify Status',
        fullWidth: true,
        type: 'select',
        options: [
          {
            displayValue: 'Trigger Once Only',
            value: '1',
          },
          {
            displayValue: 'Trigger Once And Trigger In Reverse', // If current LONG is notify, then system will notify on next SHORT
            value: '2',
          },
          {
            displayValue: 'Turn Off',
            value: '0',
          },
        ],
        sizeOptions: {
          xs: 6
        },
      },
    ]
  });

  // trading journal 
  // Steps
  const [activeStep, setActiveStep] = useState(0);

  // search bar filter
  const [equityFilter, setEquityFilter] = useState('');

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  // const handleChange = (event) => {
  //   const { name, value, type, checked } = event.target;

  //   let newState = { ...state };

  //   newState.fields
  //     .find((field) => field.name === name)
  //     .value = type ? (type === 'check-box' ? checked : value) : value;

  //   // newState.valid = preliminaryValidation(state.fields);

  //   setState(newState);
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let newState = { ...notification };

    newState.fields
      .find((field) => field.name === name)
      .value = value;

      setNotification(newState);
  };

  const handleAddFieldFilterList = (targetSource, newFilterList) => targetSource.filterLists.push(newFilterList)

  const profileRedirectHandler = (equity) => {
    const queryParams = '?' + encodeURIComponent('exchange') + '=' + encodeURIComponent(equity.exchange)
      + '&' + encodeURIComponent('ticker') + '=' + encodeURIComponent(equity.ticker);

    history.push({
      pathname: '/Equity',
      search: queryParams,
    });
  }

  const steps = [
    {
      label: 'Ticker Name',
      content:
        <SearchBar
          underline
          placeholder='Search Ticker'
          center
          change={(event) => setEquityFilter(event.target.value)}
          clear={() => setEquityFilter('')}
          value={equityFilter}
        />
    },
    {
      label: 'Add entry plan',
      content:
        <Grid container spacing={2}>
          <FormGenerator fields={state.fields} onChange={handleChange} />
        </Grid>,
    },
  ]

  const content = [
    {
      label: 'Ticker',
      render:
        <Grid
          container
          item
          lg={12}
          xs={12}
          style={{ paddingBottom: '1%' }}
        >
          <BasicTable
            placeholder='Search'
            size='small'
            searchable
            sortable
            paginable
            pageSize={10}
            keys={favourite.key}
            header={favourite.header}
            feeds={favourite.feeds}
            maxMobileColumn={2}
            switchColumn
            onDetail={profileRedirectHandler}
            editable
            editOptions={{
              add: false,
              edit: {
                modal: {
                  title: 'Notification Setting',
                  content:
                    <form noValidate>
                      <Grid container spacing={2}>
                        <FormGenerator fields={notification.fields} onChange={handleChange} />
                      </Grid>
                    </form>,
                }
              }
            }}
          />
        </Grid>,
    },
    {/* Hidden Away Until Further Enhancement */},
    // {
    //   label: 'Journal',
    //   render:
    //     <Grid
    //       container
    //       item
    //       lg={12}
    //       xs={12}
    //       style={{ paddingBottom: '1%' }}
    //     >
    //       <BasicTable
    //         placeholder='Search'
    //         size='medium'
    //         searchable
    //         sortable
    //         paginable
    //         editable
    //         switchColumn
    //         editOptions={{
    //           add: {
    //             modal: {
    //               title: 'Add Journal',
    //               content:
    //                 <Steps
    //                   activeStep={activeStep}
    //                   steps={steps}
    //                 />,
    //               actions:
    //                 <>
    //                   <DialogActions>
    //                     <Button
    //                       disabled={activeStep === 0}
    //                       onClick={handleBack}
    //                       className={classes.backButton}
    //                     >
    //                       {t('Back')}
    //                     </Button>
    //                     {activeStep === 0 ?
    //                       <Button variant="contained" onClick={handleNext} fullWidth>
    //                         {t('Next')}
    //                       </Button> : null
    //                     }
    //                     {activeStep === 1 ?
    //                       <Button variant="contained" onClick={() => history.push('/Favourites')} fullWidth>
    //                         {t('Submit')}
    //                       </Button> : null
    //                     }
    //                   </DialogActions>
    //                 </>
    //             }
    //           },
    //           edit: {
    //             modal: {
    //               title: 'Edit Journal',
    //             }
    //           }
    //         }}
    //         filterable
    //         // filterOptions={{
    //         //   addFilterList: handleAddFieldFilterList,
    //         //   filterLists: journal.filterLists,
    //         // }}
    //         pageSize={10}
    //         keys={journal.key}
    //         header={journal.header}
    //         feeds={journal.feeds}
    //         onDetail={profileRedirectHandler}
    //         maxMobileColumn={2}
    //       />
    //     </Grid>,
    // },
    // {
    //   label: 'News',
    //   render:
    //     <Grid
    //       container
    //       item
    //       lg={12}
    //       xs={12}
    //       style={{ paddingBottom: '1%' }}
    //     >
    //       <BasicTable
    //         placeholder='Search'
    //         size='small'
    //         searchable
    //         sortable
    //         paginable
    //         pageSize={10}
    //         keys={news.key}
    //         header={news.header}
    //         feeds={news.feeds}
    //         switchColumn
    //       />
    //     </Grid>,
    // },
    {/* Hidden end */}
  ];

  return (
    <StyledExchangeInfo>
      <Container disableGutters maxWidth="lg">  
        <Typo variant="h6" type="title" fontWeight="bold">
          {t('Favourites')}
        </Typo>
        <MultiTabs content={content} />
      </Container>
      <div className={classes.hiddenspace} />
    </StyledExchangeInfo>
  );
}

export default Favourite;